import "./Administration.css";
import { roles } from "./constants";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "primereact/autocomplete";
import { confirmDialog } from "primereact/confirmdialog";
import Button from "../../components/CustomButton/CustomButton";
import ErrorLayout from "../../components/ErrorLayout/ErrorLayout";
import { fetchCustomers } from "../../redux/slices/CustomersSlice";
import { setToastMessage } from "../../redux/slices/ToastMessageSlice";
import {
  fetchAdminsDispatchers,
  updateUserRole,
} from "../../redux/slices/UserSlice";

const Administration = () => {
  const { customers } = useSelector((state) => state.customers);
  const { adminsDispatchers } = useSelector((state) => state.user);
  console.log(adminsDispatchers);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUserRole, setNewUserRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [errorDataLoading, setErrorDataLoading] = useState(false);
  useEffect(() => {
    if (adminsDispatchers?.length) {
      setLoading(false);
    }
    dispatch(
      fetchAdminsDispatchers({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // setFirstTimeLoaded(true);
    const arr = roles.map((role) => {
      return {
        label: t("administration_page.user_roles." + role),
        value: role,
      };
    });
    setRolesDropDown(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customers && selectedUser.length !== 0) {
      const arr = customers.map((customer) => {
        return {
          label: customer.fullName,
          value: customer._id,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
        };
      });
      setUsers(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const successUpdateUserCallback = () => {
    dispatch(
      fetchAdminsDispatchers({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.administration.role_updated"),
      })
    );
  };

  const failureUpdateUserCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
    setUser(null);
    setSelectedUser("");
    setNewUserRole("");
  };

  const confirm = (newRole, id) => {
    confirmDialog({
      header: t("administration_page.role_changing"),
      message: t("administration_page.role_confirmation_message"),
      acceptClassName: "p-button-danger",
      accept: () => handleRole(newRole, id),
      reject: () => console.log("reject"),
    });
  };

  const handleRole = (newRole, id) => {
    dispatch(
      updateUserRole({
        role: newRole,
        id,
        successCallback: () => successUpdateUserCallback(),
        failureCallback: (message) => failureUpdateUserCallback(message),
      })
    );
  };

  const actionBodyTemplate = (options) => {
    return (
      <Dropdown
        options={rolesDropDown}
        value={options.value}
        dropdownIcon="pi pi-ellipsis-v"
        onChange={(e) => confirm(e.value, options._id)}
        className="p-column-filter administration-dropdown"
      />
    );
  };
  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        options={rolesDropDown}
        value={options.value}
        className="p-column-filter"
        placeholder={t("administration_page.select_role")}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };

  const searchUser = (event) => {
    setTimeout(() => {
      const params = new URLSearchParams([
        ["query", event.query],
        ["type", "email"],
      ]);
      dispatch(fetchCustomers({ params }));
    }, 250);
  };

  const handleAdd = () => {
    if (newUserRole && user?.value) {
      handleRole(newUserRole, user.value);
    }
  };

  const itemTemplate = (item) => {
    return (
      <div className="user-item">
        <strong>{item.label}</strong>
        <small>{item.email}</small>
        <small>{item.phoneNumber}</small>
      </div>
    );
  };

  const handleSelect = (e) => {
    setSelectedUser(e.value.label);
    setUser(e.value);
  };

  return errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <Layout className="administration-table-layout">
      <div className="table-header administration-tab-header">
        <div className="users-filter-container">
          <AutoComplete
            field="name"
            minLength={3}
            suggestions={users}
            value={selectedUser}
            aria-label="Customers"
            onSelect={handleSelect}
            completeMethod={searchUser}
            itemTemplate={itemTemplate}
            className="administration-users-filter"
            onChange={(e) => setSelectedUser(e.value)}
            placeholder={t("searches.search_for_user")}
          />
        </div>
        <div className="row role-add-button-container">
          <Dropdown
            value={newUserRole}
            options={rolesDropDown}
            className="p-column-filter new-user-role"
            onChange={(e) => setNewUserRole(e.value)}
            placeholder={t("administration_page.select_role")}
          />
          <Button name="administration_page.assign" onClick={handleAdd} />
        </div>
      </div>
      <DataTable
        rowHover
        paginator
        rows={20}
        scrollable
        filterDisplay="menu"
        responsiveLayout="scroll"
        value={adminsDispatchers}
        emptyMessage={t("administration_page.no_users_found")}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        <Column
          sortable
          field="name"
          showAddButton={false}
          header={t("common.name")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="administration-main-column"
        />
        <Column
          filter
          sortable
          field={(row) => {
            return t("administration_page.user_roles." + row.role);
          }}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={statusFilterTemplate}
          header={t("administration_page.role")}
          className="administration-main-column"
        />
        <Column
          sortable
          field="createdAt"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.created_at")}
          className="administration-main-column-date"
        />
        <Column
          body={actionBodyTemplate}
          header={""}
          className="administration-list-header"
        />
      </DataTable>
    </Layout>
  );
};

export default Administration;
