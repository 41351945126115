import Navbar from "./Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Notification from "../../components/Notification/Notification";

const Dashboard = (props) => {
  const { isLogged } = useSelector((state) => state.user);

  return (
    <>
      {isLogged && <Navbar />}
      <Outlet />
      <Notification />
      {props.children}
    </>
  );
};

export default Dashboard;
