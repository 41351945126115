import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import React, { useEffect, useRef } from "react";
import { resetToastMessage } from "../redux/slices/ToastMessageSlice";

const ToastifyMessage = ({
  type,
  show,
  detail,
  sticky,
  position,
  closable,
  contentShow,
}) => {
  const toast = useRef(null);
  let dispatch = useDispatch();

  useEffect(() => {
    toast.current.clear();
    if (toast.current) {
      toast.current.clear();
    }
    let content = null;
    if (contentShow) {
      content = (
        <div className="today-order-toast-message-container">
          <>
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <br />
            <div>{detail}</div>
          </>
          <Button
            aria-label="Apply"
            className="today-order-toast-message-button"
            onClick={() => dispatch(resetToastMessage())}
          >
            Understood
          </Button>
        </div>
      );
    }
    if (show) {
      toast.current.show({
        severity: type,
        detail: detail,
        sticky: sticky,
        content: content,
        closable: closable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, contentShow]);

  const onToastHide = () => {
    dispatch(resetToastMessage());
  };

  return (
    <div className={show && contentShow ? "overlay" : ""}>
      <Toast ref={toast} position={position} onHide={() => onToastHide()} />
    </div>
  );
};

export default ToastifyMessage;
