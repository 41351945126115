import "./CarForm.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { carColors, FuelTypes } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "primereact/autocomplete";
import CloseSvg from "../../../../../../assets/svg/Close.svg";
import { validateForm } from "../../../../../../utils/Validator";
import Button from "../../../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../../../redux/slices/ToastMessageSlice";
import {
  fetchBrands,
  fetchModels,
} from "../../../../../../redux/slices/CarsSlice";
import {
  addCar,
  updateCar,
  fetchCustomer,
} from "../../../../../../redux/slices/CustomersSlice";
import { FileUpload } from "primereact/fileupload";
import Loader from "../../../../../../components/Loader/Loader";
import { InputText } from "primereact/inputtext";

const CardForm = ({ handleClick, isNew, selectedCar }) => {
  const { brands, models } = useSelector((state) => state.cars);
  const { customer } = useSelector((state) => state.customers);

  let { t } = useTranslation();
  let dispatch = useDispatch();
  let params = useParams();

  const initialCarValues = {
    brand: "",
    model: "",
    color: "",
    license: null,
    licensePlate: "",
    productionYear: "",
    fuelType: "petrol",
  };
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [userCar, setUserCar] = useState(initialCarValues);
  const [filteredModels, setFilteredModels] = useState(null);

  useEffect(() => {
    if (customer && selectedCar && !isNew) {
      const carInfo = initialCarValues;
      const keys = Object.keys(carInfo);
      keys.forEach((key) => {
        if (selectedCar[key] === undefined) {
          carInfo[key] = "";
        } else {
          carInfo[key] = selectedCar[key];
        }
      });
      setUserCar(carInfo);
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const failureFetchBrandModelsCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchBrands({
        failureCallback: (message) => failureFetchBrandModelsCallback(message),
      })
    );
    if (userCar.brand) {
      dispatch(
        fetchModels({
          brand: userCar.brand,
          failureCallback: (message) =>
            failureFetchBrandModelsCallback(message),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCar.brand]);

  const handleChange = (e) => {
    const carInfo = userCar;
    if (e.target.name === "brand") {
      setUserCar({ ...carInfo, [e.target.name]: e.target.value, model: "" });
    } else if (e.target.name === "productionYear") {
      setUserCar({ ...carInfo, [e.target.name]: e.target.value.getFullYear() });
    } else if (e.target.name === "fuelType") {
      setUserCar({
        ...carInfo,
        [e.target.name]: e.target.id.toLocaleLowerCase(),
      });
    } else {
      setUserCar({ ...carInfo, [e.target.name]: e.target.value });
    }
  };

  const successAddUpdateCarCallback = ({ addCar }) => {
    handleClick();
    dispatch(
      setToastMessage({
        show: true,
        type: "success",
        position: "bottom-center",
        detail: addCar
          ? t("toast_messages.car.car_added")
          : t("toast_messages.car.car_updated"),
      })
    );
    dispatch(fetchCustomer({ id: params.id }));
    setSubmitted(false);
  };

  const failureAddUpdateCarCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleCar = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const carInfo = { ...userCar };
    delete userCar.license;
    delete userCar.licensePlate;
    if (!userCar.color) {
      delete userCar.color;
    }
    if (!userCar.productionYear) {
      delete userCar.productionYear;
    }
    const carValues = Object.values(userCar);
    const validated = validateForm(carValues);
    userCar.user = customer?._id;
    userCar.licensePlate = carInfo.licensePlate;

    if (selectedCar && !isNew) {
      if (validated) {
        const id = selectedCar._id;
        dispatch(
          updateCar({
            userCar,
            id,
            successCallback: ({ addCar }) =>
              successAddUpdateCarCallback({ addCar }),
            failureCallback: (message) => failureAddUpdateCarCallback(message),
          })
        );
      } else {
        setUserCar(carInfo);
      }
    } else {
      if (validated) {
        dispatch(
          addCar({
            userCar,
            successCallback: ({ addCar }) =>
              successAddUpdateCarCallback({ addCar }),
            failureCallback: (message) => failureAddUpdateCarCallback(message),
          })
        );
      } else {
        setUserCar(carInfo);
      }
    }
  };

  const searchModel = (event) => {
    setTimeout(() => {
      let _filteredModels;
      if (!event.query.trim().length) {
        _filteredModels = [...models];
      } else {
        _filteredModels = models.filter((model) => {
          return model.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredModels(_filteredModels);
    }, 250);
  };

  const itemTemplate = (item) => {
    return <div className="models-dropdown-item">{item}</div>;
  };

  const handleRegistrationImageUpload = (e) => {
    setUserCar({ ...userCar, file: e.files[0] });
  };

  return loading ? (
    <div className="edit-car-loader-container">
      <Loader />
    </div>
  ) : (
    <>
      <Button
        name={!isNew ? "buttons.save_car_button" : "buttons.add_car_button"}
        onClick={(e) => handleCar(e)}
      />
      <div className="car-form-container">
        <div className="close-button-container">
          <img
            src={CloseSvg}
            alt="close car form"
            onClick={handleClick}
            id="close-card-form-button"
          />
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                value={userCar.brand}
                name="brand"
                options={brands}
                onChange={handleChange}
              />
              <label htmlFor="brand">
                {t("customers_page.customer.vehicle_tab.car.brand")}
              </label>
            </span>
          </div>
          {submitted && userCar.brand?.length === 0 && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                name="model"
                field="model"
                aria-label="Models"
                value={userCar.model}
                onChange={handleChange}
                disabled={!userCar.brand}
                itemTemplate={itemTemplate}
                completeMethod={searchModel}
                suggestions={filteredModels}
              />
              <label htmlFor="model">
                {t("customers_page.customer.vehicle_tab.car.model")}
              </label>
            </span>
          </div>
          {submitted && userCar.model?.length === 0 && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Calendar
                view="year"
                dateFormat="yy"
                name="productionYear"
                onChange={handleChange}
                id="car-form-year-picker"
                value={
                  userCar.productionYear
                    ? new Date(userCar.productionYear, 0, 1)
                    : ""
                }
              />
              <label htmlFor="productionYear">
                {t("customers_page.customer.vehicle_tab.car.production_year")}
              </label>
            </span>
          </div>
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                name="color"
                value={userCar.color}
                options={carColors}
                onChange={handleChange}
              />
              <label htmlFor="color">
                {t("customers_page.customer.vehicle_tab.car.colors.title")}
              </label>
            </span>
          </div>
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="licensePlate"
                onChange={handleChange}
                value={userCar.licensePlate}
                className="car-card-input full-row"
              />
              <label htmlFor="licensePlate">
                {t(
                  "customers_page.customer.vehicle_tab.car.license_plate_number"
                )}
              </label>
            </span>
          </div>
        </div>
        <div className="registration-image-upload-container">
          <span>
            {t("customers_page.customer.vehicle_tab.car.registration_image")}
          </span>
          {userCar?.license && customer.cars?.length > 0 && (
            <div className="registration-image-container">
              <img
                src={userCar.license.url}
                alt="registration-file"
                className="registration-image"
                onClick={() => window.open(userCar.license.url, "_blank")}
              />
              <a
                href={userCar.license.url}
                target="_blank"
                rel="registration-image noreferrer"
                className="registration-image-link"
              >
                View
              </a>
            </div>
          )}
          <div className="image-uploader-container">
            <FileUpload
              mode="basic"
              accept="image/*"
              maxFileSize={4500000}
              name="registration-file"
              className="registration-file"
              chooseLabel={
                userCar?.license
                  ? t("customers_page.customer.vehicle_tab.car.update_image")
                  : t("customers_page.customer.vehicle_tab.car.upload_image")
              }
              onSelect={(e) => handleRegistrationImageUpload(e)}
            />
          </div>
        </div>
        <div className="row fuel-row">
          <span id="fuelType">
            {t("customers_page.customer.vehicle_tab.car.fuel_types.title")}
          </span>
          <div className="row fuel-type-buttons">
            <button
              className={
                userCar.fuelType === FuelTypes.PETROL
                  ? "selected"
                  : "notSelected"
              }
              onClick={handleChange}
              name="fuelType"
              id="petrol"
            >
              {t("customers_page.customer.vehicle_tab.car.fuel_types.petrol")}
            </button>
            <button
              className={
                userCar.fuelType === FuelTypes.DIESEL
                  ? "selected"
                  : "notSelected"
              }
              name="fuelType"
              id="diesel"
              onClick={handleChange}
            >
              {t("customers_page.customer.vehicle_tab.car.fuel_types.diesel")}
            </button>
            <button
              className={
                userCar.fuelType === FuelTypes.ELECTRIC
                  ? "selected"
                  : "notSelected"
              }
              name="fuelType"
              id="electric"
              onClick={handleChange}
            >
              {t("customers_page.customer.vehicle_tab.car.fuel_types.electric")}
            </button>
            <button
              className={
                userCar.fuelType === FuelTypes.HYBRID
                  ? "selected"
                  : "notSelected"
              }
              name="fuelType"
              id="hybrid"
              onClick={handleChange}
            >
              {t("customers_page.customer.vehicle_tab.car.fuel_types.hybrid")}
            </button>
            <button
              className={
                userCar.fuelType === FuelTypes.PLUG_IN_HYBRID
                  ? "selected"
                  : "notSelected"
              }
              name="fuelType"
              id="plug-in-hybrid"
              onClick={handleChange}
            >
              {t(
                "customers_page.customer.vehicle_tab.car.fuel_types.plug-in-hybrid"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardForm;
