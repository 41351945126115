import i18next from "i18next";
import axios from "../../api/api";
import { CustomersSliceUrls } from "./SlicesUrls";
import { errorCodes, NETWORK_ERROR } from "./constants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async ({ params, successCallback, failureCallback }) => {
    return axios
      .get(`${CustomersSliceUrls.customersUrl}`, { params })
      .then((res) => {
        res.data.forEach((customer) => {
          if (customer?.photo?.url) {
            customer.image = customer.photo.url;
          } else {
            customer.image = customer.fullName;
          }
          const hasUid = customer?.uid ? true : false;
          customer.isMobileUser = hasUid || customer.signInProvider === "OTP";
        });
        if (successCallback !== undefined) successCallback();
        return { customers: res.data };
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback !== undefined) failureCallback();
      });
  }
);

export const fetchCustomer = createAsyncThunk(
  "customers/fetchCustomer",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`${CustomersSliceUrls.customerUrl}/${id}`)
      .then((res) => {
        if (res.data) {
          const carsDropDown = res.data.cars.map((car) => {
            const carInfo = {};
            carInfo.value = car._id;
            carInfo.label = `${car.brand} / ${car.model} ${
              car?.licensePlate ? ` / ${car?.licensePlate}` : ""
            }${car.productionYear ? ` / ${car?.productionYear}` : ""}`;
            return carInfo;
          });
          const carsWithMembership = res.data.memberships.map((membership) => {
            return membership.car;
          });
          successCallback?.();
          res.data.company = res.data?.company ? res.data?.company?.name : "";
          return { customer: res.data, carsDropDown, carsWithMembership };
        } else {
          return { customer: null, carsDropDown: ["Deleted Car"] };
        }
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback !== undefined)
          failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const addCustomer = createAsyncThunk(
  "customers/addCustomer",
  async ({ customer, successCallback, failureCallback }) => {
    return axios
      .post(CustomersSliceUrls.customerUrl, {
        ...customer,
      })
      .then((res) => {
        successCallback(res.data._id);
        return { customer: res.data };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        let duplicatePhoneEmail = false;
        if (
          err.response?.data?.error_code === errorCodes.DUPLICATE_EMAIL ||
          err.response?.data?.error_code === errorCodes.DUPLICATE_PHONE_NUMBER
        ) {
          message = err.response.data.message;
          duplicatePhoneEmail = true;
        } else {
          message = i18next.t("toast_messages.something_went_wrong");
        }
        failureCallback(
          message,
          duplicatePhoneEmail,
          err.response?.data?.error_code
        );
      });
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async ({ id, user, successCallback, failureCallback }) => {
    return axios
      .put(`${CustomersSliceUrls.customerUrl}/${id}`, {
        ...user,
      })
      .then((res) => {
        successCallback();
        res.data.company = res.data?.company ? res.data?.company?.name : "";
        return { customer: res.data };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        let duplicatePhoneEmail = false;
        if (
          err.response?.data?.error_code === errorCodes.DUPLICATE_EMAIL ||
          err.response?.data?.error_code === errorCodes.DUPLICATE_PHONE_NUMBER
        ) {
          message = err.response.data.message;
          duplicatePhoneEmail = true;
        } else if (
          err.response?.data?.error_code === errorCodes.VERIFIED_EMAIL_CHANGE
        ) {
          message = err.response.data.message;
        } else {
          message = i18next.t("toast_messages.something_went_wrong");
        }
        failureCallback(
          message,
          duplicatePhoneEmail,
          err.response?.data?.error_code
        );
      });
  }
);

export const updateCar = createAsyncThunk(
  "customers/updateCar",
  async ({ userCar, id, successCallback, failureCallback }) => {
    return axios
      .put(
        `${CustomersSliceUrls.updateCarUrl}/${id}`,
        {
          ...userCar,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        successCallback({ addCar: false });
        return { car: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const addCar = createAsyncThunk(
  "customers/addCar",
  async ({ userCar, successCallback, failureCallback }) => {
    return axios
      .post(
        `${CustomersSliceUrls.addCarUrl}`,
        {
          ...userCar,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        successCallback({ addCar: true });
        return { car: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const fetchCities = createAsyncThunk(
  "customers/fetchCities",
  async () => {
    return axios
      .get(`${CustomersSliceUrls.citiesUrl}`)
      .then((res) => {
        const cities = res.data.map((city) => {
          return city.name;
        });
        return { cities };
      })
      .catch((err) => {
        console.log(err);
        return { cities: [] };
      });
  }
);

export const exportCustomersToCsvFile = createAsyncThunk(
  "customers/exportCustomersToCsvFile",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(`${CustomersSliceUrls.csvUrl}`, {
        responseType: "blob",
      })
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .replaceAll('"', "");
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const deleteCustomer = createAsyncThunk(
  "customers/deleteCustomer",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .delete(`${CustomersSliceUrls.customerUrl}/${id}`)
      .then(() => {
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);
export const deleteCar = createAsyncThunk(
  "customer/deleteCar",
  async ({ id, userId, successCallback, failureCallback }) => {
    return axios
      .delete(`${CustomersSliceUrls.deleteCarUrl}/${id}`, { data: { userId } })
      .then(() => {
        successCallback();
      })
      .catch((err) => {
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    cars: [],
    car: null,
    cities: [],
    customers: [],
    customer: null,
    carsDropDown: [],
    carsWithMembership: [],
  },
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload.customer;
    },
    setCarsDropDown: (state, action) => {
      state.carsDropDown = action.payload.carsDropDown;
    },
  },
  extraReducers: {
    [fetchCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload?.customers;
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.customer = action.payload?.customer;
    },
    [fetchCustomer.fulfilled]: (state, action) => {
      state.customer = action.payload?.customer;
      state.car = action.payload?.customer?.cars[0];
      state.cars = action.payload?.customer?.cars;
      state.carsDropDown = action.payload?.carsDropDown ?? [];
      state.carsWithMembership = action.payload?.carsWithMembership ?? [];
    },
    [addCustomer.fulfilled]: (state, action) => {
      if (action.payload?.customer) state.customer = action.payload?.customer;
    },
    [updateCar.fulfilled]: (state, action) => {
      if (action.payload?.car) {
        let _customer = { ...state.customer };
        _customer.cars[0] = action.payload.car;
        state.customer = _customer;
      }
    },
    [addCar.fulfilled]: (state, action) => {
      if (action.payload?.car) {
        let _customer = { ...state.customer };
        _customer.cars[0] = action.payload.car;
        state.customer = _customer;
      }
    },
    [fetchCities.fulfilled]: (state, action) => {
      state.cities = action.payload?.cities;
    },
  },
});

export const { setCustomer, setCarsDropDown } = customersSlice.actions;

export default customersSlice.reducer;
