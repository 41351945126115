import "./MembershipsList.css";
import { tableFilters } from "./constant";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import StarFilled from "../../../assets/svg/StarFilled.svg";
import MembershipForm from "./MembershipForm/MembershipForm";
import { Button as PrimeReactButton } from "primereact/button";
import Button from "../../../components/CustomButton/CustomButton";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import {
  setMembership,
  fetchMemberships,
  deleteMembership,
} from "../../../redux/slices/MembershipsSlice";
import { confirmDialog } from "primereact/confirmdialog";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import { FcApproval } from "react-icons/fc";

const MembershipsList = () => {
  const { features } = useSelector((state) => state.user);
  const { memberships } = useSelector((state) => state.memberships);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(tableFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let { t } = useTranslation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    if (memberships?.length) {
      setLoading(false);
    }
    dispatch(
      fetchMemberships({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successDeleteMembershipCallback = () => {
    setLoading(false);
    dispatch(
      fetchMemberships({ successCallback: () => {}, failureCallback: () => {} })
    );
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_deleted"),
      })
    );
  };

  const failureDeleteMembershipCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const deleteConfirmPopUp = (id) => {
    confirmDialog({
      header: t("services_page.memberships.delete_membership_pop_up.title"),
      message: t("services_page.memberships.delete_membership_pop_up.body"),
      accept: () => {
        setLoading(true);
        dispatch(
          deleteMembership({
            id: id,
            successCallback: successDeleteMembershipCallback,
            failureCallback: failureDeleteMembershipCallback,
          })
        );
      },
      // deleteCustomer({
      //   id,
      //   successCallback: () => successDeleteCustomerCallback(),
      //   failureCallback: (message) =>
      //     failureDeleteCustomerCallback(message),
      // })
      reject: () => console.log("reject"),
    });
  };

  const actionBodyTemplate = (e) => {
    return (
      <div className="customers-list-icons-container">
        <PrimeReactButton
          type="button"
          icon="pi pi-pencil"
          onClick={() => onRowSelect({ data: e })}
          className={
            !features.services.memberships.edit
              ? "memberships-list-features-not-allowed"
              : ""
          }
        />
        <PrimeReactButton
          type="button"
          className={
            !(!e.defaultMembership && features.customers.deleteCustomers) &&
            "invisible-element"
          }
          icon="pi pi-trash"
          onClick={() => deleteConfirmPopUp(e._id)}
        />
      </div>
    );
  };

  const onRowSelect = (event) => {
    navigate(`/dashboard/memberships/${event.data._id}`);
  };

  const handleOpen = () => {
    dispatch(setMembership({ membership: null }));
    setOpen(!open);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const starBodyTemplate = (rowData) => {
    if (rowData?.defaultMembership) {
      return (
        <img
          src={StarFilled}
          alt="default-membership"
          className="default-membership"
        />
      );
    } else {
      return <div className="default-membership" />;
    }
  };
  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <div style={{ margin: "10px" }}>{rowData.name}</div>
        {rowData?.defaultMembership && (
          <img
            src={StarFilled}
            alt="default-membership"
            className="default-membership"
          />
        )}
      </>
    );
  };
  const activeBodyTemplate = (rowData) => {
    if (rowData?.active) {
      return <FcApproval size={30} />;
    } else {
      return <div className="default-membership" />;
    }
  };
  useEffect(() => {
    setGlobalFilterValue(filters?.global?.value);
    // eslint-disable-next-line
  }, [open]);

  return open ? (
    <MembershipForm open={open} newMembership={true} handleOpen={handleOpen} />
  ) : errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <>
      <div className="table-header">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder={t("searches.keyword_search")}
        />
        <Button
          onClick={() => handleOpen()}
          name="buttons.add_membership_button"
          className={
            !features.services.memberships.add
              ? "memberships-list-features-not-allowed"
              : ""
          }
        />
      </div>
      <DataTable
        rowHover
        rows={20}
        scrollable
        paginator
        filters={filters}
        value={memberships}
        selectionMode="single"
        responsiveLayout="scroll"
        onRowSelect={onRowSelect}
        globalFilterFields={["name", "price"]}
        emptyMessage={t("services_page.memberships.no_memberships_found")}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        <Column
          sortable
          field="name"
          showAddButton={false}
          header={t("common.name")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="memberships-table-main-colum"
          body={nameBodyTemplate}
        />
        <Column
          sortable
          field="price"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.price")}
          className="memberships-table-main-colum"
        />
        <Column
          body={activeBodyTemplate}
          className="memberships-list-active-column"
        />
        <Column
          body={actionBodyTemplate}
          headerClassName="membership-action-column"
        />
      </DataTable>
    </>
  );
};

export default MembershipsList;
