import "./SignIn.css";
import { auth } from "../../firebase";
import images from "../../assets/images";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { userRoles } from "../../constants";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setToastMessage } from "../../redux/slices/ToastMessageSlice";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {
  createUser,
  login,
  setAccountInfo,
  setLogged,
  setLoggedOut,
} from "../../redux/slices/UserSlice";
import {
  authErrorCodes,
  authErrorMessages,
} from "../../utils/AuthErrorMessages";

const SignIn = () => {
  const { isLogged, loggedUser } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { t } = useTranslation();

  auth.onAuthStateChanged((user) => {
    if (user) {
    } else {
      if (isLogged) {
        if (localStorage.getItem("unauthorized")) {
          dispatch(setLoggedOut());
          navigate("/sign-in");
          dispatch(
            setToastMessage({
              show: true,
              position: "top-center",
              type: "error",
              detail: t("toast_messages.unauthorized"),
            })
          );
          localStorage.clear();
          sessionStorage.clear();
          setInterval(() => {
            dispatch(
              setToastMessage({
                show: false,
              })
            );
          }, 500);
        }
      }
    }
  });

  const checkAuthorization = (user) => {
    if (user) {
      if (loggedUser.role !== userRoles.CUSTOMER) {
        navigate("/dashboard");
      } else {
        setLoading(false);
        navigate("/not-authorized", { state: { email } });
        //temporarily
        // if (!user.emailVerified)
        //   navigate("/not-verified", { state: { email } });
        // else navigate("/not-authorized", { state: { email } });
      }
    }
  };

  const failureCreateUser = () => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: t("toast_messages.something_went_wrong"),
      })
    );
  };

  const SignInFormSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        user.getIdToken().then((IdToken) => {
          localStorage.setItem("token", IdToken);
          //get the JWT token
          dispatch(
            login({
              IdToken,
              successCallback: () => {
                dispatch(setLogged({ isLogged: true }));
              },
              failureCallback: () => failureCreateUser(),
            })
          );
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === authErrorCodes.NETWORK_FAILED) {
          dispatch(
            setToastMessage({
              show: true,
              position: "bottom-center",
              type: "error",
              detail: t("authorization.network_failed"),
            })
          );
        } else if (
          error.code === authErrorCodes.WRONG_PASSWORD ||
          error.code === authErrorCodes.USER_NOT_FOUND
        ) {
          setError(true);
        } else {
          dispatch(
            setToastMessage({
              show: true,
              position: "bottom-center",
              type: "error",
              detail: t("toast_messages.something_went_wrong"),
            })
          );
        }
        console.log(error);
      });
  };

  const successSetAccountInfoCallback = () => {
    setDialogMessage(t("verify_email_page.email_verified"));
    setVisible(true);
  };

  const failureSetAccountInfoCallback = (message) => {
    setDialogMessage(t("authorization." + authErrorMessages[message]));
    setVisible(true);
  };

  useEffect(() => {
    if (searchParams.get("mode") === "verifyEmail") {
      const oobCode = searchParams.get("oobCode");
      const key = searchParams.get("apiKey");
      const params = new URLSearchParams([["key", key]]);
      dispatch(
        setAccountInfo({
          oobCode,
          params,
          successCallback: () => successSetAccountInfoCallback(),
          failureCallback: (message) => failureSetAccountInfoCallback(message),
        })
      );
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      checkAuthorization(loggedUser);
    }
    if (error) {
      setFormMessage(
        t("authorization." + authErrorMessages.WRONG_USER_PASSWORD)
      );
      setShowError(true);
      setError(null);
      setLoading(false);
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, error]);

  const dialogFooter = () => {
    return (
      <Button onClick={() => handleClose()}>
        {t("sign_in_page.verify_email_dialog.ok")}
      </Button>
    );
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Dialog
        closable={false}
        position="center"
        draggable={false}
        resizable={false}
        visible={visible}
        footer={dialogFooter}
        header={t("authorization.title")}
        className="email-verifying-dialog"
      >
        <div>{dialogMessage}</div>
      </Dialog>
      <div className="sign-in-up-page-container">
        {loading && submitted && <OverlayLoader />}
        <div className="sign-in-up-header">
          <img src={images.logo} alt="Turqat Logo" id="sign-in-logo" />
        </div>
        <div className="sign-in-up-form">
          <form onSubmit={(e) => SignInFormSubmit(e)}>
            <div className="sign-in-up-form-inputs-container">
              <h1>{t("sign_in_page.title")}</h1>
              <InputText
                required
                name="Email"
                type="email"
                value={email}
                className="padded-input-text"
                placeholder={t("common.email_address")}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputText
                required
                type="password"
                name="password"
                autoComplete="on"
                value={password}
                className="padded-input-text"
                placeholder={t("common.password")}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className={`error-container ${showError ? "show" : "hide"}`}>
                <small className="sign-in-up-error">{formMessage}</small>
              </div>
              <Button
                type="submit"
                className="submitBtn"
                label={t("sign_in_page.title")}
                onClick={(e) => SignInFormSubmit(e)}
              />
              {/* <NavLink className="sign-in-up-link" to="/sign-up">
                {t("sign_in_page.not_registered")}
              </NavLink> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignIn;
