import i18next from "i18next";
import axios from "../../api/api";
import i18n from "../../i18n/i18n";
import { NETWORK_ERROR } from "./constants";
import { MembershipsSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchMemberships = createAsyncThunk(
  "memberships/fetchMemberships",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(MembershipsSliceUrls.membershipsUrl)
      .then((res) => {
        if (res.data.length === 0)
          return { memberships: [], membershipsDropDown: [] };
        const membershipsDropDown = [];
        let defaultMembershipIndex = 0;
        res.data.forEach((item, index) => {
          const membership = {};
          membership.value = item._id;
          membership.label = item.name;
          membershipsDropDown.push(membership);
          if (item.defaultMembership) {
            defaultMembershipIndex = index;
          }
        });
        const defaultMembership = res.data.splice(defaultMembershipIndex, 1);
        const memberships = [defaultMembership[0], ...res.data];
        if (successCallback !== undefined) successCallback();
        return { memberships, membershipsDropDown };
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback !== undefined) failureCallback();
      });
  }
);

export const fetchPendingMemberships = createAsyncThunk(
  "memberships/fetchPendingMemberships",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(MembershipsSliceUrls.pendingMembershipsUrl)
      .then((res) => {
        res.data.forEach((item) => {
          item.paymentMethod = item?.paymentMethod;
          item.brandModel = `${item?.car?.brand}/${item?.car?.model}`;
          item.registrationPhoto = item?.car?.license?.url;
          item.promoCode = item?.promoCode?.name;
        });
        successCallback();
        return { pendingMemberships: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);

export const fetchMembership = createAsyncThunk(
  "memberships/fetchMembership",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`${MembershipsSliceUrls.membershipUrl}/${id}`)
      .then((res) => {
        if (successCallback !== undefined) successCallback();
        successCallback();
        return { membership: res.data };
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback !== undefined)
          failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const updateMembership = createAsyncThunk(
  "memberships/updateMembership",
  async ({ id, membership, successCallback, failureCallback }) => {
    return axios
      .put(`${MembershipsSliceUrls.membershipUrl}/${id}`, {
        ...membership,
      })
      .then((res) => {
        successCallback({ addedMembership: false });
        return { membership: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const deleteMembership = createAsyncThunk(
  "memberships/deleteMembership",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .delete(`${MembershipsSliceUrls.membershipUrl}/${id}`)
      .then((res) => {
        successCallback?.();
        return { membership: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback?.(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const addMembership = createAsyncThunk(
  "memberships/addMembership",
  async ({ membership, successCallback, failureCallback }) => {
    return axios
      .post(MembershipsSliceUrls.membershipUrl, {
        ...membership,
      })
      .then((res) => {
        console.log(res.data);
        successCallback({ addedMembership: true });
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);

export const activateMembership = createAsyncThunk(
  "memberships/activateMembership",
  async ({ id, index, successCallback, failureCallback }) => {
    return axios
      .put(`${MembershipsSliceUrls.activateMembershipUrl}/${id}`)
      .then((res) => {
        console.log(res.data);
        successCallback();
        return { index };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const deleteMembershipRequest = createAsyncThunk(
  "memberships/deleteMembershipRequest",
  async ({ id, index, successCallback, failureCallback }) => {
    return axios
      .delete(`${MembershipsSliceUrls.userMembershipsUrl}/${id}`)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data.message);
        return {
          index,
        };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const deleteUserMembership = createAsyncThunk(
  "memberships/deleteMembershipRequest",
  async ({ id, index, successCallback, failureCallback }) => {
    return axios
      .delete(`${MembershipsSliceUrls.deleteUserMembershipUrl}/${id}`)
      .then((res) => {
        successCallback(res.data.message);
        return {
          index,
        };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const markMembershipRequest = createAsyncThunk(
  "memberhsips/markMembershipRequest",
  async ({ id, index, successCallback, failureCallback }) => {
    console.log(id);
    return axios
      .put(`${MembershipsSliceUrls.markMembershipUrl}/${id}`)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data.message);
        return {
          index,
        };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const fetchUserMemberships = createAsyncThunk(
  "memberships/fetchUserMemberships",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`${MembershipsSliceUrls.userMembershipsUrl}/${id}`)
      .then((res) => {
        console.log(res);
        const userMemberships = [];
        res.data.forEach((item) => {
          item.membership.services.forEach((service) => {
            const membership = {};
            let date =
              item.startDate && item.endDate
                ? `(${new Date(item.startDate).toLocaleDateString(
                    "en-US"
                  )} - ${new Date(item.endDate).toLocaleDateString("en-US")})`
                : "";
            const status =
              item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1);
            membership.name = item.membership.name;
            membership.paid = item.paid;
            const productionYear = item.car?.productionYear
              ? `-${item.car.productionYear}`
              : "";
            membership.carInfo =
              `${item.car.brand} / ${item.car.model}${
                item?.car?.licensePlate ? ` / ${item?.car?.licensePlate}` : ""
              }` +
              (item.car?.productionYear ? ` / ${item.car.productionYear}` : "");
            membership.membershipInfo = `${
              item.membership.name
            }\n - ${status} ${date} - \n${
              item.paid ? i18n.t("common.paid") : i18n.t("common.not_paid")
            }`;
            membership.fullName =
              `${item.car.brand} / ${item.car.model}` +
              (item.car?.productionYear ? `/ ${item.car.productionYear}` : "") +
              (item?.car?.licensePlate ? `/ ${item?.car?.licensePlate}` : "") +
              `\n${item.membership.name}\n${status} ${date}\n${
                item.paid ? i18n.t("common.paid") : i18n.t("common.not_paid")
              }`;
            membership.numberOfTimesLeft =
              service.numberOfFreeTimes - service.numberOfFreeTimesUsed;
            membership.numberOfFreeTimes = service.numberOfFreeTimes;
            membership.numberOfFreeTimesUsed = service.numberOfFreeTimesUsed;
            membership.serviceKey = service.service.key;
            membership.serviceId = service.service._id;
            membership.serviceName = i18n.t(
              "services_page.services." + service.service.key
            );
            membership.status = item.status;
            membership._id = item._id;
            membership.price = item.membership.price;
            membership.startDate = item.startDate;
            membership.endDate = item.endDate;
            membership.car = item.car._id;
            userMemberships.push(membership);
          });
        });
        successCallback(userMemberships);
        return { userMemberships };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
        return { userMemberships: [] };
      });
  }
);

export const addUserMembership = createAsyncThunk(
  "memberships/addUserMembership",
  async ({ membership, id, successCallback, failureCallback }) => {
    return axios
      .post(`${MembershipsSliceUrls.addUserMembership}/${id}`, {
        ...membership,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const updateUserMembership = createAsyncThunk(
  "memberships/addUserMembership",
  async ({ membership, id, successCallback, failureCallback }) => {
    return axios
      .put(`${MembershipsSliceUrls.addUserMembership}/${id}`, {
        ...membership,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const addCustomMembership = createAsyncThunk(
  "memberships/addCustomMembership",
  async ({ customMembership, successCallback, failureCallback, id }) => {
    return axios
      .post(`${MembershipsSliceUrls.addCustomUserMembershipUrl}/${id}`, {
        ...customMembership,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const membershipsSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    memberships: [],
    membership: null,
    userMemberships: [],
    pendingMemberships: [],
    membershipsDropDown: [],
  },
  reducers: {
    setMembership: (state, action) => {
      state.membership = action.payload.membership;
    },
  },
  extraReducers: {
    [fetchMemberships.fulfilled]: (state, action) => {
      state.memberships = action.payload?.memberships;
      state.membershipsDropDown = action.payload?.membershipsDropDown;
    },
    [fetchPendingMemberships.fulfilled]: (state, action) => {
      state.pendingMemberships = action.payload?.pendingMemberships;
    },
    [fetchMembership.fulfilled]: (state, action) => {
      state.membership = action.payload?.membership;
    },
    [deleteMembershipRequest.fulfilled]: (state, action) => {
      if (action.payload?.index !== null) {
        const requests = [...state.pendingMemberships];
        requests.splice(action.payload.index, 1);
        state.pendingMemberships = requests;
      }
    },
    [fetchUserMemberships.fulfilled]: (state, action) => {
      state.userMemberships = action.payload?.userMemberships;
    },
  },
});

export const { setMembership } = membershipsSlice.actions;

export default membershipsSlice.reducer;
