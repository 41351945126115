import "./ChangePassword.css";
import { useState } from "react";
import { auth } from "../../../firebase";
import { useDispatch } from "react-redux";
import images from "../../../assets/images";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import CloseSvg from "../../../assets/svg/Close.svg";
import Loader from "../../../components/Loader/Loader";
import Button from "../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import {
  authErrorCodes,
  authErrorMessages,
} from "../../../utils/AuthErrorMessages";
import {
  updatePassword,
  EmailAuthProvider,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
} from "firebase/auth";

const ChangePassword = ({ setChangePassword }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  let dispatch = useDispatch();
  let { t } = useTranslation();

  const handleError = (err) => {
    setLoading(false);
    let message = "";
    switch (err.code) {
      case authErrorCodes.WEAK_PASSWORD:
        message = t("authorization." + authErrorMessages.WEAK_PASSWORD);
        break;
      case authErrorCodes.INVALID_PASSWORD:
        message = t("authorization." + authErrorMessages.INVALID_PASSWORD);
        break;
      default:
        dispatch(
          setToastMessage({
            show: true,
            position: "bottom-center",
            type: "error",
            detail: t("toast_messages.something_went_wrong"),
          })
        );
        return;
    }
    setErrorMessage(message);
    setError(true);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    if (newPassword !== confirmPassword) {
      setErrorMessage(t("authorization.password.passwords_do_not_match"));
      setError(true);
      setLoading(false);
      return;
    } else {
      setError(false);
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        oldPassword
      );
      reauthenticateWithCredential(auth.currentUser, credential)
        .then(() => {
          updatePassword(auth.currentUser, newPassword)
            .then(() => {
              setLoading(false);
              dispatch(
                setToastMessage({
                  show: true,
                  position: "bottom-center",
                  type: "success",
                  detail: t("profile_page.password_updated"),
                })
              );
              setChangePassword(false);
            })
            .catch((err) => {
              handleError(err);
            });
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const resetPassword = () => {
    sendPasswordResetEmail(auth, auth.currentUser.email)
      .then(() => {
        setEmailSent(true);
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return loading ? (
    <Loader />
  ) : emailSent ? (
    <div className="verify-email-container">
      <h2>{t("email_sent.title")}</h2>
      <small>
        {t("email_sent.reset_password_message.first_segment")}
        <strong> {auth.currentUser.email} </strong>
        {t("email_sent.reset_password_message.second_segment")}
      </small>
      <img src={images.accept} alt="very-email" className="email-sent-icon" />
    </div>
  ) : (
    <div className="change-password-card-container">
      <div className="title-close-button-container">
        <div className="form-title">
          {t("profile_page.change_password_header")}
        </div>
        <img
          src={CloseSvg}
          alt="close car form"
          id="close-card-form-button"
          onClick={() => setChangePassword(false)}
        />
      </div>
      <div className="change-password-card">
        <div className="change-password-image-container">
          <img
            src={images.changePassword}
            alt="change-password"
            className="change-password-image"
          />
        </div>
        <div className="change-password-fields-container">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Password
                toggleMask
                feedback={false}
                inputId="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <label htmlFor="oldPassword">
                {t("profile_page.old_password")}
              </label>
            </span>
          </div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Password
                toggleMask
                inputId="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label htmlFor="password">{t("profile_page.new_password")}</label>
            </span>
          </div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Password
                toggleMask
                feedback={false}
                inputId="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <label htmlFor="password">
                {t("profile_page.confirm_password")}
              </label>
            </span>
          </div>
          <small
            className={`change-password-error ${error ? "error-shown" : ""}`}
          >
            {errorMessage}
          </small>
          <small className="reset-password">
            {t("profile_page.forget_password")}
            <div className="reset-password-link" onClick={resetPassword}>
              {t("profile_page.reset_password")}
            </div>
          </small>
        </div>
      </div>
      <Button
        name="buttons.confirm_button"
        onClick={
          newPassword.length === 0 || confirmPassword.length === 0
            ? null
            : () => handleChangePassword()
        }
        className={
          newPassword.length === 0 || confirmPassword.length === 0
            ? "button-disabled"
            : ""
        }
      />
    </div>
  );
};

export default ChangePassword;
