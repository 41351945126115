import "./PromotionCodesList.css";
import { Column } from "primereact/column";
import { tableFilters } from "./constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { confirmDialog } from "primereact/confirmdialog";
import { Button as PrimeReactButton } from "primereact/button";
import Button from "../../../components/CustomButton/CustomButton";
import PromotionCodeForm from "./PromotionCodeForm/PromotionCodeForm";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import {
  deletePromotionCode,
  fetchPromotionCodes,
} from "../../../redux/slices/PromotionCodesSlice";

const PromotionCodesList = () => {
  const { promotionCodes } = useSelector((state) => state.promotionCodes);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(tableFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let dispatch = useDispatch();
  let { t } = useTranslation();

  useEffect(() => {
    if (promotionCodes?.length) {
      setLoading(false);
    }
    dispatch(
      fetchPromotionCodes({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successDeletePromoCodeCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.promotion_code.promotion_code_deleted"),
      })
    );
    dispatch(
      fetchPromotionCodes({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
  };

  const failureDeletePromoCodeCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const accept = (rowData) => {
    dispatch(
      deletePromotionCode({
        id: rowData._id,
        successCallback: () => successDeletePromoCodeCallback(),
        failureCallback: (message) => failureDeletePromoCodeCallback(message),
      })
    );
  };

  const confirm = (rowData) => {
    confirmDialog({
      message: t("services_page.promotion_codes.delete_pop_up.body"),
      header: t("services_page.promotion_codes.delete_pop_up.title"),
      acceptClassName: "p-button-danger",
      accept: () => accept(rowData),
      reject: () => console.log("reject"),
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <PrimeReactButton
        type="button"
        icon="pi pi-trash"
        onClick={() => confirm(rowData)}
      />
    );
  };

  const getExpirationDate = (rowData) => {
    const className = new Date(rowData.expiry) < new Date() ? "expired" : "";
    return <p className={className}>{rowData.expiry}</p>;
  };
  useEffect(() => {
    setGlobalFilterValue(filters?.global?.value);
    // eslint-disable-next-line
  }, [open]);

  return open ? (
    <PromotionCodeForm
      setOpen={setOpen}
      setLoading={setLoading}
      setErrorDataLoading={setErrorDataLoading}
    />
  ) : errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <div>
      <div className="table-header">
        <InputText
          value={globalFilterValue}
          placeholder="Keyword Search"
          onChange={onGlobalFilterChange}
        />
        <Button
          onClick={() => setOpen(!open)}
          name="buttons.add_promotion_code_button"
        />
      </div>
      <DataTable
        rowHover
        rows={20}
        paginator
        scrollable
        dataKey="id"
        filters={filters}
        value={promotionCodes}
        responsiveLayout="scroll"
        emptyMessage={t("services_page.promotion_codes.no_promotion_codes")}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        <Column
          sortable
          field="name"
          header={t("common.name")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column"
        />
        <Column
          sortable
          field="description"
          header={t("services_page.promotion_codes.description")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column"
        />
        <Column
          sortable
          field="discount"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column"
          header={t("services_page.promotion_codes.discount")}
        />
        <Column
          sortable
          field="expiry"
          body={getExpirationDate}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column"
          header={t("services_page.promotion_codes.expiration_date")}
        />
        <Column
          sortable
          field="type"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column promotion-code-type"
          header={t("services_page.promotion_codes.promotion_code_type")}
        />
        <Column
          sortable
          field="Owner"
          body={(rowData) => {
            return rowData?.user ? rowData.user?.fullName : "*";
          }}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="promotion-codes-main-column promotion-code-type"
          header={t("services_page.promotion_codes.owner")}
        />
        <Column
          body={actionBodyTemplate}
          headerClassName="promotion-codes-header"
        />
      </DataTable>
    </div>
  );
};

export default PromotionCodesList;
