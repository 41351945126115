import "./GeneralTab.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProfileCard from "./ProfileCard/ProfileCard";
import { InputTextarea } from "primereact/inputtextarea";
import Button from "../../../../../components/CustomButton/CustomButton";

const GeneralTab = () => {
  const { customer } = useSelector((state) => state.customers);

  const [saveProfile, setSaveProfile] = useState(false);
  const [note, setNote] = useState(customer?.customerNote);

  let { t } = useTranslation();

  return (
    <div className="customerGeneralTapLayout">
      <Button
        className="save-profile-button-general-tap"
        name="buttons.save_profile_button"
        onClick={() => setSaveProfile(true)}
      />
      <div className="tab general">
        <div className="general-tab-left-panel">
          <ProfileCard
            newUser={false}
            noteInfo={note}
            saveProfile={saveProfile}
            setSaveProfile={setSaveProfile}
          />
        </div>
        <div className="general-tab-right-panel">
          <InputTextarea
            name="note"
            autoResize
            value={note}
            className="note-input-text"
            onChange={(e) => setNote(e.target.value)}
            placeholder={t("customers_page.customer.general_tab.add_note")}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralTab;
