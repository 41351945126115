import { FilterMatchMode, FilterOperator } from "primereact/api";

export const tableFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  fullName: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  city: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  phoneNumber: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  isMember: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  isMobileUser: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
};
