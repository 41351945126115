export const CarsSliceUrls = {
  brandsUrl: "cars/v2/brands",
  modelsUrl: "cars/models",
};

export const CustomersSliceUrls = {
  citiesUrl: "/cities",
  customerUrl: "admin/user",
  csvUrl: "/admin/users/csv",
  customersUrl: "admin/users",
  addCarUrl: "/admin/user/car",
  updateCarUrl: "admin/user/car",
  deleteCarUrl: "admin/user/car",
};

export const MembershipsSliceUrls = {
  membershipUrl: "admin/membership",
  membershipsUrl: "admin/memberships",
  userMembershipsUrl: "admin/userMembership",
  addUserMembership: "admin/addUserMembership",
  deleteUserMembershipUrl: "superadmin/userMembership",
  activateMembershipUrl: "admin/activateMembership",
  markMembershipUrl: "admin/confirmPayment",
  pendingMembershipsUrl: "admin/pendingMemberships",
  addCustomUserMembershipUrl: "admin/addCustomUserMembership",
};

export const PartnersSliceUrls = {
  providerUrl: "admin/provider",
  providersUrl: "admin/providers",
};

export const PromotionCodesSliceUrls = {
  promoCodesUrl: "admin/promoCode/",
};

export const ServicesSliceUrls = {
  servicesUrl: "services",
  serviceUrl: "admin/service",
};

export const UserSliceUrls = {
  userUrl: "user",
  login: "/auth/login",
  logout: "/auth/logout",
  refreshToken: "/auth/refreshToken",
  adminsDispatchersUrl: "admin/user/role",
  adminSms: "admin/sms",
  setAccountInfoUrl:
    "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo",
  getPhoneNumbers: "/admin/sms/phoneNumbers",
};

export const OrdersSliceUrls = {
  ordersUrl: "admin/order/V2",
  addOrderV2: "admin/order/v2",
  getOrderByIdUrl: "admin/order",
  orderFeedBack: "admin/feedback",
  ordersCsvUrl: "/admin/order/csv",
  ordersToday: "admin/order/ordersToday",
  orderWarrantyPhoto: "admin/photo",
};

export const companiesSliceUrls = {
  companiesUrl: "admin/companies",
};
