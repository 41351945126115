import "./NotAuthorized.css";
import { useTranslation } from "react-i18next";

const NotAuthorized = () => {
  let { t } = useTranslation();

  return (
    <div className="not-authorized-container">
      <span id="authorization">{t("authorization.not_authorized")}</span>
    </div>
  );
};

export default NotAuthorized;
