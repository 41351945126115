import axios from "../../api/api";
import i18n from "../../i18n/i18n";
import { CarsSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchBrands = createAsyncThunk(
  "cars/fetchBrands",
  async ({ failureCallback }) => {
    return axios
      .get(CarsSliceUrls.brandsUrl)
      .then((res) => {
        const brands = res.data.map((element) => {
          return element.name;
        });
        return { brands };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18n.t("toast_messages.something_went_wrong"));
      });
  }
);

export const fetchModels = createAsyncThunk(
  "cars/fetchModels",
  async ({ brand, failureCallback }) => {
    return axios
      .get(`${CarsSliceUrls.modelsUrl}`, {
        params: { model: brand },
      })
      .then((res) => {
        return { models: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18n.t("toast_messages.something_went_wrong"));
      });
  }
);

export const carsSlice = createSlice({
  name: "orders",
  initialState: {
    brands: [],
    models: [],
  },
  reducers: {},
  extraReducers: {
    [fetchBrands.fulfilled]: (state, action) => {
      state.brands = action.payload?.brands;
    },
    [fetchModels.fulfilled]: (state, action) => {
      state.models = action.payload?.models;
    },
  },
});

export default carsSlice.reducer;
