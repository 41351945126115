import "./SmsPage.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getSmsConfiguration,
  updateSmsConfiguration,
} from "../../../redux/slices/UserSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { validatePhoneNumber } from "../../../utils/Validator";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import Loader from "../../../components/Loader/Loader";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";

const SmsPage = () => {
  let { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [errorDataLoading, setErrorDataLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumbersList, setPhoneNumbersList] = useState([""]);
  const [smsFlag, setSmsFlag] = useState(false);

  const handleInputChange = (index, event) => {
    const newInputs = [...phoneNumbersList];
    newInputs[index] = event;
    setPhoneNumbersList(newInputs);
  };

  const handleAddInput = () => {
    setPhoneNumbersList([...phoneNumbersList, ""]);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(
      getSmsConfiguration({
        successCallback: handleSmsSuccess,
        failureCallback: handleSmsFailure,
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleSmsToggle = (event) => {
    setSmsFlag(event.value === "Off" ? false : true);
  };
  const handleSmsSuccess = (smsFlag, phoneNumbersList) => {
    setSmsFlag(smsFlag);
    setPhoneNumbersList(phoneNumbersList);
    setErrorDataLoading(false);
    setLoading(false);
  };

  const handleSmsFailure = (error) => {
    setErrorDataLoading(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let validToSubmit = true;
    phoneNumbersList.forEach((element) => {
      if (validateInput(element)) {
      } else {
        setSubmitted(true);
        validToSubmit = false;
        return;
      }
    });
    if (validToSubmit) {
      dispatch(
        updateSmsConfiguration({
          smsFlag,
          phoneNumbersList,
          successCallback: handleUpdateSuccess,
          failureCallback: handleUpdateFailed,
        })
      );
    }
  };
  const handleUpdateSuccess = (smsFlag, phoneNumbersList) => {
    setErrorDataLoading(false);
    setSmsFlag(smsFlag);
    setPhoneNumbersList(phoneNumbersList);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.administration.sms_configuration_updated"),
      })
    );
  };
  const handleUpdateFailed = () => {
    setErrorDataLoading(true);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: t(
          "toast_messages.administration.sms_configuration_updated_failed"
        ),
      })
    );
  };
  const validateInput = (phoneNumber) => {
    if (phoneNumber?.length !== 0) {
      if (phoneNumber === undefined) {
        return false;
      } else {
        if (validatePhoneNumber(phoneNumber)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  const handleDeleteInput = (event, index) => {
    event.preventDefault();
    if (phoneNumbersList.length >= 2) {
      const newInputs = [...phoneNumbersList];
      newInputs.splice(index, 1);
      setPhoneNumbersList(newInputs);
    }
  };

  return errorDataLoading ? (
    <ErrorLayout />
  ) : (
    <>
      {loading && <Loader />}

      {!loading && (
        <div className="sms-page">
          <button type="submit" className="submit" onClick={handleSubmit}>
            Save Changes
          </button>
          <div className="sms-main-section">
            <div className="sms-setup-section">
              <div className="sms-enable-section">
                <span className="sms-label">SMS</span>
                <SelectButton
                  value={smsFlag ? "On" : "Off"}
                  className="sms-toggle-button"
                  onChange={handleSmsToggle}
                  options={["Off", "On"]}
                />
              </div>
              {
                <>
                  <div className="phone-number-text">
                    <span className="sms-label">Phone Numbers</span>
                    <Button
                      icon="pi pi-plus icon"
                      rounded
                      text
                      severity="danger"
                      aria-label="Cancel"
                      onClick={handleAddInput}
                    />
                  </div>
                  <form className="sms-phone-number-form">
                    {phoneNumbersList?.map((input, index) => (
                      <div key={index} className="the-whole-input">
                        <div className="phone-input">
                          <PhoneInput
                            required
                            name="phoneNumber"
                            defaultCountry="PS"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            value={input}
                            className="phone-input-field"
                            placeholder={t("common.phone_number")}
                            autoComplete="tel"
                          />
                          {submitted && (
                            <small className="p-error block">
                              {input?.length === 0
                                ? t("common.required_field")
                                : !validateInput(input)
                                ? t("common.enter_valid_phone_number")
                                : true}
                            </small>
                          )}
                        </div>
                        <Button
                          icon="pi pi-times"
                          rounded
                          text
                          severity="danger"
                          aria-label="Cancel"
                          onClick={(event) => handleDeleteInput(event, index)}
                        />
                      </div>
                    ))}
                  </form>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SmsPage;
