import { useTranslation } from "react-i18next";
import "./CustomButton.css";

const Button = (props) => {
  const { name, onClick, style, flex, className, disabled } = props;

  let { t } = useTranslation();

  return (
    <div className={`button-container ${flex}`}>
      <button
        id="custom-button"
        onClick={(e) => onClick(e)}
        style={style}
        className={className}
        disabled={disabled}
      >
        {t(name)}
      </button>
    </div>
  );
};

export default Button;
