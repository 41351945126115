import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "./api/api";
import { LOCAL_STORAGE_FCM_TOKEN } from "./redux/slices/constants";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let app;
if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const auth = firebase.auth();

const messaging = getMessaging();

export const requestFCMToken = async () => {
  return await getToken(messaging, {
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = (callback) => {
  const unsubscribe = onMessage(messaging, callback);
  return unsubscribe;
};

export const notificationSubscriber = (token) => {
  const FCMToken = localStorage.getItem(LOCAL_STORAGE_FCM_TOKEN);
  // eslint-disable-next-line
  if (FCMToken != token && token) {
    //delete the previous token for the duplicates
    if (FCMToken) {
      notificationUnsubscriber(FCMToken);
    }
    localStorage.setItem(LOCAL_STORAGE_FCM_TOKEN, token);
    axios
      .post("notification/subscribe?lang=en", {
        token: token,
        os: "web",
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => console.log(error));
  }
};

export const notificationUnsubscriber = (token) => {
  axios
    .post("notification/unsubscribe", {
      token: token,
    })
    .then((res) => console.log(res.data))
    .catch((error) => console.log(error));
};

export { auth, app, messaging };
