import axios from "axios";
import { useDispatch } from "react-redux";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    type: "v2",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "JWTToken"
      )}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      const store = JSON.parse(localStorage.getItem("persist:root"));
      const user = JSON.parse(store.user);
      if (user.isLogged) {
        localStorage.setItem("unauthorized", true);
        auth
          .signOut()
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
