import "./AddCustomer.css";
import ProfileCard from "../components/GeneralTab/ProfileCard/ProfileCard";

const AddCustomer = ({ closeForm }) => {
  return (
    <div className="add-customer-container">
      <ProfileCard newUser={true} saveProfile={false} closeForm={closeForm} />
    </div>
  );
};

export default AddCustomer;
