import "./CustomerMembershipTab.css";
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";
import CustomMembership from "./CustomMembership/CustomMembership";
import NewMembershipForm from "./NewMembershipForm/NewMembershipForm";
import { fetchCustomer } from "../../../../../redux/slices/CustomersSlice";
import { setToastMessage } from "../../../../../redux/slices/ToastMessageSlice";
import {
  activateMembership,
  deleteMembershipRequest,
  deleteUserMembership,
  fetchUserMemberships,
  updateUserMembership,
} from "../../../../../redux/slices/MembershipsSlice";
import { newMembershipOptions } from "./constants";
import { Button as PrimeReactButton } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE } from "../../../../../constants";
import { Menu } from "primereact/menu";

const CustomerMembershipTab = () => {
  const { features } = useSelector((state) => state.user);
  const { customer, cars } = useSelector((state) => state.customers);
  const { userMemberships } = useSelector((state) => state.memberships);
  const [isEditing, setIsEditing] = useState(false);
  const [carsList, setCarsList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState([]);
  const getFormatedMembership = () => {
    let membership = {
      services: [],
    };
    selectedMembership?.forEach((item, index) => {
      membership.price = item.price;
      membership.startDate = item.startDate;
      membership.endDate = item.endDate;
      membership.car = item.car;
      membership.paid = item.paid;
      membership.services[index] = {
        service: item?.serviceId,
        numberOfTimesLeft: item.numberOfTimesLeft,
        numberOfFreeTimes: item.numberOfFreeTimes,
        numberOfFreeTimesUsed: item.numberOfFreeTimesUsed,
      };
    });
    return membership;
  };

  let { t } = useTranslation();
  let dispatch = useDispatch();
  let params = useParams();

  const [open, setOpen] = useState(false);
  const [option, setOption] = useState("");
  const [loading, setLoading] = useState(true);
  const menu = useRef(null);
  const newMembershipOptionsDropDown = [
    {
      label: t("memberships_list.turqat_usermembership"),
      value: "select_membership",
      command: () => handleSelectedMembershipOption("select_membership"),
    },
    {
      label: t("memberships_list.custom_usermembership"),
      value: "add_custom_membership",
      command: () => handleSelectedMembershipOption("add_custom_membership"),
    },
  ];
  useEffect(() => {
    if (params.id && !customer) {
      dispatch(fetchCustomer({ id: params.id }));
    }
    if (!open) {
      if (userMemberships?.length) {
        setLoading(false);
      }
      dispatch(
        fetchUserMemberships({
          id: customer?._id,
          successCallback: () => setLoading(false),
          failureCallback: () => setLoading(false),
        })
      );
    } else if (customer.memberships?.length === 0) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    getMembershipDetails(userMemberships);
  }, [userMemberships]);

  const handleOpen = () => {
    dispatch(fetchCustomer({ id: params.id }));
    dispatch(
      fetchUserMemberships({
        id: customer?._id,
        successCallback: () => setLoading(false),
        failureCallback: () => setLoading(false),
      })
    );
    setOpen(!open);
    setIsEditing(false);
  };

  const successActivateCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_activated"),
      })
    );
    dispatch(fetchCustomer({ id: params.id }));
    dispatch(
      fetchUserMemberships({
        id: customer?._id,
        successCallback: () => setLoading(false),
        failureCallback: () => setLoading(false),
      })
    );
  };

  const failureActivateCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleActivateMembership = (id) => {
    confirmDialog({
      header: t("memberships_list.activate_membership_pop_up.title"),
      message: t("memberships_list.activate_membership_pop_up.body"),
      accept: () => {
        setLoading(true);
        dispatch(
          activateMembership({
            id: id,
            successCallback: successActivateCallback,
            failureCallback: failureActivateCallback,
          })
        );
      },
      reject: () => console.log("reject"),
    });
  };

  const handleSelectedMembershipOption = (option) => {
    setOption(option);
    setOpen(!open);
  };

  const successUpdateDeleteUserMembership = (isDeleted = false) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t(
          `toast_messages.membership.${
            isDeleted ? "membership_deleted" : "membership_updated"
          }`
        ),
      })
    );
    handleOpen();
  };

  const handleSaveMembership = (membership) => {
    sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
    const updatedMembership = { ...membership };
    updatedMembership.services = membership?.services?.map((service) => ({
      numberOfFreeTimes: service.numberOfFreeTimes,
      numberOfFreeTimesUsed: service.numberOfFreeTimesUsed,
      service: service.service,
    }));
    setLoading(true);
    setIsEditing(false);
    dispatch(
      updateUserMembership({
        id: selectedMembership[0]._id,
        membership: updatedMembership,
        successCallback: successUpdateDeleteUserMembership,
        failureCallback: failureActivateCallback,
      })
    );
  };

  const handleDeleteMembership = (userMembership) => {
    confirmDialog({
      header: t("memberships_list.delete_membership_pop_up.title"),
      message: t("memberships_list.delete_membership_pop_up.body"),
      accept: () => {
        sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
        setLoading(true);
        const op =
          userMembership?.status === "inactive"
            ? deleteMembershipRequest
            : deleteUserMembership;
        dispatch(
          op({
            id: userMembership?._id,
            index: 0,
            successCallback: () => {
              successUpdateDeleteUserMembership(true);
              setOpen(false);
            },
            failureCallbeck: failureActivateCallback,
          })
        );
      },
      reject: () => console.log("reject"),
    });
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    handleSelectedMembershipOption(newMembershipOptions.CUSTOM_MEMBERSHIP);
  };

  const renderNumberOfTimesComponent = (rowData) => {
    return (
      <div className="number-of-times-left-label">
        {rowData.numberOfTimesLeft}
      </div>
    );
  };
  const getFullName = (data) => {
    return (
      data && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{data.membershipInfo}</div>
          <div
            className="customer-membership-buttons-container"
            style={{ height: "fit-content" }}
          >
            {features.customers.tabs.membership.edit && !isEditing && (
              <PrimeReactButton
                type="button"
                icon="pi pi-pencil"
                onClick={handleStartEditing}
                className="membership-action-button"
                tooltip="Edit membership"
                tooltipOptions={{ position: "top" }}
              />
            )}
            {features.customers.tabs.membership.delete && (
              <PrimeReactButton
                type="button"
                icon="pi pi-trash"
                onClick={() => {
                  handleDeleteMembership(data);
                }}
                className="membership-action-button"
                tooltip="Delete membership"
                tooltipOptions={{ position: "top" }}
              />
            )}
            {features.customers.tabs.membership.activate &&
              data?.status === "inactive" && (
                <PrimeReactButton
                  type="button"
                  icon="pi pi-check-circle"
                  onClick={() => {
                    handleActivateMembership(data._id);
                  }}
                  className="membership-action-button"
                  tooltip="Activate membership"
                  tooltipOptions={{ position: "top" }}
                />
              )}
          </div>
        </div>
      )
    );
  };
  const getMembershipDetails = () => {
    const userReducedMemberships = userMemberships.reduce((acc, item) => {
      if (!acc[item._id]) {
        acc[item._id] = {
          _id: item._id,
          carInfo: item.carInfo,
          car: item.car,
        };
      }
      return acc;
    }, {});

    const userReducedMembershipsValues = Object.values(userReducedMemberships);
    setCarsList(userReducedMembershipsValues ?? []);
    const previousSelectedCar = userReducedMembershipsValues.find(
      (element) => element._id === selectedCar?._id
    );
    handleChangeSelectedMembership(
      previousSelectedCar
        ? previousSelectedCar
        : userReducedMembershipsValues?.length
        ? userReducedMembershipsValues[0]
        : ""
    );
  };
  const handleChangeSelectedMembership = (membership) => {
    if (membership) {
      setSelectedCar(membership);
      const selectedMembership = userMemberships.filter(
        (element) => element.car === membership.car
      );
      setSelectedMembership(selectedMembership);
    } else {
      setSelectedMembership([]);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {!open ? (
        <div
          className="customer-membership-section"
          style={{ marginTop: "10px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: customer?.memberships?.length
                ? "space-between"
                : "flex-end",
            }}
          >
            {customer?.memberships?.length ? (
              <Dropdown
                options={carsList}
                placeholder={t("memberships_list.user_membership_car")}
                onChange={(e) => {
                  handleChangeSelectedMembership(e.value);
                }}
                className="add-customer-membership-dropdown padded-input-text"
                value={selectedCar}
                style={{ width: "330px" }}
                optionLabel={"carInfo"}
                filter
              />
            ) : (
              <></>
            )}

            {features.customers.tabs.membership.add &&
              (!cars?.length ||
                (customer?.memberships?.length !== cars?.length && (
                  <div className="card flex justify-content-center">
                    <Menu
                      model={newMembershipOptionsDropDown}
                      popup
                      ref={menu}
                      id="popup_menu_left"
                      onClick={(e) => {
                        handleSelectedMembershipOption(e.value);
                      }}
                    />
                    <PrimeReactButton
                      style={{
                        backgroundColor: "#56ccf2",
                        borderColor: "#56ccf2",
                        marginBottom: "12px",
                      }}
                      label={t("memberships_list.add_usermembership")}
                      className="mr-2"
                      onClick={(event) => {
                        menu.current.toggle(event);
                      }}
                      aria-controls="popup_menu_left"
                      aria-haspopup
                    />
                  </div>

                  // <Dropdown
                  //   options={newMembershipOptionsDropDown}
                  //   placeholder={t("memberships_list.user_membership")}
                  //   onChange={(e) => handleSelectedMembershipOption(e.value)}
                  //   className="add-customer-membership-dropdown padded-input-text"
                  // />
                )))}
          </div>
          {getFullName(
            selectedMembership.length ? selectedMembership[0] : undefined
          )}
          <DataTable
            sortOrder={1}
            sortMode="single"
            groupRowsBy="fullName"
            responsiveLayout="scroll"
            className="customer-memberships-table"
            emptyMessage={t("memberships_list.customer_has_no_memberships")}
            value={customer?.memberships.length === 0 ? [] : selectedMembership}
            style={{ marginTop: "10px" }}
          >
            <Column
              field="fullName"
              header={t("common.name")}
              className="customer-membership-row-name"
              hidden
            ></Column>
            <Column
              field="serviceName"
              header={t("common.service_name")}
            ></Column>
            <Column
              field="numberOfTimesLeft"
              body={renderNumberOfTimesComponent}
              header={t("memberships_list.membership.number_of_times_left")}
            ></Column>
          </DataTable>
        </div>
      ) : option === newMembershipOptions.SELECT_MEMBERSHIP ? (
        <NewMembershipForm handleOpen={handleOpen} />
      ) : (
        <CustomMembership
          handleOpen={handleOpen}
          isEditing={isEditing}
          userMembership={getFormatedMembership()}
          handleEditMembership={handleSaveMembership}
          handleClosing={() => {
            setOpen(false);
            setIsEditing(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerMembershipTab;
