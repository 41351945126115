export const userRoles = {
  CRM: "crm",
  ADMIN: "admin",
  CUSTOMER: "customer",
  DISPATCHER: "dispatcher",
  SUPER_ADMIN: "superadmin",
};

export const languages = {
  ENGLISH: "english",
  ARABIC: "arabic",
};

export const SERVICES_TABS = {
  REQUESTS: 0,
  MEMBERSHIPS: 1,
  SERVICES: 2,
  PROMO_CODE: 3,
};
export const ROWS_IN_TABLE = 20;

export const ALL_ORDERS_TABLE = "allOrdersTable";
export const CUSTOMERS_TABLE = "customersTable";
export const PARTNERS_TABLE = "partnersTable";
export const PREVENT_LOADING_WHEN_RETURNED = "preventLoadingWhenReturned";
export const PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE =
  "preventLoadingWhenReturnedCustomerPage";
