export const authErrorMessages = {
  WEAK_PASSWORD: "password.weak_password",
  USER_DISABLED: "user_disabled",
  NETWORK_FAILED: "network_failed",
  EMIL_IS_USED: "email.email_is_used",
  INVALID_PASSWORD: "password.invalid_password",
  EMAIL_NOT_FOUND: "email.email_not_found",
  WRONG_USER_PASSWORD: "password.wrong_user_password",
  INVALID_OOB_CODE: "oob_code.invalid_oob_code",
  EXPIRED_OOB_CODE: "oob_code.expired_oob_code",
  PASSWORDS_DO_NOT_MATCH: "password.passwords_do_not_match",
};

export const authErrorCodes = {
  WEAK_PASSWORD: "auth/weak-password",
  WRONG_PASSWORD: "auth/wrong-password",
  USER_NOT_FOUND: "auth/user-not-found",
  INVALID_PASSWORD: "auth/wrong-password",
  NETWORK_FAILED: "auth/network-request-failed",
};
