import "./NotVerified.css";
import { useState } from "react";
import { auth } from "../../firebase";
import images from "../../assets/images";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { setLogged } from "../../redux/slices/UserSlice";

const NotVerified = () => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  let { t } = useTranslation();
  let location = useLocation();
  let dispatch = useDispatch();

  const handleVerification = async () => {
    setLoading(true);
    auth.currentUser
      .sendEmailVerification()
      .then(() => {
        setLoading(false);
        setEmailSent(true);
        setTimeout(() => {
          dispatch(setLogged({ isLogged: false }));
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setEmailSent(false);
      });
  };

  return (
    <div className="not-verified-container">
      {loading ? (
        <Loader />
      ) : (
        <div className="verify-email-container">
          {emailSent ? (
            <>
              <h2>{t("email_sent..title")}</h2>
              <small>
                {t("verify_email_page.email_sent_message.first_segment")}
                <strong> {location.state?.email} </strong>
                {t("verify_email_page.email_sent_message.second_segment")}
              </small>
              <img
                src={images.accept}
                alt="very-email"
                className="email-sent-icon"
              />
            </>
          ) : (
            <>
              <img
                src={images.verifyEmail}
                alt="very-email"
                className="verify-email-image"
              />
              <h2>{t("verify_email_page.title")}</h2>
              <div>
                <small>
                  {t("verify_email_page.top_message.first_segment")}{" "}
                  <strong>{location.state?.email} </strong>
                  {t("verify_email_page.top_message.second_segment")}
                </small>
                <br />
                <small>{t("verify_email_page.bottom_message")}</small>
              </div>
              <Button
                className="verify-email-button"
                onClick={() => handleVerification()}
              >
                {t("buttons.verify_email_button")}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NotVerified;
