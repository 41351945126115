import { FilterMatchMode, FilterOperator } from "primereact/api";

export const statuses = [
  { label: "Visa", value: "visa" },
  { label: "Cash", value: "cash" },
];

export const paidOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const tableFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  "user.fullName": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "user.phoneNumber": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  brandModel: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  paymentMethod: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  paid: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
};

export const actionsDropDown = [
  { label: "Activate", value: "activate" },
  { label: "Delete", value: "delete" },
  { label: "Mark as Paid", value: "mark" },
];

export const actions = {
  ACTIVATE: "activate",
  DELETE: "delete",
  MARK: "mark",
};
