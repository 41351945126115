import React from "react";
import Map from "../pages/Map/Map";
import {  useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import SignIn from "../pages/SignIn/SignIn";
// import SignUp from "../pages/SignUp/SignUp";
import Services from "../pages/Services/Services";
import Dashboard from "../pages/Dashboard/Dashboard";
import OrdersList from "../pages/OrdersList/OrdersList";
import NotVerified from "../pages/NotVerified/NotVerified";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import PartnersList from "../pages/PartnersList/PartnersList";
import Customer from "../pages/CustomersList/Customer/Customer";
import OrderForm from "../pages/OrdersList/OrderForm/OrderForm";
import CustomersList from "../pages/CustomersList/CustomersList";
import NotAuthorized from "../pages/NotAuthorized/NotAuthorized";
import Administration from "../pages/Administration/AdministrationPage";
import ServicesList from "../pages/Services/ServicesList/ServicesList";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import PartnersForm from "../pages/PartnersList/PartnersForm/PartnersForm";
import ServiceForm from "../pages/Services/ServicesList/ServiceForm/ServiceForm";
import MembershipForm from "../pages/Services/MembershipsList/MembershipForm/MembershipForm";

const Router = () => {
  const { features } = useSelector((state) => state.user);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route exact path="/" element={<SignIn />} />
        <Route path="/sign-in" element={<SignIn />}>
          <Route path=":mode/:oobCode/:key" element={<SignIn />} />
          <Route path="" element={<SignIn />} />
        </Route>
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
        <Route element={<Dashboard />}>
          <Route
            path="/not-authorized"
            element={
              <PrivateRoute>
                <NotAuthorized />
              </PrivateRoute>
            }
          />
          <Route
            path="/not-verified"
            element={
              <PrivateRoute>
                <NotVerified />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Navigate to={features.config?.initialRoute} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/customers"
            element={
              <PrivateRoute>
                <CustomersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/customers/:id"
            element={
              <PrivateRoute>
                <Customer />
              </PrivateRoute>
            }
          />
          {features.partners.view && (
            <>
              <Route
                path="/dashboard/partners"
                element={
                  <PrivateRoute>
                    <PartnersList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/partners/:id"
                element={
                  <PrivateRoute>
                    <PartnersForm newPartner={false} />
                  </PrivateRoute>
                }
              />
            </>
          )}
          {features.map.view && (
            <Route
              path="/dashboard/map"
              element={
                <PrivateRoute>
                  <Map />
                </PrivateRoute>
              }
            />
          )}
          {features.services.servicesTab && (
            <>
              <Route
                path="/dashboard/services"
                element={
                  <PrivateRoute>
                    <Services />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/services/:id"
                element={
                  <PrivateRoute>
                    <ServiceForm />
                  </PrivateRoute>
                }
              />
            </>
          )}
          {features.administration.view && (
            <Route
              path="/dashboard/administration"
              element={
                <PrivateRoute>
                  <Administration />
                </PrivateRoute>
              }
            />
          )}
          {features.services.memberships.view && (
            <>
              <Route
                path="/dashboard/memberships"
                element={
                  <PrivateRoute>
                    <ServicesList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/memberships/:id"
                element={
                  <PrivateRoute>
                    <MembershipForm newMembership={false} />
                  </PrivateRoute>
                }
              />
            </>
          )}
          {features.orders.order.view && (
            <>
              <Route
                path="/dashboard/orders"
                element={
                  <PrivateRoute>
                    <OrdersList userOrders={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/orders/:id"
                element={
                  <PrivateRoute>
                    <OrderForm open={false} />
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            path="/dashboard/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};

export default Router;
