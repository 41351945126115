import { FilterMatchMode, FilterOperator } from "primereact/api";

export const statues = [
  "open",
  "assigned",
  "canceled",
  "completed",
  // "inprogress",
];

export const paidStatus = ["true", "false"];

export const tableFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  customerName: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  status: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  car: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  service: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  paid: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
};
export const carTableFilter = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
};
export const googleMapUrl = "https://maps.google.com/?q=";

export const TIRE_REPAIR_SERVICE = "TIRE_REPAIR";
export const TOWING_SERVICE = "TOWING";
export const ORDER_STATUSES = {
  OPEN: "open",
  CANCELED: "canceled",
  ASSIGNED: "assigned",
  COMPLETED: "completed",
};
export const ORDER_SOURCE_WEB = "web";
export const PAGE_SIZE = 100;
