import i18next from "i18next";
import axios from "../../api/api";
import { NETWORK_ERROR } from "./constants";
import { PromotionCodesSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchPromotionCodes = createAsyncThunk(
  "promotionCodes/fetchPromotionCodes",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(PromotionCodesSliceUrls.promoCodesUrl)
      .then((res) => {
        res.data.forEach((code) => {
          code.expiry = new Date(code.expiry).toLocaleString("en-US", {
            timeZone: "UTC",
          });
          return code;
        });
        successCallback();
        return { promotionCodes: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);

export const addPromotionCode = createAsyncThunk(
  "promotionCodes/addPromoCode",
  async ({ promotionCode, successCallback, failureCallback }) => {
    return axios
      .post(PromotionCodesSliceUrls.promoCodesUrl, {
        ...promotionCode,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);

export const deletePromotionCode = createAsyncThunk(
  "promotionCodes/deletePromotionCode",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .delete(PromotionCodesSliceUrls.promoCodesUrl + id)
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);

export const promotionCodesSlice = createSlice({
  name: "promotionCodes",
  initialState: {
    promotionCodes: [],
    promotionCode: null,
  },
  reducers: {
    setPromotionCode: (state, action) => {
      state.promotionCodes = action.payload.promotionCodes;
    },
  },
  extraReducers: {
    [fetchPromotionCodes.fulfilled]: (state, action) => {
      state.promotionCodes = action.payload?.promotionCodes;
    },
  },
});

export const { setPromotionCode } = promotionCodesSlice.actions;

export default promotionCodesSlice.reducer;
