import _ from "lodash";
import "./MembershipForm.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import AddSvg from "../../../../assets/svg/Add.svg";
import { InputNumber } from "primereact/inputnumber";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../../../assets/svg/Close.svg";
import Layout from "../../../../components/Layout/Layout";
import Loader from "../../../../components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { validateForm } from "../../../../utils/Validator";
import Button from "../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../redux/slices/ToastMessageSlice";
import {
  addMembership,
  fetchMembership,
  updateMembership,
  fetchMemberships,
} from "../../../../redux/slices/MembershipsSlice";
import { setServicesTab } from "../../../../redux/slices/TabsSlice";
import { SERVICES_TABS } from "../../../../constants";

const MembershipForm = ({ open, newMembership, handleOpen }) => {
  const { features } = useSelector((state) => state.user);
  const { membership } = useSelector((state) => state.memberships);
  const { servicesIdKeys, servicesNames, servicesNameId } = useSelector(
    (state) => state.services
  );

  const initialMembershipInfo = {
    name: "",
    price: 0,
    services: [],
    defaultMembership: false,
    active: false,
  };
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [notSelectedOptions, setNotSelectedOptions] = useState([]);
  const [servicesValidation, setServicesValidation] = useState([]);
  const [membershipInfo, setMembershipInfo] = useState(initialMembershipInfo);

  let { t } = useTranslation();
  let dispatch = useDispatch();
  let params = useParams();
  let navigate = useNavigate();

  const getNotSelectedOptions = () => {
    const options = [...servicesNames];
    if (!membership) {
      setNotSelectedOptions(options);
      return options;
    }
    for (let i = 0; i < membership.services?.length; i++) {
      const service = membership.services[i];
      const serviceId = service.service;
      const serviceType = servicesIdKeys[serviceId];
      if (options.includes(t("services_page.services." + serviceType))) {
        const index = options.indexOf(
          t("services_page.services." + serviceType)
        );
        options.splice(index, 1);
      }
    }
    setNotSelectedOptions(options);
  };

  const failureFetchMembershipCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    getNotSelectedOptions();
    if (params.id) {
      dispatch(
        fetchMembership({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: (message) => failureFetchMembershipCallback(message),
        })
      );
    } else if (open) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (membership) {
      const membershipObj = initialMembershipInfo;
      const keys = Object.keys(membershipObj);
      keys.forEach((key) => {
        if (membership[key] === undefined) {
          membershipObj[key] = "";
        } else {
          membershipObj[key] = membership[key];
        }
      });
      setMembershipInfo(membershipObj);
      getNotSelectedOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  const validateServices = () => {
    const services = membershipInfo.services;
    if (services?.length === 0) {
      return false;
    }
    const arr = [];
    let validServices = true;
    for (let i = 0; i < services?.length; i++) {
      arr.push({
        validNumberOfTimes: true,
        validServiceType: true,
      });
    }
    services.forEach((service, index) => {
      if (
        typeof service.numberOfFreeTimes !== "number" ||
        service.numberOfFreeTimes <= 0
      ) {
        arr[index].validNumberOfTimes = false;
        validServices = false;
      }
      if (service.service?.length === 0) {
        validServices = false;
        arr[index].validServiceType = false;
      }
    });
    setServicesValidation(arr);
    return validServices;
  };

  const successAddUpdateMembershipCallback = ({ addedMembership }) => {
    dispatch(
      fetchMemberships({ successCallback: () => {}, failureCallback: () => {} })
    );
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: addedMembership
          ? t("toast_messages.membership.membership_added")
          : t("toast_messages.membership.membership_updated"),
      })
    );
    if (addedMembership) {
      handleOpen();
    } else {
      dispatch(setServicesTab({ servicesTab: SERVICES_TABS.MEMBERSHIPS }));
      navigate("/dashboard/services");
    }
  };

  const failureAddUpdateMembershipCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleSave = () => {
    setSubmitted(true);
    let validated = validateForm([membershipInfo.name, membershipInfo.price]);
    if (membershipInfo.price === 0) {
      validated = false;
    }
    const validServices = validateServices();
    if (validated && validServices) {
      if (!newMembership) {
        dispatch(
          updateMembership({
            membership: membershipInfo,
            id: membership._id,
            successCallback: ({ addedMembership }) =>
              successAddUpdateMembershipCallback({ addedMembership }),
            failureCallback: (message) =>
              failureAddUpdateMembershipCallback(message),
          })
        );
      } else {
        dispatch(
          addMembership({
            membership: membershipInfo,
            successCallback: ({ addedMembership }) =>
              successAddUpdateMembershipCallback({ addedMembership }),
            failureCallback: (message) =>
              failureAddUpdateMembershipCallback(message),
          })
        );
      }
    }
  };

  const handleAdd = () => {
    setSubmitted(false);
    const membership = _.cloneDeep(membershipInfo);
    membership.services.push({ service: "", numberOfFreeTimes: 0 });
    setMembershipInfo(membership);
  };

  const ItemTemplate = (rowData) => {
    return (
      <div role="option" aria-selected="true">
        {rowData}
      </div>
    );
  };

  const handleDisabled = (rowData) => {
    if (notSelectedOptions.includes(rowData)) {
      return false;
    } else {
      return true;
    }
  };

  const handleDelete = (index) => {
    const membership = _.cloneDeep(membershipInfo);
    const deletedServiceId = membership.services[index].service;
    const deletedServiceType = servicesIdKeys[deletedServiceId];
    const deletedServiceName = t(
      "services_page.services." + deletedServiceType
    );
    setNotSelectedOptions([...notSelectedOptions, deletedServiceName]);
    membership.services.splice(index, 1);
    setMembershipInfo(membership);
    const validatedServices = [...servicesValidation];
    validatedServices.splice(index, 1);
    setServicesValidation(validatedServices);
  };

  const handleChange = (e, index) => {
    if (
      e?.target?.name === "service" ||
      e?.originalEvent?.target.name === "numberOfFreeTimes"
    ) {
      const services = _.cloneDeep(membershipInfo.services);
      if (e.originalEvent.target.name === "numberOfFreeTimes") {
        services[index][e.originalEvent.target.name] = e.value;
      } else {
        const value = e.target.value;
        const options = [...notSelectedOptions];
        if (notSelectedOptions.includes(value)) {
          const index1 = notSelectedOptions.indexOf(value);
          options.splice(index1, 1);
          const oldValueId = services[index].service;
          const oldValueType = servicesIdKeys[oldValueId];
          const serviceName = t("services_page.services." + oldValueType);
          options.push(serviceName);
        }
        setNotSelectedOptions(options);
        const id = servicesNameId[e.target.value];
        services[index][e.target.name] = id;
      }
      setMembershipInfo({ ...membershipInfo, services });
    } else {
      setMembershipInfo({ ...membershipInfo, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    if (!newMembership) {
      dispatch(setServicesTab({ servicesTab: SERVICES_TABS.MEMBERSHIPS }));
      navigate("/dashboard/services");
    } else {
      handleOpen();
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Layout
      className={`membership-form-container ${open ? "add-membership" : ""}`}
    >
      <div className="membership-form">
        <div className="close-button-container">
          <div className="form-title">
            {open
              ? t("buttons.new_membership_button")
              : t("services_page.memberships.membership_form.title")}
          </div>
          <img
            src={CloseSvg}
            alt="close car form"
            onClick={() => handleClose()}
            id="close-card-form-button"
          />
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                name="name"
                className="form-input"
                onChange={handleChange}
                value={membershipInfo.name}
              />
              <label>{t("memberships_list.membership.membership_name")}</label>
            </span>
          </div>
          {submitted && membershipInfo.name?.length === 0 && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        {membershipInfo.services?.map((membershipService, index) => {
          const serviceId = membershipService?.service;
          const serviceType = servicesIdKeys[serviceId];
          return (
            <div className="row" key={index}>
              <div className="row-full-width">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <Dropdown
                      name="service"
                      options={servicesNames}
                      itemTemplate={ItemTemplate}
                      optionDisabled={handleDisabled}
                      onChange={(e) => handleChange(e, index)}
                      value={
                        serviceType
                          ? t("services_page.services." + serviceType)
                          : ""
                      }
                    />
                    <label>{t("common.service_type")}</label>
                  </span>
                </div>
                {submitted &&
                  !servicesValidation[index]?.validServiceType &&
                  membershipService?.service?.length === 0 && (
                    <small className="p-error block">
                      {t("common.required_field")}
                    </small>
                  )}
              </div>
              <div className="row-full-width">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <InputNumber
                      min={0}
                      name="numberOfFreeTimes"
                      className="form-input integer-only"
                      onChange={(e) => handleChange(e, index)}
                      value={membershipService.numberOfFreeTimes}
                    />

                    <label>
                      {t(
                        "services_page.memberships.membership_form.number_of_uses"
                      )}
                    </label>
                  </span>
                </div>
                {submitted &&
                  !servicesValidation[index]?.validNumberOfTimes && (
                    <small className="p-error block">
                      {t("common.required_field")}
                    </small>
                  )}
              </div>
              <img
                src={CloseSvg}
                alt="delete-button"
                id="delete-service-button"
                onClick={() => handleDelete(index)}
                className={
                  !features.services.memberships.edit
                    ? "memberships-form-features-not-allowed"
                    : ""
                }
              />
            </div>
          );
        })}
        <div>
          <button
            className={`add-new-service-button ${
              membershipInfo?.services?.length === 5 ||
              !features.services.memberships.edit
                ? "disabled"
                : ""
            }`}
            onClick={() => handleAdd()}
            disabled={
              membershipInfo?.services?.length === 5 ||
              !features.services.memberships.edit
            }
          >
            <img src={AddSvg} alt="add new service" />
            {t("buttons.add_service_button")}
          </button>
          {submitted && membershipInfo?.services?.length === 0 && (
            <small className="p-error block">
              * Please add at least one service
            </small>
          )}
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputNumber
                min={0}
                maxFractionDigits={2}
                name="price"
                value={membershipInfo.price}
                onValueChange={handleChange}
                className="form-input integer-only"
              />
              <label>
                {t("services_page.memberships.membership_form.total_price")}
              </label>
            </span>
          </div>
          {submitted && !(membershipInfo.price > 0) && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div className="row default-membership-container">
          <Checkbox
            checked={membershipInfo.defaultMembership}
            onChange={(e) =>
              setMembershipInfo({
                ...membershipInfo,
                defaultMembership: e.checked,
              })
            }
            disabled={!features.services.memberships.edit}
          />
          <label htmlFor="binary">
            {t("services_page.memberships.membership_form.default_membership")}
          </label>
        </div>
        <div className="row default-membership-container">
          <Checkbox
            checked={membershipInfo?.active}
            onChange={(e) =>
              setMembershipInfo({
                ...membershipInfo,
                active: e.checked,
              })
            }
            disabled={!features.services.memberships.edit}
          />
          <label htmlFor="binary">
            {t("services_page.memberships.membership_form.active_membership")}
          </label>
        </div>
        <Button
          flex="flexStart"
          onClick={() => handleSave()}
          name="buttons.save_membership_button"
          className={
            !features.services.memberships.edit
              ? "memberships-list-features-not-allowed"
              : ""
          }
        />
      </div>
    </Layout>
  );
};

export default MembershipForm;
