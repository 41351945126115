import "./PartnersList.css";
import { Column } from "primereact/column";
import { tableFilters } from "./constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import PartnersForm from "./PartnersForm/PartnersForm";
import { Button as PrimeReactButton } from "primereact/button";
import Button from "../../components/CustomButton/CustomButton";
import ErrorLayout from "../../components/ErrorLayout/ErrorLayout";
import { fetchPartners, resetPartner } from "../../redux/slices/PartnersSlice";
import {
  PARTNERS_TABLE,
  PREVENT_LOADING_WHEN_RETURNED,
  ROWS_IN_TABLE,
} from "../../constants";

const PartnersList = () => {
  const { features } = useSelector((state) => state.user);
  const { partners, errors } = useSelector((state) => state.partners);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(tableFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const returned = sessionStorage.getItem(PREVENT_LOADING_WHEN_RETURNED);
  useEffect(() => {
    if (returned) {
      setLoading(false);
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
    }
  }, [returned]);
  let dispatch = useDispatch();
  let { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!returned) {
      if (partners) {
        setLoading(false);
      }
      dispatch(
        fetchPartners({
          successCallback: () => setLoading(false),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowSelect = (event) => {
    sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
    navigate(`/dashboard/partners/${event.data._id}`);
  };

  const handleOpen = () => {
    setLoading(true);
    dispatch(
      fetchPartners({
        successCallback: () => setLoading(false),
      })
    );
    setOpen(!open);
  };
  const convertOpen = () => {
    setOpen(!open);
  };
  const actionBodyTemplate = (e) => {
    return (
      <PrimeReactButton
        type="button"
        icon="pi pi-pencil"
        onClick={() => onRowSelect({ data: e })}
        className={!features.partners.add ? "partners-feature-not-allowed" : ""}
      />
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const servicesBodyTemplate = (rowData) => {
    return rowData.services
      .map((service) => t(`services_page.services.${service}`))
      .join(", ");
  };

  useEffect(() => {
    if (returned) {
      const partnerTableSession = JSON.parse(
        sessionStorage.getItem(PARTNERS_TABLE)
      );
      if (partnerTableSession) {
        setGlobalFilterValue(partnerTableSession.filters?.global?.value);
      } else {
        setGlobalFilterValue(filters?.global?.value);
      }
    } else {
      setGlobalFilterValue(filters?.global?.value);
    }
    // eslint-disable-next-line
  }, [open]);

  return open ? (
    <PartnersForm
      newPartner={true}
      handleOpen={handleOpen}
      convertOpen={convertOpen}
    />
  ) : errors ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <Layout className="partners-table-container">
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
      <div className="table-header">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder={t("searches.keyword_search")}
        />
        <Button
          onClick={() => {
            dispatch(resetPartner());
            setOpen(!open);
          }}
          name="buttons.add_new_partner_button"
          className={
            !features.partners.add ? "partners-feature-not-allowed" : ""
          }
        />
      </div>
      <DataTable
        rowHover
        paginator
        rows={ROWS_IN_TABLE}
        scrollable
        dataKey="_id"
        value={partners}
        filters={filters}
        filterDisplay="menu"
        selectionMode="single"
        responsiveLayout="scroll"
        onRowSelect={onRowSelect}
        emptyMessage={t("partners_page.no_partners_found")}
        globalFilterFields={["name", "phoneNumber", "city"]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        onFilter={(e) => setFilters(e.filters)}
        stateStorage="session"
        stateKey={PARTNERS_TABLE}
      >
        <Column
          sortable
          field="name"
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="partners-list-main-column"
          header={t("partners_page.partner_name")}
        />
        <Column
          sortable
          field="phoneNumber"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.phone_number")}
          className="partners-list-main-column"
        />
        <Column
          sortable
          field="city"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.location")}
          className="partners-list-main-column"
        />
        <Column
          sortable
          field="services"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={servicesBodyTemplate}
          header={t("common.services")}
          className="partners-list-main-column"
        />
        <Column
          sortable
          field="partnerNote"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.note")}
          className="partners-list-main-column"
        />
        <Column
          body={actionBodyTemplate}
          headerClassName="partners-list-header"
        />
      </DataTable>
    </Layout>
  );
};

export default PartnersList;
