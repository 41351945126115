import i18next from "i18next";
import axios from "../../api/api";
import { NETWORK_ERROR } from "./constants";
import { CustomersSliceUrls, OrdersSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({ queryParams, successCallback, failureCallback }) => {
    return axios
      .get(
        !queryParams
          ? OrdersSliceUrls.ordersUrl
          : `${OrdersSliceUrls.ordersUrl}?${Object.entries(queryParams)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
      )
      .then((res) => {
        let orders = [];
        (res.data?.list ?? []).forEach((item) => {
          const order = {};
          order.car = item.car
            ? `${item.car.brand} - ${item.car.model} ${
                item?.car?.licensePlate ? `- ${item?.car?.licensePlate}` : ""
              } ${item.car.productionYear ? "-" + item.car.productionYear : ""}`
            : "Deleted Car";
          order.customerName = item.user ? item.user.fullName : "Deleted User";
          order.customerPhoto = item.user ? item.user?.photo : "";

          order.service = i18next.t(
            "services_page.services." + item.service.key
          );
          order.location = item.serviceLocation;
          order.date = new Date(item.createdDate).toLocaleString();
          order.status = i18next.t("orders_list.order_statuses." + item.status);
          order._id = item._id;
          order.type = item.type;
          order.paid = item.paid;
          order.customerId = item.user ? item.user._id : null;
          order.feedback = item.feedback;
          order.source = item.source;
          orders.push(order);
        });
        successCallback();
        return {
          orders,
          count: res.data.count,
          pages: res.data.pages,
          page: queryParams.page,
        };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);

export const fetchCustomerOrders = createAsyncThunk(
  "orders/fetchCustomerOrders",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`admin/${id}/order`)
      .then((res) => {
        const customerOrders = [];
        res.data.forEach((item) => {
          const order = {};
          order.customerName = item.user.fullName;
          order.car = item.car
            ? `${item.car?.brand ?? ""} / ${item.car?.model ?? ""} ${
                item?.car?.licensePlate ? ` / ${item?.car?.licensePlate}` : ""
              } ${
                item.car?.productionYear
                  ? ` / ${item?.car?.productionYear}`
                  : ""
              }`
            : "Deleted Car";
          order.service = i18next.t(
            "services_page.services." + item.service.key
          );
          order.location = item.coordinates;
          order.status = i18next.t("orders_list.order_statuses." + item.status);
          order.date = new Date(item.createdDate).toLocaleString();
          order._id = item._id;
          order.customerId = item.user._id;
          order.paid = item.paid;
          customerOrders.push(order);
        });
        successCallback();
        return { customerOrders };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);

export const fetchTodayOrders = createAsyncThunk(
  "orders/fetchTodayOrders",
  async ({ successCallback, failureCallback }) => {
    const cities = await axios
      .get(`${CustomersSliceUrls.citiesUrl}`)
      .catch(failureCallback);
    return axios
      .get(OrdersSliceUrls.ordersToday)
      .then((res) => {
        const todayOrders = [];
        res.data.forEach((order) => {
          if (order.user !== null && order.car !== null) {
            const todayOrder = {};
            todayOrder.status = order.status;
            todayOrder.order = order._id;
            todayOrder.customerId = order.user.id;
            todayOrder.firstName = order.user.firstName;
            todayOrder.lastName = order.user.lastName;
            todayOrder.fullName = order.user.fullName;
            todayOrder.createdDate = order.createdDate;
            todayOrder.serviceLocation =
              order.serviceLocation.length > 0
                ? order.serviceLocation
                : getCityLocation(order.city, cities.data);
            todayOrder.provider = order?.provider;
            todayOrder._id = order._id;
            todayOrder.price = order?.price;
            todayOrder.paid = order?.paid;
            todayOrders.push(todayOrder);
          }
        });
        console.log(todayOrders);
        successCallback();
        return { todayOrders };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);
const getCityLocation = (city, cities) => {
  if (city != null) {
    const cityLocation = cities.filter((element) => {
      return element.name === city;
    });
    return cityLocation[0].location.coordinates;
  } else return [];
};
export const fetchOrder = createAsyncThunk(
  "orders/fetchOrder",
  async ({ id, successCallback, failureCallback, setCustomerInfo }) => {
    return axios
      .get(`${OrdersSliceUrls.getOrderByIdUrl}/${id}`)
      .then((res) => {
        ordersSlice.actions.resetOrder();
        setCustomerInfo?.(
          res.data[0].user ? res.data[0].user._id : "Deleted User",
          res.data[0].user ? res.data[0].user.fullName : "Deleted User"
        );
        const order = {};
        order._id = res.data[0]._id;
        order.paid = res.data[0].paid;
        order.city = res.data[0]?.city;
        order.user = res.data[0]?.user;
        order.price = res.data[0].price;
        order.servicePrice = res.data[0]?.servicePrice;
        order.partsPrice = res.data[0]?.partsPrice;
        order.car = res.data[0]?.car ? res.data[0]?.car?._id : "Deleted Car";
        order.status = res.data[0].status;
        order.provider = res.data[0]?.provider;
        order.feedback = res.data[0]?.feedback;
        order.createdDate = res.data[0].createdDate;
        order.service = res.data[0].service._id;
        order.customerId = res.data[0].user
          ? res.data[0].user._id
          : "Deleted User";
        order.paymentMethod = res.data[0].paymentMethod;
        order.locationNote = res.data[0]?.locationNote;
        order.numberOfWheels = res.data[0]?.numberOfWheels;
        order.serviceLocation = res.data[0]?.serviceLocation;
        order.destinationNote = res.data[0]?.destinationNote;
        order.cancellationMessage = res.data[0]?.cancellationMessage;
        order.destinationLocation = res.data[0]?.destinationLocation;
        order.serviceSubCategories = res.data[0]?.serviceSubCategories;
        order.promoCode = res.data[0]?.promoCode;
        order.extras = res.data[0]?.extras;
        order.source = res.data[0]?.source;
        order.orderNote = res.data[0]?.orderNote;
        successCallback();
        return { order };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async ({ order, id, successCallback, failureCallback }) => {
    return axios
      .put(`${OrdersSliceUrls.getOrderByIdUrl}/${id}`, {
        ...order,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const addOrderV2 = createAsyncThunk(
  "orders/addOrderV2",
  async ({ order, id, successCallback, failureCallback }) => {
    return axios
      .post(`${OrdersSliceUrls.addOrderV2}/${id}`, {
        ...order,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);

export const addOrderFeedBack = createAsyncThunk(
  "orders/addOrderFeedBack",
  async ({ feedback, successCallback, failureCallback }) => {
    return axios
      .post(OrdersSliceUrls.orderFeedBack, {
        ...feedback,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const updateOrderFeedBack = createAsyncThunk(
  "orders/updateOrderFeedBack",
  async ({ feedback, id, successCallback, failureCallback }) => {
    return axios
      .put(`${OrdersSliceUrls.orderFeedBack}/${id}`, {
        ...feedback,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const exportOrdersToCsvFile = createAsyncThunk(
  "orders/exportOrdersToCsvFile",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(`${OrdersSliceUrls.ordersCsvUrl}`, { responseType: "blob" })
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const uploadWarrantyPhoto = createAsyncThunk(
  "orders/uploadWarrantyPhoto",
  async ({ body, successCallback, failureCallback }) => {
    return axios
      .post(`${OrdersSliceUrls.orderWarrantyPhoto}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => successCallback?.(res.data))
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const deleteWarrantyPhoto = createAsyncThunk(
  "orders/uploadWarrantyPhoto",
  async ({ url, successCallback, failureCallback }) => {
    return axios
      .delete(`${OrdersSliceUrls.orderWarrantyPhoto}`, { data: { url: url } })
      .then((res) => successCallback?.(res.data))
      .catch((err) => {
        console.log(err);
        failureCallback?.(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);
export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .delete(`${OrdersSliceUrls.getOrderByIdUrl}/${id}`)
      .then((res) => {
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR) {
          message = i18next.t("toast_messages.something_went_wrong");
        } else {
          message = err.response.data.message;
        }
        failureCallback(message);
      });
  }
);
export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    order: null,
    todayOrders: [],
    customerOrders: [],
  },
  reducers: {
    resetOrder: (state) => {
      state.order = null;
    },
  },
  extraReducers: {
    [fetchOrders.fulfilled]: (state, action) => {
      state.count = action.payload?.count;
      state.pages = action.payload?.pages;
      state.orders =
        action.payload?.page === 1
          ? action.payload?.orders
          : [...state.orders, ...action.payload?.orders];
    },
    [fetchCustomerOrders.fulfilled]: (state, action) => {
      state.order = null;
      state.customerOrders = action.payload?.customerOrders;
    },
    [fetchOrder.fulfilled]: (state, action) => {
      state.order = action.payload?.order;
    },
    [fetchTodayOrders.fulfilled]: (state, action) => {
      state.todayOrders = action.payload?.todayOrders;
    },
  },
});

export const { resetOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
