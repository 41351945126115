import "./Customer.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CarTab from "./components/CarTab/CarTab";
import { TabView, TabPanel } from "primereact/tabview";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout/Layout";
import Loader from "../../../components/Loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import GeneralTab from "./components/GeneralTab/GeneralTab";
import { setCustomerTab } from "../../../redux/slices/TabsSlice";
import { fetchCustomer } from "../../../redux/slices/CustomersSlice";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import CustomerOrdersTab from "./components/CustomerOrdersTab/CustomerOrdersTab";
import CustomerMembershipTab from "./components/CustomerMembershipTab/CustomerMembershipTab";

const Customer = () => {
  const { features } = useSelector((state) => state.user);
  const { customerTab } = useSelector((state) => state.tabs);
  const { customer } = useSelector((state) => state.customers);

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  let params = useParams();
  let dispatch = useDispatch();
  let { t } = useTranslation();
  let location = useLocation();

  const ordersTab = location?.state?.ordersTab;

  const failureFetchCustomerCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    if (params.id) {
      dispatch(
        fetchCustomer({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: (message) => failureFetchCustomerCallback(message),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordersTab !== undefined) {
      setSelectedTab(3);
    } else {
      setSelectedTab(customerTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTab = (index) => {
    setSelectedTab(index);
    dispatch(setCustomerTab({ customerTab: index }));
  };

  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return <GeneralTab />;
      case 1:
        return <CarTab />;
      case 2:
        return <CustomerMembershipTab />;
      case 3:
        return <CustomerOrdersTab />;
      default:
        return <GeneralTab />;
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Layout className="customer-tab-container">
      <TabView
        className="customers-tabs"
        activeIndex={selectedTab}
        onTabChange={(tab) => handleTab(tab.index)}
      >
        {features.customers.tabs.general.view && (
          <TabPanel header={t("customers_page.customer.general_tab.title")} />
        )}
        {features.customers.tabs.vehicle.view && (
          <TabPanel header={t("customers_page.customer.vehicle_tab.title")} />
        )}
        {features.customers.tabs.membership.view && (
          <TabPanel
            header={t("customers_page.customer.membership_tab.title")}
          />
        )}
        {features.customers.tabs.orders && (
          <TabPanel
            className="orders-tab"
            header={t("customers_page.customer.orders_tab.title")}
          />
        )}
      </TabView>
      {customer?.fullName && (
        <p className="customer-name">{customer.fullName}</p>
      )}
      {renderTabs()}
    </Layout>
  );
};

export default Customer;
