// import { useSelector } from "react-redux";
import "./CarTab.css";
import { useCallback, useState } from "react";
import CarCard from "./CarCard/CarCard";
import CarForm from "./CarForm/CarForm";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../components/Layout/Layout";
import { ROWS_IN_TABLE } from "../../../../../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeReactButton } from "primereact/button";
import { resetOrder } from "../../../../../redux/slices/OrdersSlice";
import { capitalizeFirstChar } from "../../../../../utils/Utils";
import { carTableFilter } from "../../../../OrdersList/constants";
import { InputText } from "primereact/inputtext";
import {
  deleteCar,
  fetchCustomer,
} from "../../../../../redux/slices/CustomersSlice";
import { setToastMessage } from "../../../../../redux/slices/ToastMessageSlice";
import Loader from "../../../../../components/Loader/Loader";
import { confirmDialog } from "primereact/confirmdialog";

const CarTab = () => {
  let dispatch = useDispatch();
  const { features } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [filters, setFilters] = useState(carTableFilter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { car, cars, customer } = useSelector((state) => state.customers);
  const [selectedCar, setSelectedCar] = useState(null);

  const handleClick = (selectedCar) => {
    if (selectedCar) {
      setSelectedCar(selectedCar);
    }
    setIsNew(false);
    setOpen(!open);
  };
  const handleAddingNewCar = () => {
    setIsNew(true);
    setOpen(!open);
  };
  let { t } = useTranslation();

  const onRowSelect = (event) => {
    console.log(event.data);
    handleClick(event.data);
  };
  const onRowDelete = useCallback((event) => {
    confirmDialog({
      header: t("customers_page.customer.vehicle_tab.delete_car_pop_up.title"),
      message: t("customers_page.customer.vehicle_tab.delete_car_pop_up.body"),
      accept: () => {
        setLoading(true);
        dispatch(
          deleteCar({
            id: event.data._id,
            userId: customer._id,
            successCallback: onRowDeleteSuccess,
            failureCallback: onRowDeleteFailure,
          })
        );
      },
      reject: () => {},
    });
  }, []);
  const failureFetchCustomerCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };
  const onRowDeleteSuccess = useCallback(() => {
    dispatch(
      fetchCustomer({
        id: customer._id,
        successCallback: () => setLoading(false),
        failureCallback: (message) => failureFetchCustomerCallback(message),
      })
    );
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("customers_page.customer.vehicle_tab.car_delete.on_success"),
      })
    );
  }, []);
  const onRowDeleteFailure = useCallback((error) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: error,
      })
    );
  }, []);
  const actionBodyTemplate = (e) => {
    return (
      <div className="customers-list-icons-container">
        <PrimeReactButton
          type="button"
          icon="pi pi-trash"
          onClick={() => onRowDelete({ data: e })}
        />
        <PrimeReactButton
          type="button"
          icon="pi pi-pencil"
          onClick={() => onRowSelect({ data: e })}
        />
      </div>
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return loading ? (
    <Loader />
  ) : (
    <Layout>
      <div className="car-tab-container">
        <div className="flex justify-between table-header">
          {!open && (
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder={t("Search by model, brand, plate, etc..")}
              style={{ height: "50px", width: "250px", padding: "10px" }}
            />
          )}

          {features.customers.tabs.vehicle.edit && !open && (
            <Button
              onClick={() => handleAddingNewCar()}
              name={"buttons.add_car_button"}
            />
          )}
        </div>

        {open ? (
          <CarForm
            handleClick={handleClick}
            isNew={isNew}
            selectedCar={selectedCar}
          />
        ) : (
          <DataTable
            rowHover
            paginator
            rows={ROWS_IN_TABLE}
            dataKey="_id"
            filterDisplay="menu"
            selectionMode="single"
            className="cars-table"
            responsiveLayout="scroll"
            onRowSelect={(e) => onRowSelect(e)}
            emptyMessage={t("customers_page.customer.vehicle_tab.car.no_car")}
            value={cars}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            filters={filters}
            onFilter={(e) => setFilters(e.filters)}
          >
            <Column
              sortable
              field="brand"
              showFilterMatchModes={false}
              header={t("customers_page.customer.vehicle_tab.car.brand")}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              sortable
              field="model"
              showFilterMatchModes={false}
              header={t("customers_page.customer.vehicle_tab.car.model")}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              sortable
              field="productionYear"
              showFilterMatchModes={false}
              header={t(
                "customers_page.customer.vehicle_tab.car.production_year"
              )}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              sortable
              field="licensePlate"
              showFilterMatchModes={false}
              header={t(
                "customers_page.customer.vehicle_tab.car.license_plate_number"
              )}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              sortable
              field="fuelType"
              body={(e) => capitalizeFirstChar(e.fuelType)}
              showFilterMatchModes={false}
              header={t("customers_page.customer.vehicle_tab.car.fuel_type")}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              sortable
              field="color"
              body={(e) => capitalizeFirstChar(e.color)}
              showFilterMatchModes={false}
              header={t("customers_page.customer.vehicle_tab.car.color")}
              showFilterMenuOptions={false}
              className="cars-table-main-column"
            />
            <Column
              body={actionBodyTemplate}
              headerClassName="orders-list-row-action"
            />
          </DataTable>
        )}
      </div>
    </Layout>
  );
};

export default CarTab;
