import _ from "lodash";
import "./ServiceForm.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputNumber } from "primereact/inputnumber";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../../../assets/svg/Close.svg";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import Layout from "../../../../components/Layout/Layout";
import Button from "../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../redux/slices/ToastMessageSlice";
import {
  fetchService,
  updateService,
} from "../../../../redux/slices/ServicesSlice";
import { setServicesTab } from "../../../../redux/slices/TabsSlice";
import { SERVICES_TABS } from "../../../../constants";

const ServiceForm = () => {
  const { features } = useSelector((state) => state.user);
  const { service } = useSelector((state) => state.services);

  let params = useParams();
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [serviceInfo, setServiceInfo] = useState([]);

  const failureFetchServiceCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    if (params.id) {
      dispatch(
        fetchService({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: (message) => failureFetchServiceCallback(message),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (service) {
      setServiceInfo(service);
    }
  }, [service]);

  const successUpdateServiceCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.service.service_updated"),
      })
    );
    dispatch(setServicesTab({ servicesTab: SERVICES_TABS.SERVICES }));
    navigate("/dashboard/services");
  };

  const failureUpdateServiceCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    setSubmitted(true);
    for (let i = 0; i < serviceInfo.prices.length; i++) {
      const option = serviceInfo.prices[i];
      if (
        typeof option.maxDistance != "number" ||
        typeof option.minDistance != "number" ||
        typeof option.oneTripPrice != "number" ||
        typeof option.twoTripPrice != "number"
      ) {
        return;
      }
    }
    if (
      serviceInfo.oneTripNightAdditionalPrice <= 0 ||
      serviceInfo.twoTripNightAdditionalPrice <= 0
    ) {
      return;
    }
    const service = {
      oneTripNightAdditionalPrice: serviceInfo.oneTripNightAdditionalPrice,
      twoTripNightAdditionalPrice: serviceInfo.twoTripNightAdditionalPrice,
      prices: serviceInfo.prices,
    };
    dispatch(
      updateService({
        service,
        id: params.id,
        successCallback: () => successUpdateServiceCallback(),
        failureCallback: (message) => failureUpdateServiceCallback(message),
      })
    );
  };

  function updatePrices(prices, index, propertyName, newValue) {
    if (index < 0 || index >= prices.length) {
      return updatePrices;
    }
    let updatedPrices = _.cloneDeep(prices);
    updatedPrices[index][propertyName] = newValue;
    if (propertyName === "minDistance") {
      if (
        // eslint-disable-next-line
        index + 1 != prices.length &&
        updatedPrices[index].maxDistance <= newValue
      ) {
        updatedPrices[index].maxDistance = newValue + 1;
      }
      if (index - 1 >= 0 && newValue !== updatedPrices[index - 1].maxDistance) {
        updatedPrices = updatePrices(
          updatedPrices,
          index - 1,
          "maxDistance",
          newValue
        );
      }
    } else {
      if (updatedPrices[index].minDistance >= newValue) {
        updatedPrices[index].minDistance = newValue - 1;
      }
      if (
        index + 1 < prices.length &&
        newValue !== updatedPrices[index + 1].minDistance
      ) {
        updatedPrices = updatePrices(
          updatedPrices,
          index + 1,
          "minDistance",
          newValue
        );
      }
    }
    return updatedPrices;
  }

  const handleChange = (e, index, name) => {
    const serviceDistancesPrices = _.cloneDeep(serviceInfo.prices);
    if (index !== undefined) {
      if (name) {
        const newPrices = updatePrices(
          serviceDistancesPrices,
          index,
          name,
          e.value
        );
        setServiceInfo((prevState) => ({
          ...prevState,
          prices: newPrices,
        }));
      } else {
        serviceDistancesPrices[index][e?.originalEvent?.target?.name] = e.value;
        setServiceInfo((prevState) => ({
          ...prevState,
          prices: serviceDistancesPrices,
        }));
      }
    } else {
      setServiceInfo({
        ...serviceInfo,
        [e.originalEvent.target.name]: e.value,
      });
    }
  };

  const handleClose = () => {
    dispatch(setServicesTab({ servicesTab: SERVICES_TABS.SERVICES }));
    navigate("/dashboard/services");
  };

  return loading ? (
    <Loader />
  ) : (
    <Layout>
      <div className="service-form">
        <div className="close-button-form-title-container">
          <div className="form-title">
            {t("services_page.services.service_form.title")}
          </div>
          <img
            src={CloseSvg}
            alt="close car form"
            onClick={handleClose}
            id="close-card-form-button"
          />
        </div>
        <input
          type="text"
          value={
            serviceInfo?.key
              ? t("services_page.services." + serviceInfo.key)
              : ""
          }
          disabled={true}
          className="form-input"
          placeholder={t("common.service_name")}
        />
        <div className="labels-container">
          <div>{t("services_page.services.service_form.distance")}</div>
          <div>{t("services_page.services.service_form.one_trip_price")}</div>
          <div>{t("services_page.services.service_form.two_trip_price")}</div>
        </div>
        {serviceInfo.prices?.length > 0 &&
          serviceInfo.prices?.map((item, index) => {
            return (
              <div className="row" key={index}>
                <div className="column">
                  <div className="buttons-container">
                    <InputNumber
                      name="minDistance"
                      inputId="minmax-buttons"
                      onChange={(e) => handleChange(e, index, "minDistance")}
                      showButtons
                      min={0}
                      format={false}
                      value={item.minDistance}
                      className="form-input integer-only"
                      maxFractionDigits={2}
                    />
                    {serviceInfo.prices.length - 1 !== index && (
                      <span className="form-input dash pi pi-minus" />
                    )}
                    {serviceInfo.prices.length - 1 !== index && (
                      <InputNumber
                        name="maxDistance"
                        inputId="minmax-buttons"
                        onChange={(e) => handleChange(e, index, "maxDistance")}
                        showButtons
                        min={0}
                        format={false}
                        value={item.maxDistance}
                        className="form-input integer-only"
                        maxFractionDigits={2}
                      />
                    )}
                    <span className="form-input dash"> KM</span>
                  </div>
                  <small
                    className={`p-error block ${
                      submitted &&
                      (typeof item.maxDistance !== "number" ||
                        typeof item.minDistance !== "number")
                        ? ""
                        : "hidden"
                    }`}
                  >
                    {t("common.required_field")}
                  </small>
                </div>
                <div className="column">
                  <div>
                    <InputNumber
                      min={0}
                      name="oneTripPrice"
                      currency="NIS"
                      mode="currency"
                      value={item.oneTripPrice}
                      className="form-input integer-only"
                      placeholder={t(
                        "services_page.services.service_form.one_trip_price"
                      )}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <small
                      className={`p-error block ${
                        submitted && !(item.oneTripPrice > 0) ? "" : "hidden"
                      }`}
                    >
                      {t("common.required_field")}
                    </small>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <InputNumber
                      min={0}
                      name="twoTripPrice"
                      currency="NIS"
                      mode="currency"
                      value={item.twoTripPrice}
                      className="form-input integer-only"
                      placeholder={t(
                        "services_page.services.service_form.two_trip_price"
                      )}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <small
                      className={`p-error block ${
                        submitted && !(item.twoTripPrice > 0) ? "" : "hidden"
                      }`}
                    >
                      {t("common.required_field")}
                    </small>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="labels-container">
          <div>
            {t(
              "services_page.services.service_form.one_night_additional_trip_price"
            )}
          </div>
          <div>
            {t(
              "services_page.services.service_form.two_night_additional_trip_price"
            )}
          </div>
        </div>
        <div className="column additional-night-prices-container">
          <div className="row">
            <div className="row-full-width">
              <InputNumber
                min={0}
                name="oneTripNightAdditionalPrice"
                currency="NIS"
                mode="currency"
                value={serviceInfo.oneTripNightAdditionalPrice}
                className="form-input integer-only"
                placeholder={t(
                  "services_page.services.service_form.one_night_additional_trip_price"
                )}
                onChange={(e) => handleChange(e)}
              />
              <small
                className={`p-error block ${
                  submitted && !(serviceInfo.oneTripNightAdditionalPrice > 0)
                    ? ""
                    : "hidden"
                }`}
              >
                {t("common.required_field")}
              </small>
            </div>
            <div className="row-full-width">
              <InputNumber
                min={0}
                name="twoTripNightAdditionalPrice"
                currency="NIS"
                mode="currency"
                value={serviceInfo.twoTripNightAdditionalPrice}
                className="form-input integer-only"
                placeholder={t(
                  "services_page.services.service_form.two_night_additional_trip_price"
                )}
                onChange={(e) => handleChange(e)}
              />
              <small
                className={`p-error block ${
                  submitted && !(serviceInfo.twoTripNightAdditionalPrice > 0)
                    ? ""
                    : "hidden"
                }`}
              >
                {t("common.required_field")}
              </small>
            </div>
          </div>
        </div>
        <Button
          name="buttons.save_service_button"
          onClick={(e) => handleClick(e)}
          className={
            !features.services.services.edit
              ? "services-list-features-not-allowed"
              : ""
          }
        />
      </div>
    </Layout>
  );
};

export default ServiceForm;
