import "./CustomersList.css";
import "primeflex/primeflex.css";
import Avatar from "react-avatar";
import "primeicons/primeicons.css";
import { Column } from "primereact/column";
import { tableFilters } from "./constants";
import { useEffect, useState } from "react";
import "primereact/resources/primereact.css";
import { useTranslation } from "react-i18next";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Loader from "../../components/Loader/Loader";
import Layout from "../../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import StarFilled from "../../assets/svg/StarFilled.svg";
import AddCustomer from "./Customer/AddCustomer/AddCustomer";
import { Button as PrimeReactButton } from "primereact/button";
import Button from "../../components/CustomButton/CustomButton";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import ErrorLayout from "../../components/ErrorLayout/ErrorLayout";
import { setToastMessage } from "../../redux/slices/ToastMessageSlice";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {
  setCustomer,
  fetchCustomers,
  deleteCustomer,
  exportCustomersToCsvFile,
} from "../../redux/slices/CustomersSlice";
import { paidOptions } from "../Services/RequestsList/constants";
import {
  CUSTOMERS_TABLE,
  PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE,
  ROWS_IN_TABLE,
} from "../../constants";
import { resetTabs } from "../../redux/slices/TabsSlice";
import { MdOutlineMobileOff, MdOutlineMobileFriendly } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";

const CustomersList = () => {
  const { features } = useSelector((state) => state.user);
  const { customers, cities } = useSelector((state) => state.customers);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [filters, setFilters] = useState(tableFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { t } = useTranslation();
  let returned = sessionStorage.getItem(
    PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE
  );
  useEffect(() => {
    if (!returned) {
      if (customers?.length) {
        setLoading(false);
      }
      setFilters(tableFilters);
      dispatch(
        fetchCustomers({
          successCallback: () => setLoading(false),
          failureCallback: () => setErrorDataLoading(true),
        })
      );
    } else {
      setLoading(false);
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowSelect = (event) => {
    dispatch(resetTabs());
    navigate(`/dashboard/customers/${event.data._id}`);
  };

  const closeForm = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    dispatch(setCustomer({ customer: null }));
    setOpen(!open);
    // sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
  };

  const successDeleteCustomerCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("customers_page.customer_deleted"),
      })
    );
    dispatch(
      fetchCustomers({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
  };

  const failureDeleteCustomerCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const deleteConfirmPopUp = (id) => {
    confirmDialog({
      header: t("customers_page.delete_customer_pop_up.title"),
      message: t("customers_page.delete_customer_pop_up.body"),
      accept: () =>
        dispatch(
          deleteCustomer({
            id,
            successCallback: () => successDeleteCustomerCallback(),
            failureCallback: (message) =>
              failureDeleteCustomerCallback(message),
          })
        ),
      reject: () => console.log("reject"),
    });
  };

  const actionBodyTemplate = (e) => {
    return (
      <div className="customers-list-icons-container">
        <PrimeReactButton
          type="button"
          icon="pi pi-pencil"
          onClick={() => onRowSelect({ data: e })}
        />
        {features.customers.deleteCustomers && (
          <PrimeReactButton
            type="button"
            icon="pi pi-trash"
            onClick={() => deleteConfirmPopUp(e._id)}
          />
        )}
      </div>
    );
  };

  const fullNameBodyTemplate = (rowData) => {
    const hasImage = rowData?.photo?.url;
    return (
      <div>
        {hasImage ? (
          <div className="image-name-container">
            <img src={rowData?.photo?.url} alt="" className="customers-img" />
            {rowData.fullName}
          </div>
        ) : (
          <div className="image-name-container">
            <Avatar
              name={`${rowData.firstName} ${rowData.lastName}`}
              size="40"
              round={true}
            />
            {rowData.fullName}
          </div>
        )}{" "}
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        mask="99/99/9999"
        dateFormat="mm/dd/yy"
        value={options.value}
        placeholder="mm/dd/yyyy"
        onChange={(e) => options.filterCallback(e.value.toLocaleDateString())}
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        options={cities}
        value={options.value}
        className="p-column-filter"
        placeholder={t("customers_page.select_city")}
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  const starBodyTemplate = (rowData) => {
    if (rowData?.isMember) {
      return <img src={StarFilled} alt="customer-has-membership" />;
    }
  };

  const failureExportCsvFile = (message) => {
    setCsvLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleCSV = () => {
    setCsvLoading(true);
    dispatch(
      exportCustomersToCsvFile({
        successCallback: () => setCsvLoading(false),
        failureCallback: (message) => failureExportCsvFile(message),
      })
    );
  };

  const handleCellSelection = (e) => {
    if (e.column.props.className === "customers-table-main-column") {
      dispatch(resetTabs());
      sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE, true);
      navigate(`/dashboard/customers/${e.rowData._id}`);
    }
  };

  useEffect(() => {
    if (returned) {
      const customerTableSession = JSON.parse(
        sessionStorage.getItem(CUSTOMERS_TABLE)
      );
      if (customerTableSession) {
        setGlobalFilterValue(customerTableSession.filters?.global?.value);
      } else {
        setGlobalFilterValue(filters?.global?.value);
      }
    } else {
      setGlobalFilterValue(filters?.global?.value);
    }
    // eslint-disable-next-line
  }, [open]);

  const isMembershipFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={paidOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder={t("common.select_status")}
        className="p-column-filter"
        showClear
      />
    );
  };
  const isAppUserFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={paidOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder={t("common.select_status")}
        className="p-column-filter"
        showClear
      />
    );
  };
  return errorDataLoading ? (
    <ErrorLayout />
  ) : (
    <Layout
      className={`customers-table-container ${
        features.customers.deleteCustomers ? "customers-table-container1" : ""
      }`}
    >
      {csvLoading && (
        <OverlayLoader showTitle={true} title={t("common.wait_moment")} />
      )}
      <div className={loading ? "table-header-hidden" : ""}>
        {!open && (
          <div className="table-header">
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder={t("searches.keyword_search")}
            />
            <div className="csv-dropdown-container">
              {features.customers.add && (
                <Button
                  name="buttons.add_customer_button"
                  onClick={() => handleOpen()}
                />
              )}
              {features.config.csv && (
                <Dropdown
                  onChange={() => handleCSV()}
                  dropdownIcon="pi pi-ellipsis-v"
                  options={[t("common.export_to_csv")]}
                  className="customers-table-export-csv-dropdown"
                />
              )}
            </div>
          </div>
        )}
      </div>
      {open ? (
        <AddCustomer closeForm={closeForm} />
      ) : loading ? (
        <Loader />
      ) : (
        <>
          <ScrollRestoration
            getKey={(location, matches) => {
              return location.pathname;
            }}
          />
          <DataTable
            rowHover
            rows={ROWS_IN_TABLE}
            paginator
            scrollable
            dataKey="_id"
            sortOrder={-1}
            cellSelection
            value={customers}
            filters={filters}
            id="customers-table"
            sortField="createdAt"
            selectionMode="single"
            responsiveLayout="scroll"
            onCellSelect={handleCellSelection}
            emptyMessage={t("customers_page.no_customers_found")}
            globalFilterFields={["fullName", "city", "phoneNumber"]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            onFilter={(e) => setFilters(e.filters)}
            stateStorage="session"
            stateKey={CUSTOMERS_TABLE}
          >
            <Column
              sortable
              field="fullName"
              showAddButton={false}
              header={t("common.name")}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              body={fullNameBodyTemplate}
              className="customers-table-main-column"
            />
            <Column
              filter
              sortable
              field="city"
              header={t("common.city")}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filterElement={statusFilterTemplate}
              className="customers-table-main-column"
            />
            <Column
              sortable
              field="phoneNumber"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              header={t("common.phone_number")}
              className="customers-table-main-column"
            />
            <Column
              filter
              sortable
              dataType="date"
              field="createdAt"
              filterField="createdAt"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              body={(row) => {
                return new Date(row.createdAt).toLocaleDateString("en-US");
              }}
              header={t("common.created_at")}
              filterElement={dateFilterTemplate}
              className="customers-table-main-column"
            />
            <Column
              sortable
              filter
              field="isMember"
              showAddButton={false}
              body={starBodyTemplate}
              header={"Membership"}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filterElement={isMembershipFilterTemplate}
              className="customers-table-main-column"
            />
            <Column
              sortable
              filter
              field="isMobileUser"
              showAddButton={false}
              body={(row) => {
                return row.isMobileUser ? <FaMobileAlt size={23} /> : <></>;
              }}
              header={"App User"}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filterElement={isAppUserFilterTemplate}
              className="customers-table-main-column app-column"
            />
            <Column
              body={actionBodyTemplate}
              className="customers-list-action-column"
            />
          </DataTable>
        </>
      )}
    </Layout>
  );
};

export default CustomersList;
