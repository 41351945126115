import { createSlice } from "@reduxjs/toolkit";

export const tabsSlice = createSlice({
  name: "tabs",
  initialState: {
    customerTab: 0,
    servicesTab: 0,
    administratorTab: 0,
  },
  reducers: {
    setCustomerTab: (state, action) => {
      state.customerTab = action.payload.customerTab;
    },
    setServicesTab: (state, action) => {
      state.servicesTab = action.payload.servicesTab;
    },
    resetTabs: (state) => {
      state.customerTab = 0;
      state.servicesTab = 0;
      state.administratorTab = 0;
    },
    setAdministratorTab: (state, action) => {
      state.administratorTab = action.payload.administratorTab;
    },
  },
});

export const {
  setCustomerTab,
  setServicesTab,
  resetTabs,
  setAdministratorTab,
} = tabsSlice.actions;

export default tabsSlice.reducer;
