import "./App.css";
import { auth, notificationSubscriber, requestFCMToken } from "./firebase";
import { useEffect } from "react";
import Router from "./routes/Router";
import { languages } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import ToastifyMessage from "./utils/ToastifyMessage";
import { ConfirmDialog } from "primereact/confirmdialog";
import { refreshUserFeature } from "./redux/slices/UserSlice";
import {
  LOCAL_STORAGE_LOADED,
  LOCAL_STORAGE_TOKEN,
} from "./redux/slices/constants";
import { fetchCities } from "./redux/slices/CustomersSlice";
import { fetchPartners } from "./redux/slices/PartnersSlice";
import { fetchServices } from "./redux/slices/ServicesSlice";
function App() {
  const {
    type,
    show,
    detail,
    sticky,
    content,
    closable,
    position,
    contentShow,
  } = useSelector((state) => state.toastMessages);
  const { loggedUser } = useSelector((state) => state.user);
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLogged) {
      dispatch(refreshUserFeature({ role: loggedUser?.role }));
      dispatch(fetchCities());
      dispatch(
        fetchPartners({
          successCallback: () => {},
          failureCallback: () => {},
        })
      );
      dispatch(
        fetchServices({
          successCallback: () => {},
          failureCallback: () => {},
        })
      );
    }
    // eslint-disable-next-line
  }, [isLogged, loggedUser?.role]);
  useEffect(() => {
    if (isLogged) {
      if (Notification.permission === "granted") {
        let loaded = sessionStorage.getItem(LOCAL_STORAGE_LOADED);
        if (!loaded) {
          requestFCMToken()
            .then(notificationSubscriber)
            .catch((error) => console.log(error));
          sessionStorage.setItem(LOCAL_STORAGE_LOADED, true);
        }
      }
    }
  }, [isLogged]);
  const { language } = useSelector((state) => state.user);
  // auth.onAuthStateChanged((user) => {
  //   if (user) {
  //     user.getIdToken().then((IdToken) => {
  //       localStorage.setItem(LOCAL_STORAGE_TOKEN, IdToken);
  //     });
  //   }
  // });

  return (
    <div
      className={`App ${
        language === languages.ENGLISH ? "" : "arabic-language"
      }`}
    >
      <ToastifyMessage
        show={show}
        type={type}
        detail={detail}
        sticky={sticky}
        content={content}
        position={position}
        closable={closable}
        contentShow={contentShow}
      />
      <ConfirmDialog />
      <Router />
    </div>
  );
}

export default App;
