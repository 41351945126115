import "./PartnersForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";
import { types, company } from "./constants";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-number-input";
import CloseSvg from "../../../assets/svg/Close.svg";
import Loader from "../../../components/Loader/Loader";
import Layout from "../../../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorCodes } from "../../../redux/slices/constants";
import Button from "../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import {
  validateEmail,
  validateForm,
  validatePhoneNumber,
} from "../../../utils/Validator";
import {
  addPartner,
  fetchPartner,
  updatePartner,
} from "../../../redux/slices/PartnersSlice";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { PREVENT_LOADING_WHEN_RETURNED } from "../../../constants";

const PartnersForm = (props) => {
  const { features } = useSelector((state) => state.user);
  const { partner } = useSelector((state) => state.partners);
  const { cities } = useSelector((state) => state.customers);
  const { services } = useSelector((state) => state.services);

  const getFormatedServices = () => {
    const partnerServicesKeys = partner?.services ?? [];
    return services?.map((service) => ({
      key: service.key,
      checked: partnerServicesKeys.includes(service.key),
    }));
  };

  const [formatedServices, setFormatedServices] = useState(
    getFormatedServices()
  );

  const { newPartner, handleOpen, convertOpen } = props;

  let params = useParams();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { t } = useTranslation();

  const initialPartnerInfo = {
    type: "",
    name: "",
    city: "",
    email: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    partnerNote: "",
  };
  const [loading, setLoading] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(initialPartnerInfo);
  const [firstTimeSubmitted, setFirstTimeSubmitted] = useState(false);
  const [duplicatePhoneNumber, setDuplicatePhoneNumber] = useState(false);

  const handleChangeCheckbox = (e) => {
    setFormatedServices(
      formatedServices.map((service) =>
        service.key === e.value
          ? {
              ...service,
              checked: e.checked,
            }
          : service
      )
    );
  };

  const failureFetchPartnerCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    if (params.id) {
      dispatch(
        fetchPartner({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: (message) => failureFetchPartnerCallback(message),
        })
      );
    } else {
      setLoading(false);
    }
    if (!features.partners.edit) setFieldsDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (partner) {
      const partnerObj = initialPartnerInfo;
      const keys = Object.keys(partnerObj);
      keys.forEach((key) => {
        if (partner[key] === undefined) {
          partnerObj[key] = "";
        } else {
          partnerObj[key] = partner[key];
        }
      });
      setPartnerInfo(partnerObj);
      validatePartnerEmail(partner.email);
      validatePartnerPhoneNumber(partner?.phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  useEffect(() => {
    setFormatedServices(getFormatedServices);
    // eslint-disable-next-line
  }, [partner, services]);

  const replaceCountryCode = () => {
    if (partnerInfo.phoneNumber.startsWith("+970")) {
      let phoneNumber = partnerInfo.phoneNumber.replace("+970", "+972");
      const partner = { ...partnerInfo };
      partner.phoneNumber = phoneNumber;
      return partner;
    } else {
      return partnerInfo;
    }
  };

  const successUpdatePartnerCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.partner.partner_updated"),
      })
    );
    navigate("/dashboard/partners");
  };

  const failureAddUpdatePartnerCallback = (
    message,
    duplicatePhoneEmail,
    errorCode
  ) => {
    if (!duplicatePhoneEmail) {
      dispatch(
        setToastMessage({
          show: true,
          position: "bottom-center",
          type: "error",
          detail: message,
        })
      );
    } else {
      if (errorCode === errorCodes.DUPLICATE_EMAIL) setDuplicateEmail(true);
      else setDuplicatePhoneNumber(true);
      setDuplicateMessage(message);
    }
    setLoading(false);
  };

  const successAddPartnerCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.partner.partner_added"),
      })
    );
    handleOpen();
  };

  const validatePartnerEmail = (email) => {
    if (email?.length !== 0) {
      if (email === undefined) {
        setValidEmail(false);
      } else {
        setValidEmail(validateEmail(email));
      }
    }
  };

  const validatePartnerPhoneNumber = (phoneNumber) => {
    if (phoneNumber?.length !== 0) {
      if (phoneNumber === undefined) {
        setValidPhoneNumber(false);
      } else {
        setValidPhoneNumber(validatePhoneNumber(phoneNumber));
      }
    }
  };

  const handleBlur = () => {
    validatePartnerEmail(partnerInfo.email);
    validatePartnerPhoneNumber(partnerInfo.phoneNumber);
  };

  const handleChange = (e) => {
    if (typeof e !== "object") {
      setPartnerInfo({ ...partnerInfo, phoneNumber: e });
    } else {
      setPartnerInfo({ ...partnerInfo, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    if (newPartner) {
      sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
      convertOpen();
    } else {
      navigate("/dashboard/partners", {
        state: {
          returned: true,
        },
      });
    }
  };

  const handleSavePartner = () => {
    sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
    setFirstTimeSubmitted(true);
    let validated = false;
    let copiedPartner = { ...partnerInfo };
    if (partnerInfo.type === company.value) {
      delete partnerInfo.firstName;
      delete partnerInfo.lastName;
    } else {
      partnerInfo.name = partnerInfo.firstName + partnerInfo.lastName;
    }
    if (partnerInfo.email?.length === 0) {
      delete partnerInfo.email;
      setValidEmail(true);
    }
    const note = partnerInfo.partnerNote;
    delete partnerInfo.partnerNote;
    const partnerValues = Object.values(partnerInfo);
    validated = validateForm([...partnerValues]);
    if (
      !validEmail &&
      partnerInfo?.email !== undefined &&
      partnerInfo.email?.length !== 0
    ) {
      setPartnerInfo(copiedPartner);
      return;
    }
    if (copiedPartner?.email?.trim().length > 0) {
      partnerInfo.email = copiedPartner.email;
    }
    if (note) {
      partnerInfo.partnerNote = note;
    }
    if (validated && validPhoneNumber) {
      setLoading(true);
      if (!newPartner) {
        dispatch(
          updatePartner({
            partner: {
              ...partnerInfo,
              services:
                formatedServices
                  .filter((service) => service.checked)
                  .map((service) => service.key) ?? [],
            },
            id: partner._id,
            successCallback: () => successUpdatePartnerCallback(),
            failureCallback: (message, duplicatePhoneEmail, errorCode) =>
              failureAddUpdatePartnerCallback(
                message,
                duplicatePhoneEmail,
                errorCode
              ),
          })
        );
      } else {
        const partner = replaceCountryCode();
        dispatch(
          addPartner({
            partner: {
              ...partner,
              services:
                formatedServices
                  .filter((service) => service.checked)
                  .map((service) => service.key) ?? [],
            },
            successCallback: () => successAddPartnerCallback(),
            failureCallback: (message, duplicatePhoneEmail, errorCode) =>
              failureAddUpdatePartnerCallback(
                message,
                duplicatePhoneEmail,
                errorCode
              ),
          })
        );
      }
    } else {
      setPartnerInfo(copiedPartner);
    }
  };

  return (
    <Layout className="partners-form-container">
      {loading ? (
        <Loader />
      ) : (
        <div className="partners-form">
          <div className="title-close-button-container">
            <div className="form-title">{t("common.partner")}</div>
            <img
              src={CloseSvg}
              alt="close car form"
              onClick={() => handleClose()}
              id="close-card-form-button"
            />
          </div>
          {partnerInfo.type === "company" ? (
            <div className="column">
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    name="name"
                    className="form-input"
                    onChange={handleChange}
                    value={partnerInfo.name}
                    disabled={fieldsDisabled}
                  />
                  <label htmlFor="name">{t("common.name")}</label>
                </span>
              </div>
              {firstTimeSubmitted && partnerInfo.name?.length === 0 && (
                <small className="p-error block">
                  {t("common.required_field")}
                </small>
              )}
            </div>
          ) : (
            <div className="row">
              <div className="full-width">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <InputText
                      name="firstName"
                      className="form-input"
                      onChange={handleChange}
                      disabled={fieldsDisabled}
                      value={partnerInfo.firstName}
                    />
                    <label htmlFor="firstName">{t("common.first_name")}</label>
                  </span>
                </div>
                {firstTimeSubmitted && partnerInfo.firstName?.length === 0 && (
                  <small className="p-error block">
                    {t("common.required_field")}
                  </small>
                )}
              </div>
              <div className="full-width">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <InputText
                      name="lastName"
                      className="form-input"
                      onChange={handleChange}
                      disabled={fieldsDisabled}
                      value={partnerInfo.lastName}
                    />
                    <label htmlFor="lastName">{t("common.last_name")}</label>
                  </span>
                </div>
                {firstTimeSubmitted && partnerInfo.lastName?.length === 0 && (
                  <small className="p-error block">
                    {t("common.required_field")}
                  </small>
                )}
              </div>
            </div>
          )}
          <div>
            <PhoneInput
              required
              name="phoneNumber"
              defaultCountry="PS"
              onChange={handleChange}
              disabled={fieldsDisabled}
              onBlur={() => handleBlur()}
              value={partnerInfo.phoneNumber}
              placeholder={t("common.phone_number")}
              className="form-input phone-number-input"
            />
            {firstTimeSubmitted &&
              (partnerInfo.phoneNumber?.length === 0 ||
                !validPhoneNumber ||
                duplicatePhoneNumber) && (
                <small className="p-error block">
                  {partnerInfo.phoneNumber?.length === 0
                    ? t("common.required_field")
                    : !validPhoneNumber
                    ? t("common.enter_valid_phone_number")
                    : "* " + duplicateMessage}
                </small>
              )}
          </div>
          <div>
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <InputText
                  type="email"
                  name="email"
                  className="form-input"
                  onChange={handleChange}
                  disabled={fieldsDisabled}
                  value={partnerInfo.email}
                  onBlur={() => handleBlur()}
                />
                <label htmlFor="email">{t("common.email")}</label>
              </span>
            </div>
            {firstTimeSubmitted &&
              ((!validEmail &&
                partnerInfo?.email?.length !== 0 &&
                partnerInfo?.email !== undefined) ||
                duplicateEmail) && (
                <small className="p-error block">
                  {!validEmail && partnerInfo?.email?.length !== 0
                    ? t("common.enter_valid_email")
                    : "* " + duplicateMessage}
                </small>
              )}
          </div>
          <div>
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  name="city"
                  options={cities}
                  onChange={handleChange}
                  value={partnerInfo.city}
                  disabled={fieldsDisabled}
                />
                <label htmlFor="city">{t("common.city")}</label>
              </span>
            </div>

            {firstTimeSubmitted && partnerInfo.city?.length === 0 && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
          </div>
          <div>
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  name="type"
                  options={types}
                  onChange={handleChange}
                  value={partnerInfo.type}
                  disabled={fieldsDisabled}
                />
                <label htmlFor="type">{t("partners_page.partner_type")}</label>
              </span>
            </div>
            {firstTimeSubmitted && partnerInfo.type?.length === 0 && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
          </div>
          <div className="checkboxes-container">
            <div className="checkboxs-header-label">
              {t("common.services")}:
            </div>
            {formatedServices.map((service, index) => (
              <div key={service.key + index} className="checkbox-container">
                <Checkbox
                  inputId={`choice${index}`}
                  name={service.key}
                  className="checkbox"
                  value={service.key}
                  onChange={handleChangeCheckbox}
                  checked={service.checked}
                  disabled={fieldsDisabled}
                />
                <label htmlFor={`choice${index}`}>
                  {t(`services_page.services.${service.key}`)}
                </label>
              </div>
            ))}
          </div>
          <InputTextarea
            name="note"
            autoResize
            value={partnerInfo.partnerNote}
            className="note-input-text"
            onChange={(e) =>
              setPartnerInfo({ ...partnerInfo, partnerNote: e.target.value })
            }
            disabled={fieldsDisabled}
            placeholder={t("customers_page.customer.general_tab.add_note")}
          />
          <Button
            onClick={handleSavePartner}
            name="buttons.save_partner_button"
            className={
              !features.partners.edit ? "partners-feature-not-allowed" : ""
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default PartnersForm;
