import "./Loader.css";

const Loader = ({ todayOrders }) => {
  return (
    <div className={`loader-container ${todayOrders ? "today-orders" : ""}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
