import "./Administration";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { TabView, TabPanel } from "primereact/tabview";
import { useDispatch, useSelector } from "react-redux";
import { setAdministratorTab } from "../../redux/slices/TabsSlice";
import Administration from "./Administration";
import SmsPage from "./sms/SmsPage";
import { useSearchParams } from "react-router-dom";
const AdministrationPage = () => {
  const { administratorTab } = useSelector((state) => state.tabs);
  const { features } = useSelector((state) => state.user);

  let dispatch = useDispatch();
  let { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const lastUpdateTime = searchParams.get("lastUpdateTime");
  const handleTab = (index) => {
    dispatch(setAdministratorTab({ administratorTab: index }));
  };

  const renderTab = () => {
    switch (administratorTab) {
      case 0:
        return <Administration />;
      case 1:
        return <SmsPage />;
      default:
        return <Administration />;
    }
  };

  useEffect(() => {
    renderTab();
    // eslint-disable-next-line
  }, [administratorTab]);

  return (
    <Layout className="administration-tab-container">
      <TabView
        className="administration-tabs"
        activeIndex={administratorTab}
        onTabChange={(tab) => handleTab(tab.index)}
      >
        {features.administration.view && (
          <TabPanel header={t("administration_page.roles")} />
        )}
        {features.administration.view && (
          <TabPanel header={t("administration_page.sms")} />
        )}
      </TabView>
      {renderTab()}
      {lastUpdateTime && (
        <footer className="last-updated-time">
          {t("administration_page.last_updated_ui") +
            process.env.REACT_APP_LAST_DEPLOY_TIME.replace(
              "(Eastern European Summer Time)",
              ""
            )}
        </footer>
      )}
    </Layout>
  );
};

export default AdministrationPage;
