import "./Map.css";
import L from "leaflet";
import "leaflet-routing-machine";
import { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import images from "../../assets/images";
import TodayOrders from "./TodayOrders/TodayOrders";
import { useDispatch, useSelector } from "react-redux";
import { mapCenter, titleLayerUrl } from "./constants";
import { Marker, TileLayer, MapContainer } from "react-leaflet";
import { fetchTodayOrders } from "../../redux/slices/OrdersSlice";
import ErrorLayout from "../../components/ErrorLayout/ErrorLayout";

export const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: images.marker,
  shadowUrl: images.markerShadow,
});

const Map = () => {
  const { todayOrders } = useSelector((state) => state.orders);
  const [loading, setLoading] = useState(true);
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    if (todayOrders?.length) {
      setLoading(false);
    }
    dispatch(
      fetchTodayOrders({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoading = () => {
    setLoading(!loading);
    dispatch(
      fetchTodayOrders({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
  };

  let scrollToTopButton = document.getElementById("scroll-button");

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    if (
      (document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20) &&
      window.innerWidth <= 600
    ) {
      console.log("block");
      scrollToTopButton.style.display = "block";
    } else {
      console.log("none");
      scrollToTopButton.style.display = "none";
    }
  };

  return errorDataLoading ? (
    <ErrorLayout />
  ) : (
    <div className="map-container">
      <MapContainer zoom={10} center={mapCenter} scrollWheelZoom={true}>
        <TileLayer url={titleLayerUrl} />
        {!loading &&
          todayOrders?.length > 0 &&
          // eslint-disable-next-line array-callback-return
          todayOrders?.map((order, index) => {
            if (order.serviceLocation?.length !== 0) {
              return (
                <Marker
                  icon={icon}
                  key={index}
                  position={order.serviceLocation}
                />
              );
            }
          })}
      </MapContainer>
      <TodayOrders ordersLoading={loading} handleLoading={handleLoading} />
      <img
        src={images.backToTop}
        alt="back-to-top-icon"
        style={{ border: 2 }}
        className="back-to-top-icon"
        id="scroll-button"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      />
    </div>
  );
};

export default Map;
