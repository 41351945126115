import "./NewMembershipForm.css";
import { useEffect, useState } from "react";
import { paymentMethods } from "../constants";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../../../../../assets/svg/Close.svg";
import { validateForm } from "../../../../../../utils/Validator";
import Button from "../../../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../../../redux/slices/ToastMessageSlice";
import {
  addUserMembership,
  fetchMemberships,
} from "../../../../../../redux/slices/MembershipsSlice";
import { Calendar } from "primereact/calendar";
import { PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE } from "../../../../../../constants";
import { filter } from "lodash";

const NewMembershipForm = ({ handleOpen }) => {
  const { membershipsDropDown } = useSelector((state) => state.memberships);
  const { customer, carsDropDown, carsWithMembership } = useSelector(
    (state) => state.customers
  );
  const [carsWithNoMemberships, setCarsWithNoMemberships] = useState([]);
  const initialNewMembership = {
    car: "",
    membershipId: "",
    paymentMethod: "",
    startDate: null,
    endDate: null,
  };
  const [submitted, setSubmitted] = useState(false);
  const [newMembership, setNewMembership] = useState(initialNewMembership);

  let { t } = useTranslation();
  let dispatch = useDispatch();
  useEffect(() => {
    if (carsWithMembership.length) {
      const filteredCars = carsDropDown.filter((element) => {
        if (!carsWithMembership.includes(element.value)) return element;
      });
      setCarsWithNoMemberships(filteredCars);
    } else {
      setCarsWithNoMemberships(carsDropDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carsDropDown, carsWithMembership]);
  useEffect(() => {
    dispatch(
      fetchMemberships({ successCallback: () => {}, failureCallback: () => {} })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setNewMembership({ ...newMembership, [e.target.name]: e.target.value });
  };

  const successAddUserMembershipCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        type: "success",
        position: "bottom-center",
        detail: t("toast_messages.membership.membership_added"),
      })
    );
    handleOpen();
  };

  const failureAddUserMembershipCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        type: "error",
        position: "bottom-center",
        detail: message,
      })
    );
  };

  const handleSave = () => {
    setSubmitted(true);
    const clonedMembership = { ...newMembership };
    delete clonedMembership.startDate;
    delete clonedMembership.endDate;
    const newMembershipValues = Object.values(clonedMembership);
    const validated = validateForm(newMembershipValues);
    if (validated) {
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
      dispatch(
        addUserMembership({
          membership: newMembership,
          id: customer._id,
          successCallback: () => successAddUserMembershipCallback(),
          failureCallback: (message) =>
            failureAddUserMembershipCallback(message),
        })
      );
    }
  };

  return (
    <div className="customer-membership-form new-membership-form">
      <div className="close-button-container">
        <div className="form-title">
          {t("services_page.memberships.membership_form.title")}
        </div>
        <img
          src={CloseSvg}
          alt="close car form"
          onClick={() => handleOpen()}
          id="close-card-form-button"
        />
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              required
              name="car"
              options={carsWithNoMemberships}
              onChange={handleChange}
              value={newMembership.car}
              inputId="new-membership-car"
              filter
            />
            <label htmlFor="new-membership-car">{t("common.vehicle")}</label>
          </span>
        </div>
        {submitted && newMembership.car?.length === 0 && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              required
              name="paymentMethod"
              onChange={handleChange}
              options={paymentMethods}
              value={newMembership.paymentMethod}
              inputId="new-membership-payment-method"
            />
            <label htmlFor="new-membership-payment-method">
              {t("common.payment_method.title")}
            </label>
          </span>
        </div>
        {submitted && newMembership.paymentMethod?.length === 0 && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              required
              name="membershipId"
              onChange={handleChange}
              options={membershipsDropDown}
              value={newMembership.membershipId}
              inputId="new-membership-memberships"
            />
            <label htmlFor="new-membership-memberships">
              {t("common.memberships")}
            </label>
          </span>
        </div>
        {submitted && newMembership.membershipId?.length === 0 && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Calendar
              name="startDate"
              onChange={handleChange}
              inputId="new-membership-start-date"
              className="custom-membership-calender"
              value={newMembership.startDate}
            />
            <label htmlFor="new-membership-start-date">
              {t("memberships_list.custom_membership.start_date")}
            </label>
          </span>
        </div>
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Calendar
              name="endDate"
              onChange={handleChange}
              inputId="new-membership-end-date"
              className="custom-membership-calender"
              value={newMembership.endDate}
            />
            <label htmlFor="new-membership-end-date">
              {t("memberships_list.custom_membership.end_date")}
            </label>
          </span>
        </div>
      </div>
      <Button name="buttons.apply_membership_button" onClick={handleSave} />
    </div>
  );
};

export default NewMembershipForm;
