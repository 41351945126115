export const FuelTypes = {
  PETROL: "petrol",
  DIESEL: "diesel",
  ELECTRIC: "electric",
  HYBRID: "hybrid",
  PLUG_IN_HYBRID: "plug-in-hybrid",
};

export const carColors = [
  { label: "Red", value: "red" },
  { label: "Yellow", value: "yellow" },
  { label: "Blue", value: "blue" },
  { label: "Navy", value: "navy" },
  { label: "Green", value: "green" },
  { label: "Orange", value: "orange" },
  { label: "Black", value: "black" },
  { label: "Silver", value: "silver" },
  { label: "Gray", value: "gray" },
  { label: "Purple", value: "purple" },
  { label: "Brown", value: "brown" },
  { label: "Gold", value: "gold" },
  { label: "Beige", value: "beige" },
  { label: "White", value: "white" },
];

export const registrationImageDropdown = [
  { label: "Upload new photo", value: "upload_new_photo" },
  { label: "View Photo", value: "view_photo" },
];
