import axios from "../../api/api";
import i18n from "../../i18n/i18n";
import { NETWORK_ERROR } from "./constants";
import { ServicesSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(ServicesSliceUrls.servicesUrl)
      .then((res) => {
        const servicesNameId = {};
        const servicesDropDown = [];
        const servicesNames = res.data.map((service) => {
          const name = i18n.t("services_page.services." + service.key);
          servicesNameId[name] = service._id;
          return name;
        });
        const servicesIdKeys = {};
        let subcategories = [];
        res.data.forEach((service) => {
          const serviceObj = {};
          serviceObj["label"] = i18n.t("services_page.services." + service.key);
          serviceObj["value"] = service._id;
          servicesDropDown.push(serviceObj);
          const key = service.key;
          const id = service._id;
          servicesIdKeys[id] = key;

          const subServices = service?.subcategory?.map((subService) => {
            const subServiceObj = {};
            subServiceObj.label = i18n.t(
              `services_page.services.sub_categories.${subService.key}`
            );
            subServiceObj.value = subService.key;
            return subServiceObj;
          });
          subcategories.push({
            mainService: service.key,
            subServices,
          });
        });
        if (successCallback !== undefined) successCallback();
        return {
          services: res.data,
          servicesNames,
          servicesIdKeys,
          servicesNameId,
          servicesDropDown,
          subcategories,
        };
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback !== undefined) failureCallback();
        return {
          services: [],
          servicesNames: [],
          servicesIdKeys: [],
          servicesNameId: [],
          servicesDropDown: [],
          subcategories: [],
        };
      });
  }
);

export const fetchService = createAsyncThunk(
  "services/fetchService",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`${ServicesSliceUrls.serviceUrl}/${id}`)
      .then((res) => {
        successCallback();
        const prices = res.data.prices.sort(
          (service1, service2) => service1.minDistance - service2.minDistance
        );
        return { service: { ...res.data, prices } };
      })
      .catch((err) => {
        failureCallback(i18n.t("toast_messages.something_went_wrong"));
        console.log(err);
      });
  }
);

export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ id, service, successCallback, failureCallback }) => {
    return axios
      .put(`${ServicesSliceUrls.serviceUrl}/${id}`, {
        ...service,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18n.t("toast_messages.something_went_wrong");
        else message = err.response.data.message;
        failureCallback(message);
      });
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    service: null,
    servicesNames: [],
    subcategories: [],
    servicesNameId: {},
    servicesIdKeys: {},
    servicesIdTypes: [],
    servicesDropDown: [],
  },
  reducers: {
    setService: (state, action) => {
      state.service = action.payload.service;
    },
  },
  extraReducers: {
    [fetchServices.fulfilled]: (state, action) => {
      state.services = action.payload?.services;
      state.servicesNames = action.payload?.servicesNames;
      state.subcategories = action.payload?.subcategories;
      state.servicesIdKeys = action.payload?.servicesIdKeys;
      state.servicesNameId = action.payload?.servicesNameId;
      state.servicesIdTypes = action.payload?.servicesIdTypes;
      state.servicesDropDown = action.payload?.servicesDropDown;
    },
    [fetchService.fulfilled]: (state, action) => {
      state.service = action.payload?.service;
    },
  },
});

export const { setService } = servicesSlice.actions;

export default servicesSlice.reducer;
