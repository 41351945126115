import "./PromotionCodeForm.css";
import { prefixLength } from "./constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { promotionCodeType } from "../constants";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { SelectButton } from "primereact/selectbutton";
import CloseSvg from "../../../../assets/svg/Close.svg";
import { validateForm } from "../../../../utils/Validator";
import Button from "../../../../components/CustomButton/CustomButton";
import { fetchCustomers } from "../../../../redux/slices/CustomersSlice";
import { setToastMessage } from "../../../../redux/slices/ToastMessageSlice";
import {
  addPromotionCode,
  fetchPromotionCodes,
} from "../../../../redux/slices/PromotionCodesSlice";

const PromotionCodeForm = ({ setOpen, setLoading, setErrorDataLoading }) => {
  const { customers } = useSelector((state) => state.customers);

  const initialPromotionCode = {
    user: "",
    expiry: "",
    prefix: "TK",
    discount: "",
    type: "service",
    description: "",
  };
  const [users, setUsers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [promotionCode, setPromotionCode] = useState(initialPromotionCode);

  let { t } = useTranslation();
  let dispatch = useDispatch();

  useEffect(() => {
    if (customers && selectedUser.length !== 0) {
      const arr = customers.map((customer) => {
        return {
          label: customer.fullName,
          value: customer._id,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
        };
      });
      setUsers(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const successAddPromoCodeCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.promotion_code.promotion_code_added"),
      })
    );
    dispatch(
      fetchPromotionCodes({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(false),
      })
    );
    setOpen(false);
  };

  const failureAddPromoCodeCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!promotionCode.prefix?.length) return;

    if (selectedUser === "") {
      delete promotionCode.user;
    }

    let validated = validateForm([
      promotionCode.discount,
      promotionCode.expiry,
      promotionCode.type,
    ]);
    if (promotionCode.discount === 0) {
      validated = false;
    }
    if (validated) {
      dispatch(
        addPromotionCode({
          promotionCode,
          successCallback: () => successAddPromoCodeCallback(),
          failureCallback: (message) => failureAddPromoCodeCallback(message),
        })
      );
    }
  };

  const handleChange = (e) => {
    setPromotionCode({ ...promotionCode, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    setPromotionCode({ ...promotionCode, user: e.value.value });
    setSelectedUser(e.value.label);
  };

  const searchUser = (event) => {
    setTimeout(() => {
      const params = new URLSearchParams([["query", event.query]]);
      dispatch(fetchCustomers(params));
    }, 250);
  };

  const itemTemplate = (item) => {
    return (
      <div className="user-item">
        <strong>{item.label}</strong>
        <small>{item.email}</small>
        <small>{item.phoneNumber}</small>
      </div>
    );
  };

  return (
    <>
      <div className="promotion-codes-form">
        <div className="title-close-button-container">
          <div className="form-title">
            {t("services_page.promotion_codes.promotion_code_form.title")}
          </div>
          <img
            src={CloseSvg}
            alt="close car form"
            onClick={() => setOpen(false)}
            id="close-card-form-button"
          />
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="prefix"
                className="form-input"
                onChange={handleChange}
                value={promotionCode.prefix}
              />
              <label htmlFor="prefix">
                {t("services_page.promotion_codes.promotion_code_form.code")}
              </label>
            </span>
          </div>
          {submitted && !promotionCode.prefix?.length && (
            <small className="p-error block">
              {t(
                "services_page.promotion_codes.promotion_code_form.prefix_max_characters"
              )}
            </small>
          )}
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="description"
                className="form-input"
                onChange={handleChange}
                value={promotionCode.description}
              />
              <label htmlFor="description">
                {t(
                  "services_page.promotion_codes.promotion_code_form.description"
                )}
              </label>
            </span>
          </div>
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputNumber
                min={0}
                maxFractionDigits={2}
                name="discount"
                inputId="integeronly"
                onValueChange={handleChange}
                value={promotionCode.discount}
                className="form-input integer-only"
              />
              <label htmlFor="discount">
                {t("services_page.promotion_codes.discount")}
              </label>
            </span>
          </div>
          {submitted && !(promotionCode.discount > 0) && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Calendar
                name="expiry"
                onChange={handleChange}
                value={promotionCode.expiry}
                className="promotion-code-calendar"
              />
              <label htmlFor="expiry">
                {t(
                  "services_page.promotion_codes.promotion_code_form.expiry_date"
                )}
              </label>
            </span>
          </div>
          {submitted && !(promotionCode.expiry instanceof Date) && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div className="users-filter-container">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <AutoComplete
                field="name"
                minLength={3}
                suggestions={users}
                value={selectedUser}
                aria-label="Customers"
                onSelect={handleSelect}
                completeMethod={searchUser}
                itemTemplate={itemTemplate}
                className="promotion-codes-users-filter"
                onChange={(e) => setSelectedUser(e.value)}
              />
              <label>
                {t("services_page.promotion_codes.promotion_code_form.user")}
              </label>
            </span>
          </div>
        </div>
        <div className="row paid">
          <div className="promotion-code-type">
            {t("services_page.promotion_codes.promotion_code_type")}
          </div>
          <SelectButton
            value={promotionCode.type}
            options={promotionCodeType}
            onChange={(e) =>
              setPromotionCode({ ...promotionCode, type: e.value })
            }
          />
        </div>
        <Button
          onClick={(e) => handleAdd(e)}
          name="buttons.add_promotion_code_button"
        />
      </div>
    </>
  );
};

export default PromotionCodeForm;
