import { createSlice } from "@reduxjs/toolkit";

export const ToastMessageSlice = createSlice({
  name: "toast-message",
  initialState: {
    type: "",
    detail: "",
    show: false,
    sticky: false,
    position: "",
    content: null,
    closable: true,
    contentShow: false,
  },
  reducers: {
    setToastMessage: (state, action) => {
      state.type = action.payload.type;
      state.show = action.payload.show;
      state.detail = action.payload.detail;
      state.sticky = action.payload.sticky;
      state.content = action.payload.content;
      state.position = action.payload.position;
      state.closable = action.payload.closable;
      state.contentShow = action.payload.contentShow;
    },
    resetToastMessage: (state) => {
      state.type = "";
      state.detail = "";
      state.show = false;
      state.position = "";
      state.content = null;
      state.sticky = false;
      state.closable = true;
      state.contentShow = false;
    },
  },
});

export const { setToastMessage, resetToastMessage } = ToastMessageSlice.actions;

export default ToastMessageSlice.reducer;
