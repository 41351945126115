export const userFeatures = {
  initialFeatures: {
    map: {
      view: false,
      edit: false,
    },
    customers: {
      add: false,
      edit: false,
      view: false,
      deleteCustomers: false,
      tabs: {
        general: {
          view: false,
          edit: false,
          editNote: false,
        },
        vehicle: {
          view: false,
          edit: false,
        },
        membership: {
          view: false,
          add: false,
          activate: false,
          edit: false,
          markAsPaid: false,
          delete: false,
        },
        orders: false,
      },
    },
    partners: {
      add: false,
      edit: false,
      view: false,
    },
    orders: {
      order: {
        add: false,
        edit: false,
        view: false,
        delete: false,
      },
      feedback: {
        add: false,
        edit: false,
        view: false,
      },
    },
    services: {
      servicesTab: false,
      requests: {
        add: false,
        edit: false,
        view: false,
      },
      memberships: {
        add: false,
        edit: false,
        view: false,
      },
      services: {
        add: false,
        edit: false,
        view: false,
      },
      promotionCodes: {
        add: false,
        edit: false,
        view: false,
      },
      companies: {
        add: false,
        edit: false,
        view: false,
      },
    },
    administration: {
      add: false,
      edit: false,
      view: false,
    },
    config: {
      initialRoute: "/sign-in",
      csv: false,
    },
  },
  dispatcherFeatures: {
    map: {
      view: true,
      edit: true,
    },
    customers: {
      add: true,
      edit: true,
      view: true,
      deleteCustomers: false,
      tabs: {
        general: {
          view: true,
          edit: false,
          editNote: true,
        },
        vehicle: {
          view: true,
          edit: false,
        },
        membership: {
          view: true,
          add: false,
          activate: false,
          edit: false,
          markAsPaid: false,
          delete: false,
        },
        orders: true,
      },
    },
    partners: {
      add: false,
      edit: false,
      view: true,
    },
    orders: {
      order: {
        add: true,
        edit: true,
        view: true,
        delete: false,
      },
      feedback: {
        add: true,
        edit: true,
        view: true,
      },
    },
    services: {
      servicesTab: false,
      requests: {
        add: false,
        edit: false,
        view: false,
      },
      memberships: {
        add: false,
        edit: false,
        view: false,
      },
      services: {
        add: false,
        edit: false,
        view: false,
      },
      promotionCodes: {
        add: false,
        edit: false,
        view: false,
      },
      companies: {
        add: false,
        edit: false,
        view: false,
      },
    },
    administration: {
      add: false,
      edit: false,
      view: false,
    },
    config: {
      initialRoute: "/dashboard/map",
      csv: false,
    },
  },
  crmFeatures: {
    map: {
      view: true,
      edit: true,
    },
    customers: {
      add: true,
      edit: true,
      view: true,
      deleteCustomers: false,
      tabs: {
        general: {
          view: true,
          edit: true,
          editNote: true,
        },
        vehicle: {
          view: true,
          edit: true,
        },
        membership: {
          view: true,
          add: true,
          activate: true,
          edit: true,
          markAsPaid: true,
          delete: false,
        },
        orders: true,
      },
    },
    partners: {
      add: false,
      edit: false,
      view: true,
    },
    orders: {
      order: {
        add: true,
        edit: true,
        view: true,
        delete: false,
      },
      feedback: {
        add: true,
        edit: true,
        view: true,
      },
    },
    services: {
      servicesTab: true,
      requests: {
        add: false,
        edit: false,
        view: true,
      },
      memberships: {
        add: false,
        edit: false,
        view: false,
      },
      services: {
        add: false,
        edit: false,
        view: false,
      },
      promotionCodes: {
        add: false,
        edit: false,
        view: false,
      },
      companies: {
        add: false,
        edit: false,
        view: false,
      },
    },
    administration: {
      add: false,
      edit: false,
      view: false,
    },
    config: {
      initialRoute: "/dashboard/customers",
      csv: false,
    },
  },
  adminFeatures: {
    map: {
      view: true,
      edit: true,
    },
    customers: {
      add: true,
      edit: true,
      view: true,
      deleteCustomers: false,
      tabs: {
        general: {
          view: true,
          edit: true,
          editNote: true,
        },
        vehicle: {
          view: true,
          edit: true,
        },
        membership: {
          view: true,
          add: false,
          activate: false,
          edit: true,
          markAsPaid: false,
          delete: false,
        },
        orders: true,
      },
    },
    partners: {
      add: true,
      edit: true,
      view: true,
    },
    orders: {
      order: {
        add: true,
        edit: true,
        view: true,
        delete: false,
      },
      feedback: {
        add: true,
        edit: true,
        view: true,
      },
    },
    services: {
      servicesTab: true,
      requests: {
        add: false,
        edit: false,
        view: false,
      },
      memberships: {
        add: false,
        edit: false,
        view: false,
      },
      services: {
        add: false,
        edit: false,
        view: true,
      },
      promotionCodes: {
        add: false,
        edit: false,
        view: false,
      },
      companies: {
        add: true,
        edit: true,
        view: true,
      },
    },
    administration: {
      add: false,
      edit: false,
      view: false,
    },
    config: {
      initialRoute: "/dashboard/map",
      csv: false,
    },
  },
  superAdminFeatures: {
    map: {
      view: true,
      edit: true,
    },
    customers: {
      add: true,
      edit: true,
      view: true,
      deleteCustomers: true,
      tabs: {
        general: {
          view: true,
          edit: true,
          editNote: true,
        },
        vehicle: {
          view: true,
          edit: true,
        },
        membership: {
          view: true,
          add: true,
          activate: true,
          edit: true,
          markAsPaid: true,
          delete: true,
        },
        orders: true,
      },
    },
    partners: {
      add: true,
      edit: true,
      view: true,
    },
    orders: {
      order: {
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      feedback: {
        add: true,
        edit: true,
        view: true,
      },
    },
    services: {
      servicesTab: true,
      requests: {
        add: true,
        edit: true,
        view: true,
      },
      memberships: {
        add: true,
        edit: true,
        view: true,
      },
      services: {
        add: true,
        edit: true,
        view: true,
      },
      promotionCodes: {
        add: true,
        edit: true,
        view: true,
      },
      companies: {
        add: true,
        edit: true,
        view: true,
      },
    },
    administration: {
      add: true,
      edit: true,
      view: true,
    },
    config: {
      initialRoute: "/dashboard/map",
      csv: true,
    },
  },
};

export const NETWORK_ERROR = "ERR_NETWORK";
export const LOCAL_STORAGE_FCM_TOKEN = "FCMToken";
export const LOCAL_STORAGE_LOADED = "loaded";
export const LOCAL_STORAGE_TOKEN = "token";

export const errorCodes = {
  DUPLICATE_EMAIL: 1000,
  DUPLICATE_PHONE_NUMBER: 1001,
  VERIFIED_EMAIL_CHANGE: 1002,
};
