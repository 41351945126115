import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/api";
import { companiesSliceUrls } from "./SlicesUrls";

export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async ({ successCallback }) => {
    return axios
      .get(companiesSliceUrls.companiesUrl)
      .then((res) => {
        successCallback?.();
        return { companies: res.data };
      })
      .catch((err) => console.log(err));
  }
);
export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async ({ name, successCallback, failureCallback }) => {
    return axios
      .delete(`${companiesSliceUrls.companiesUrl}/${name}`)
      .then((res) => {
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        failureCallback(err.response?.data?.message ?? "there is an error");
      });
  }
);
export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
  },
  reducers: {},
  extraReducers: {
    [fetchCompanies.fulfilled]: (state, action) => {
      state.companies = action.payload.companies;
    },
  },
});

export default companiesSlice.reducer;
