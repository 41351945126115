import "./OrderForm.css";
import { icon } from "../../Map/Map";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import CloseSvg from "../../../assets/svg/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { ToggleButton } from "primereact/togglebutton";
import Loader from "../../../components/Loader/Loader";
import { AutoComplete } from "primereact/autocomplete";
import FeedBackForm from "./FeedBackForm/FeedBackForm";
import { validateForm } from "../../../utils/Validator";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { mapCenter, titleLayerUrl } from "../../Map/constants";
import Button from "../../../components/CustomButton/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import { TileLayer, MapContainer, Marker, useMapEvents } from "react-leaflet";
import { paymentMethods } from "../../CustomersList/Customer/components/CustomerMembershipTab/constants";
import {
  fetchCustomer,
  fetchCustomers,
  setCarsDropDown,
} from "../../../redux/slices/CustomersSlice";
import {
  addOrderV2,
  updateOrder,
  fetchOrder,
  uploadWarrantyPhoto,
  deleteWarrantyPhoto,
} from "../../../redux/slices/OrdersSlice";
import {
  statues,
  googleMapUrl,
  TOWING_SERVICE,
  ORDER_STATUSES,
  ORDER_SOURCE_WEB,
  TIRE_REPAIR_SERVICE,
} from "../constants";
import { FileUpload } from "primereact/fileupload";
import { confirmDialog } from "primereact/confirmdialog";
import { AiOutlineMobile } from "react-icons/ai";
import { PREVENT_LOADING_WHEN_RETURNED } from "../../../constants";
import { InputTextarea } from "primereact/inputtextarea";
const OrderForm = ({ open, handleAdd, userOrders, closeTapHandler }) => {
  const { features } = useSelector((state) => state.user);
  const { partnersDropDown } = useSelector((state) => state.partners);
  const { order, feedBackUpdated } = useSelector((state) => state.orders);
  const { carsDropDown, cities } = useSelector((state) => state.customers);
  const { customer, customers, car } = useSelector((state) => state.customers);
  const { servicesDropDown, servicesIdKeys, subcategories, services } =
    useSelector((state) => state.services);
  let params = useParams();
  let dispatch = useDispatch();
  let { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();

  const deletedUser = location?.state?.deletedUser;
  const provider = new OpenStreetMapProvider();

  const initialOrderInfo = {
    _id: "",
    car: "",
    city: "",
    price: 0,
    servicePrice: 0,
    partsPrice: 0,
    paid: false,
    service: "",
    provider: "",
    customerId: "",
    locationNote: "",
    paymentMethod: "",
    serviceLocation: [],
    destinationNote: "",
    createdDate: new Date(),
    destinationLocation: [],
    cancellationMessage: "",
    serviceSubCategories: "",
    status: ORDER_STATUSES.OPEN,
    orderNote: "",
  };
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [locations, setLocations] = useState([]);
  const [source, setSource] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [subcategory, setSubcategory] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [wheelsOptions, setWheelsOptions] = useState([]);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [currentLocation, setCurrentLocation] = useState(true);
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [selectedNumberOfWheels, setSelectedNumberOfWheels] = useState(1);
  const [subCategoryExtras, setSubCategoryExtras] = useState([]);
  const [isOptionalCancellationMessage, setIsOptionalCancellationMessage] =
    useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    customerFullName: location?.state?.customerFullName ?? null,
    customerId: location?.state?.customerId ?? null,
  });
  const failureFetchOrderCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  useEffect(() => {
    const path = location.pathname;
    if (params.id) {
      if (path.includes("orders")) {
        dispatch(
          fetchOrder({
            id: params.id,
            successCallback: (order) => setLoading(false),
            failureCallback: (message) => failureFetchOrderCallback(message),
            setCustomerInfo: (id, name) => {
              setCustomerInfo({ customerFullName: name, customerId: id });
            },
          })
        );
      } else {
        delete orderInfo._id;
        delete orderInfo.customerId;
        delete orderInfo.feedback;
      }
    } else {
      delete orderInfo._id;
      delete orderInfo.customerId;
      delete orderInfo.feedback;
      setLoading(false);
    }
    const arr = statues.map((status) => {
      return {
        label: t("orders_list.order_statuses." + status),
        value: status,
      };
    });
    const wheels = [...Array(4)].map((wheel, index) => {
      ++index;
      return {
        label: index,
        value: index,
      };
    });
    setWheelsOptions(wheels);

    if (!features.orders.order.edit) setFieldsDisabled(true);
    if (deletedUser) {
      setFieldsDisabled(true);
    }
    setOrderStatuses(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order) {
      const orderObj = initialOrderInfo;
      const keys = Object.keys(orderObj);
      keys.forEach((key) => {
        if (order[key] === undefined) {
          orderObj[key] = "";
        } else {
          orderObj[key] = order[key];
        }
      });
      orderObj.createdDate = new Date(orderObj.createdDate);

      if (deletedUser) {
        dispatch(setCarsDropDown({ carsDropDown: ["Deleted Car"] }));
        orderObj.name = "Deleted User";
        orderObj.car = "Deleted Car";
      } else {
        if (order.car === "Deleted Car") {
          dispatch(setCarsDropDown({ carsDropDown: ["Deleted Car"] }));
          orderObj.car = "Deleted Car";
        } else {
          dispatch(fetchCustomer({ id: order.customerId }));
        }
      }
      setOrderInfo(orderObj);

      if (
        order.status === ORDER_STATUSES.COMPLETED ||
        order.status === ORDER_STATUSES.CANCELED
      ) {
        setStatusDisabled(true);
      }
      if (order?.numberOfWheels)
        setSelectedNumberOfWheels(order.numberOfWheels);
      const serviceKey = servicesIdKeys[order.service];
      subcategories.forEach((subCat) => {
        if (subCat.mainService === serviceKey) {
          setSubcategory(subCat.subServices);
        }
      });
      setSource(order.source);
    }
    if (open) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, open]);

  useEffect(() => {
    if (customers && selectedUser.length !== 0) {
      const arr = customers.map((customer) => {
        return {
          label: customer.fullName,
          value: customer._id,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
        };
      });
      setUsers(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const handleChange = (e) => {
    console.log(e);
    if (!e?.target?.name) {
      setOrderInfo({ ...orderInfo, [e.originalEvent.target.name]: e.value });
    } else {
      if (e.target.name === "service")
        setOrderInfo({
          ...orderInfo,
          [e.target.name]: e.target.value,
          // eslint-disable-next-line
          ["serviceSubCategories"]: "",
        });
      else setOrderInfo({ ...orderInfo, [e.target.name]: e.target.value });
    }
    if (e.target?.name === "service") {
      const serviceKey = servicesIdKeys[e.target.value];
      subcategories.forEach((subCat) => {
        if (subCat.mainService === serviceKey) {
          setSubcategory(subCat.subServices);
        }
      });
    }
  };

  useEffect(() => {
    if (orderInfo?.service?.length > 0 || order?.service?.length > 0) {
      const subCategory = services
        ?.find(
          (service) =>
            service._id ===
            (orderInfo?.service?.length > 0 ? orderInfo.service : order.service)
        )
        ?.subcategory?.find(
          (subCategory) => subCategory.key === orderInfo.serviceSubCategories
        );
      const fields = subCategory?.fields?.map((field) => ({
        ...field,
        value: order?.extras?.[field.key],
      }));
      setSubCategoryExtras(fields ?? []);
    }
    // eslint-disable-next-line
  }, [orderInfo.service, orderInfo.serviceSubCategories]);

  const successAddOrderCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.order.order_added"),
      })
    );
    handleAdd(userOrders);
  };

  const failureAddOrderCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
    setLoading(false);
  };

  const successUpdateOrderCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.order.order_updated"),
      })
    );
    handleClose(true);
  };

  const failureUpdateOrderCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
    setLoading(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(true);

    if (fieldsDisabled) {
      dispatch(
        updateOrder({
          order: { paid: orderInfo.paid, order: orderInfo._id },
          id: orderInfo.customerId,
          successCallback: () => successUpdateOrderCallback(),
          failureCallback: (message) => failureUpdateOrderCallback(message),
        })
      );
      return;
    }

    let copiedOrder = { ...orderInfo };

    if (orderInfo.status !== "canceled" || isOptionalCancellationMessage) {
      delete orderInfo.cancellationMessage;
    }

    if (typeof orderInfo.price !== "number") delete orderInfo.price;
    if (orderInfo.paymentMethod?.length === 0) delete orderInfo.paymentMethod;
    if (
      orderInfo.locationNote?.length === 0 &&
      orderInfo.serviceLocation?.length !== 0
    )
      delete orderInfo.locationNote;
    if (orderInfo.serviceLocation?.length === 0)
      delete orderInfo.serviceLocation;
    if (servicesIdKeys[orderInfo.service] !== TOWING_SERVICE) {
      delete orderInfo.destinationLocation;
      delete orderInfo.destinationNote;
    } else {
      if (
        orderInfo.destinationNote?.length === 0 &&
        orderInfo.destinationLocation?.length !== 0
      )
        delete orderInfo.destinationNote;
      if (orderInfo.destinationLocation?.length === 0)
        delete orderInfo.destinationLocation;
    }
    if (
      (orderInfo.status === ORDER_STATUSES.OPEN ||
        orderInfo.status === ORDER_STATUSES.CANCELED) &&
      orderInfo.provider.length === 0
    ) {
      delete orderInfo.provider;
    }
    if (servicesIdKeys[orderInfo.service] === TIRE_REPAIR_SERVICE) {
      orderInfo.numberOfWheels = selectedNumberOfWheels;
    }
    if (orderInfo.city?.length === 0) delete orderInfo.city;
    if (!orderInfo.serviceSubCategories?.length > 0)
      delete orderInfo.serviceSubCategories;

    if (!orderInfo.orderNote?.length > 0) orderInfo.orderNote = " ";
    const orderValues = Object.values(orderInfo);
    const validated = validateForm(orderValues);
    if (validated) {
      if (!orderInfo.orderNote?.trim().length > 0) orderInfo.orderNote = "";
      const orderObj = { ...orderInfo };
      if (orderInfo.serviceLocation?.length !== 0)
        orderObj.locationNote = copiedOrder.locationNote;
      if (orderInfo.destinationLocation?.length !== 0)
        orderObj.destinationNote = copiedOrder.destinationNote;
      orderObj.source = ORDER_SOURCE_WEB;
      orderObj.extras = {};
      subCategoryExtras.forEach(
        (extra) => (orderObj.extras[extra.key] = extra.value)
      );
      orderObj.cancellationMessage = copiedOrder.cancellationMessage;
      orderObj.price = orderObj.price ? orderObj.price : 0;
      if (
        orderObj.status === ORDER_STATUSES.COMPLETED &&
        orderObj.price === 0
      ) {
        orderObj.paid = true;
      }
      if (!open) {
        orderObj.order = orderObj._id;
        delete orderObj._id;
        const id = orderObj.customerId;
        if (orderObj.car === "Deleted Car") delete orderObj.car;
        dispatch(
          updateOrder({
            order: orderObj,
            id,
            successCallback: () => successUpdateOrderCallback(),
            failureCallback: (message) => failureUpdateOrderCallback(message),
          })
        );
        return;
      } else {
        let id = "";
        if (!params.id) {
          id = user;
        } else {
          id = params.id;
        }
        dispatch(
          addOrderV2({
            order: orderObj,
            id,
            successCallback: () => successAddOrderCallback(),
            failureCallback: (message) => failureAddOrderCallback(message),
          })
        );
        return;
      }
    } else {
      setOrderInfo(copiedOrder);
      setLoading(false);
    }
  };
  //  no change == false
  const handleClose = (updated) => {
    if (location.state?.mapTab) {
      navigate("/dashboard/map");
      return;
    }
    if (userOrders || location.state?.userOrders) {
      if (!location.state?.userOrders) {
        closeTapHandler();
      } else {
        if (updated === true) {
          sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
        } else {
          sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
        }
        navigate(`/dashboard/customers/${customer._id}`);
      }
    } else {
      if (!params.id) {
        closeTapHandler();
      } else {
        if (!feedBackUpdated) {
          if (updated === true) {
            sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
          } else {
            sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
          }
          navigate("/dashboard/orders");
        }
      }
    }
  };

  const searchUser = (event) => {
    setTimeout(() => {
      const params = new URLSearchParams([["query", event.query]]);
      dispatch(fetchCustomers({ params }));
    }, 250);
  };

  const itemTemplate = (item) => {
    return (
      <div className="user-item">
        <strong>{item.label}</strong>
        <small>{item.email}</small>
        <small>{item.phoneNumber}</small>
      </div>
    );
  };

  const handleSelect = (e) => {
    setSelectedUser(e.value.label);
    setUser(e.value.value);
    dispatch(fetchCustomer({ id: e.value.value }));
  };

  const handleSelectedUser = (userId) => {
    setSelectedUser(userId);
    if (!userId) {
      setUser(null);
      setOrderInfo({ ...orderInfo, car: "" });
    }
  };

  const handleLocation = () => {
    setVisible(!visible);
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        if (currentLocation) {
          setOrderInfo({
            ...orderInfo,
            serviceLocation: [e.latlng["lat"], e.latlng["lng"]],
          });
        } else {
          setOrderInfo({
            ...orderInfo,
            destinationLocation: [e.latlng["lat"], e.latlng["lng"]],
          });
        }
      },
    });
  };

  const handleLocationSelect = async (event) => {
    setOrderInfo({ ...orderInfo, serviceLocation: event.value.bounds[0] });
    setSearchedLocation(event.value.label);
  };

  const searchLocation = (event) => {
    setTimeout(async () => {
      const results = await provider.search({ query: event.query });
      setLocations(results);
    }, 250);
  };

  const handleHide = () => {
    if (order?.serviceLocation.length === 0 || open) {
      setOrderInfo({ ...orderInfo, serviceLocation: [] });
    } else if (order.serviceLocation.length === 2) {
      setOrderInfo({ ...orderInfo, serviceLocation: order.serviceLocation });
    }
    setVisible(false);
  };

  const handleCopyToClipBoard = () => {
    if (!deletedUser) {
      let clipboardInfo = "";
      let user = `العميل: ${order.user.fullName} / ${order.user.phoneNumber}\n`;
      clipboardInfo += user;

      let carInfo = `المركبة: ${car.brand} / ${car.model} / ${car.color}\n`;
      clipboardInfo += carInfo;

      let serviceType = `الخدمة: ${t(
        "services_ar." + servicesIdKeys[order.service]
      )}\n`;

      clipboardInfo += serviceType;

      let subServiceType = `الخدمة الفرعية: ${t(
        "sub_services." + orderInfo?.serviceSubCategories
      )}\n`;

      if (orderInfo.serviceSubCategories?.length > 0)
        clipboardInfo += subServiceType;

      let googleMapLink = "";
      if (orderInfo.serviceLocation?.length > 0) {
        googleMapLink = `الموقع الحالي: ${googleMapUrl}${orderInfo.serviceLocation[0]},${orderInfo.serviceLocation[1]}\n`;
      }
      if (orderInfo.serviceLocation?.length > 0) clipboardInfo += googleMapLink;

      if (orderInfo.locationNote?.length > 0)
        clipboardInfo += `${orderInfo.locationNote}\n`;

      googleMapLink = "";
      if (orderInfo.destinationLocation?.length > 0) {
        googleMapLink = `موقع التسليم: ${googleMapUrl}${orderInfo.destinationLocation[0]},${orderInfo.destinationLocation[1]}\n`;
      }
      if (orderInfo.destinationLocation?.length > 0)
        clipboardInfo += googleMapLink;

      if (orderInfo.destinationNote?.length > 0)
        clipboardInfo += `${orderInfo.destinationNote}\n`;

      navigator.clipboard.writeText(clipboardInfo);
      dispatch(
        setToastMessage({
          show: true,
          position: "bottom-center",
          detail: t("toast_messages.order.order_copied_to_clipboard"),
          closable: false,
        })
      );
    }
  };

  const handleUserClick = () => {
    if (!deletedUser) {
      navigate(`/dashboard/customers/${customerInfo.customerId}`, {
        state: {
          ordersTab: true,
        },
      });
    }
  };

  const handleUploadWarrantyPhoto = (e) => {
    setLoading(true);
    dispatch(
      uploadWarrantyPhoto({
        body: {
          file: e.files[0],
        },
        successCallback: (res) => {
          setLoading(false);
          setSubCategoryExtras(
            subCategoryExtras.map((ext) =>
              ext.key === "Warranty Photo"
                ? {
                    ...ext,
                    value: res,
                  }
                : ext
            )
          );
        },
        failureCallback: () => setLoading(false),
      })
    );
  };

  const handleDeleteWarrantyPhoto = (url) => {
    setLoading(true);
    dispatch(
      deleteWarrantyPhoto({
        url: url,
        successCallback: (res) => {
          setLoading(false);
          setSubCategoryExtras(
            subCategoryExtras.map((ext) =>
              ext.key === "Warranty Photo"
                ? {
                    ...ext,
                    value: null,
                  }
                : ext
            )
          );
        },
        failureCallback: () => setLoading(false),
      })
    );
  };

  const renderExtraField = (extra) => {
    let Component = null;
    let props = {
      disabled: fieldsDisabled,
      value: extra.value,
      name: `extras-${extra.key}`,
      className: "form-input integer-only",
      onChange: (e) =>
        setSubCategoryExtras(
          subCategoryExtras.map((ext) =>
            ext.key === extra.key
              ? {
                  ...ext,
                  value: e.value ?? e.target.value,
                }
              : ext
          )
        ),
    };
    switch (extra.type) {
      case "text":
      case "Number":
        Component = InputText;
        break;
      case "enum":
        props = {
          ...props,
          options: extra.options.map((item) => ({
            label: t(`common.${item}`),
            value: item,
          })),
        };
        Component = Dropdown;
        break;
      default:
        Component = InputText;
        break;
    }
    // eslint-disable-next-line
    if (extra.type == "url") {
      return extra.value ? (
        <div className="warranty-image-container">
          <img src={extra.value} alt="" className="warranty-image" />
          <div
            className="warranty-image-cover"
            onClick={() => window.open(extra.value, "_blank")}
          >
            <span
              className="pi pi-times delete-button"
              onClick={(e) => {
                e.stopPropagation();
                deleteConfirmPopUp(extra.value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="image-uploader-container">
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={4500000}
            name="warranty-file"
            className="registration-file"
            chooseLabel={`Upload ${extra.key}`}
            onSelect={handleUploadWarrantyPhoto}
          />
        </div>
      );
    } else {
      return (
        <div key={`extras-${extra.key}`} className="field col-12 md:col-4">
          <span className="p-float-label">
            <Component {...props} />
            <label htmlFor={`extras-${extra.key}`}>{extra.key}</label>
          </span>
        </div>
      );
    }
  };

  const deleteConfirmPopUp = (url) => {
    confirmDialog({
      header: t("orders_list.order_form.delete_warranty_pop_up.title"),
      message: t("orders_list.order_form.delete_warranty_pop_up.body"),
      accept: () => handleDeleteWarrantyPhoto(url),
      reject: () => console.log("reject"),
    });
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (order?.status == "canceled") {
      setIsOptionalCancellationMessage(true);
    } else {
      setIsOptionalCancellationMessage(false);
    }
  }, [order]);
  return loading ? (
    <Loader />
  ) : (
    <div
      className={`orders-form-container ${
        open ? "new-order" : "existed-order"
      } ${userOrders ? "new-user-order" : ""}`}
    >
      <Dialog
        position="center"
        draggable={false}
        resizable={false}
        visible={visible}
        onHide={() => handleHide()}
        className="order-form-dialog-map"
        header={t("orders_list.order_form.pick_location")}
      >
        <AutoComplete
          minLength={3}
          field="locationName"
          suggestions={locations}
          value={searchedLocation}
          itemTemplate={itemTemplate}
          completeMethod={searchLocation}
          onSelect={handleLocationSelect}
          className="location-search-autocomplete"
          onChange={(e) => setSearchedLocation(e.value)}
          placeholder={t("searches.search_for_location")}
        />
        <MapContainer
          zoom={9}
          center={
            orderInfo.serviceLocation?.length === 2
              ? orderInfo.serviceLocation
              : mapCenter
          }
          scrollWheelZoom={true}
          className="order-form-map"
        >
          <TileLayer url={titleLayerUrl} />
          <LocationMarker />
          {currentLocation ? (
            <Marker
              position={
                orderInfo.serviceLocation?.length === 2
                  ? orderInfo.serviceLocation
                  : [0, 0]
              }
              icon={icon}
            />
          ) : (
            <Marker
              position={
                orderInfo.destinationLocation?.length === 2
                  ? orderInfo.destinationLocation
                  : [0, 0]
              }
              icon={icon}
            />
          )}
        </MapContainer>
        <Button
          name="buttons.select_button"
          className="save-location-button"
          onClick={() => {
            setVisible(false);
          }}
        />
      </Dialog>
      <form className="order-form">
        <div className="order-form-title-container">
          <div className="close-button-container">
            <div className="form-title">
              {t("common.order")}{" "}
              <div className="flex align-items-center">
                <Tooltip target=".custom-target-icon" />
                <i
                  onClick={handleCopyToClipBoard}
                  data-pr-tooltip={t(
                    "orders_list.order_form.copy_to_clipboard"
                  )}
                  className="custom-target-icon pi pi-copy p-text-secondary p-overlay-badge"
                ></i>
              </div>
            </div>
            <img
              src={CloseSvg}
              alt="close car form"
              onClick={handleClose}
              id="close-card-form-button"
            />
          </div>
          <div className="customer-name-and-phone-icon-container">
            {customerInfo.customerFullName && (
              <div className="order-form-customer-name-container">
                <div
                  className="field col-12 md:col-4"
                  onClick={handleUserClick}
                >
                  <span className="p-float-label">
                    <InputText
                      disabled={true}
                      value={customerInfo.customerFullName}
                      className="form-input order-form-customer-name"
                    />
                    <label>{t("orders_list.customer_name")}</label>
                  </span>
                </div>
              </div>
            )}
            {source === "mobile" && (
              <AiOutlineMobile className="source-icons" size={20} />
            )}
          </div>
        </div>
        {!params.id && (
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <AutoComplete
                  field="name"
                  minLength={3}
                  inputId="user"
                  suggestions={users}
                  value={selectedUser}
                  aria-label="Customers"
                  onSelect={handleSelect}
                  completeMethod={searchUser}
                  itemTemplate={itemTemplate}
                  onChange={(e) => handleSelectedUser(e.value)}
                />
                <label htmlFor="user">
                  {t("orders_list.order_form.customer")}
                </label>
              </span>
            </div>
            {submitted && !params.id && !user && !fieldsDisabled && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
          </div>
        )}

        <div className="row-full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                id="car"
                name="car"
                inputId="Vehicle"
                value={orderInfo.car}
                options={carsDropDown}
                onChange={handleChange}
                disabled={
                  fieldsDisabled ||
                  (!params.id && !user) ||
                  orderInfo.car === "Deleted Car"
                }
                filter
              />
              <label htmlFor="Vehicle">Vehicle</label>
            </span>
          </div>
          {submitted && orderInfo.car?.length === 0 && !fieldsDisabled && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div className="row">
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  name="service"
                  inputId="service"
                  onChange={handleChange}
                  value={orderInfo.service}
                  disabled={fieldsDisabled}
                  options={servicesDropDown}
                />
                <label htmlFor="service">Service</label>
              </span>
            </div>
            {submitted &&
              orderInfo.service?.length === 0 &&
              !fieldsDisabled && (
                <small className="p-error block">
                  {t("common.required_field")}
                </small>
              )}
          </div>
          {(orderInfo.service?.length !== 0 ||
            orderInfo.serviceSubCategories?.length > 0) &&
            subcategory?.length > 0 && (
              <div className="row-full-width">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <Dropdown
                      options={subcategory}
                      onChange={handleChange}
                      disabled={fieldsDisabled}
                      name="serviceSubCategories"
                      inputId="serviceSubCategories"
                      value={orderInfo.serviceSubCategories}
                    />
                    <label htmlFor="serviceSubCategories">
                      {t("orders_list.order_form.service_subcategory")}
                    </label>
                  </span>
                </div>
              </div>
            )}
        </div>
        {servicesIdKeys[orderInfo.service] === TIRE_REPAIR_SERVICE && (
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                inputId="numberOfWheels"
                options={wheelsOptions}
                disabled={fieldsDisabled}
                value={selectedNumberOfWheels}
                onChange={(e) => setSelectedNumberOfWheels(e.value)}
              />
              <label htmlFor="numberOfWheels">
                {t("orders_list.order_form.number_of_wheels")}
              </label>
            </span>
          </div>
        )}
        {subCategoryExtras.map(renderExtraField)}
        <div className="column">
          <div className="row-full-width locations-row">
            <div className="location-picker-container">
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    name="locationNote"
                    onChange={handleChange}
                    disabled={fieldsDisabled}
                    value={orderInfo.locationNote}
                    className={`form-input ${fieldsDisabled ? "disabled" : ""}`}
                  />
                  <label htmlFor="locationNote">
                    {t("orders_list.order_form.location_note")}
                  </label>
                </span>
              </div>
              <i
                className={`pi pi-map-marker location-picker ${
                  fieldsDisabled ? "disabled" : ""
                }`}
                onClick={() => {
                  setCurrentLocation(true);
                  handleLocation();
                }}
              />
            </div>
          </div>
          {submitted &&
            orderInfo.locationNote?.length === 0 &&
            orderInfo.serviceLocation?.length === 0 &&
            !fieldsDisabled && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
        </div>
        {servicesIdKeys[orderInfo.service] === TOWING_SERVICE && (
          <div className="column">
            <div className="row-full-width locations-row">
              <div className="location-picker-container">
                <div className="field col-12 md:col-4">
                  <span className="p-float-label">
                    <InputText
                      name="destinationNote"
                      onChange={handleChange}
                      disabled={fieldsDisabled}
                      value={orderInfo.destinationNote}
                      className={`form-input ${
                        fieldsDisabled ? "disabled" : ""
                      }`}
                    />
                    <label htmlFor="destinationNote">
                      {t("orders_list.order_form.destination_note")}
                    </label>
                  </span>
                </div>
                <i
                  className={`pi pi-map-marker location-picker ${
                    fieldsDisabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    setCurrentLocation(false);
                    handleLocation();
                  }}
                />
              </div>
            </div>
            {submitted &&
              orderInfo.locationNote?.length === 0 &&
              orderInfo.serviceLocation?.length === 0 &&
              !fieldsDisabled && (
                <small className="p-error block">
                  {t("common.required_field")}
                </small>
              )}
          </div>
        )}
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              filter
              name="city"
              inputId="city"
              options={cities}
              value={orderInfo.city}
              onChange={handleChange}
              disabled={fieldsDisabled}
            />
            <label htmlFor="city">City</label>
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <InputNumber
              maxFractionDigits={2}
              min={0}
              id="price"
              name="price"
              inputId="price"
              value={orderInfo.price}
              onChange={handleChange}
              disabled={fieldsDisabled}
              className="form-input integer-only"
            />
            <label htmlFor="price">{t("common.totalPrice")}</label>
          </span>
        </div>
        <div className="row">
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <InputNumber
                  maxFractionDigits={2}
                  min={0}
                  id="servicePrice"
                  name="servicePrice"
                  inputId="servicePrice"
                  value={orderInfo.servicePrice}
                  onChange={handleChange}
                  disabled={fieldsDisabled}
                  className="form-input integer-only"
                />
                <label htmlFor="servicePrice">{t("common.servicePrice")}</label>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <InputNumber
                  maxFractionDigits={2}
                  min={0}
                  id="partsPrice"
                  name="partsPrice"
                  inputId="partsPrice"
                  value={orderInfo.partsPrice}
                  onChange={handleChange}
                  disabled={fieldsDisabled}
                  className="form-input integer-only"
                />
                <label htmlFor="partsPrice">{t("common.partsPrice")}</label>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  filter
                  name="provider"
                  inputId="partners"
                  onChange={handleChange}
                  disabled={fieldsDisabled}
                  options={partnersDropDown}
                  value={orderInfo.provider}
                />
                <label htmlFor="partners">{t("common.partner")}</label>
              </span>
            </div>
            {submitted &&
              orderInfo.provider?.length === 0 &&
              (orderInfo.status === "assigned" ||
                orderInfo.status === "completed" ||
                // orderInfo.status === "inprogress" ||
                orderInfo.status === "") &&
              !fieldsDisabled && (
                <small className="p-error block">
                  {t("common.required_field")}
                </small>
              )}
          </div>
          <div className="row">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  name="paymentMethod"
                  inputId="paymentMethod"
                  onChange={handleChange}
                  options={paymentMethods}
                  disabled={fieldsDisabled}
                  value={orderInfo.paymentMethod}
                />
                <label htmlFor="paymentMethod">
                  {t("common.payment_method.title")}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Calendar
                  showTime={true}
                  name="createdDate"
                  inputId="createdDate"
                  onChange={handleChange}
                  disabled={fieldsDisabled}
                  value={orderInfo.createdDate}
                  className="service-date-calendar"
                />
                <label htmlFor="createdDate">{t("orders_list.date")}</label>
              </span>
            </div>
            {submitted && orderInfo.createdDate === null && !fieldsDisabled && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
          </div>
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <span className="p-float-label">
                <Dropdown
                  name="status"
                  inputId="status"
                  onChange={handleChange}
                  options={orderStatuses}
                  value={orderInfo.status}
                  disabled={fieldsDisabled || statusDisabled}
                />
                <label htmlFor="status">
                  {t("orders_list.order_form.order_status")}
                </label>
              </span>
            </div>
            {submitted && orderInfo.status?.length === 0 && !fieldsDisabled && (
              <small className="p-error block">
                {t("common.required_field")}
              </small>
            )}
          </div>
        </div>
        <div className="row-full-width">
          <div className="field col-12 md:col-4">
            <InputTextarea
              rows={2}
              type="text"
              name="orderNote"
              value={orderInfo.orderNote}
              onChange={(e) =>
                setOrderInfo({
                  ...orderInfo,
                  orderNote: e.target.value,
                })
              }
              placeholder={t("orders_list.order_form.order_note")}
              className={`feedback-message`}
              disabled={fieldsDisabled}
            />
            {/* <label htmlFor="Vehicle">
                {t("orders_list.order_form.order_note")}
              </label> */}
          </div>
        </div>

        {(orderInfo?.cancellationMessage ||
          (orderInfo.status === "canceled" && !fieldsDisabled)) && (
          <div className="row-full-width">
            <div className="field col-12 md:col-4">
              <InputTextarea
                rows={1}
                type="text"
                name="cancellationMessage"
                disabled={fieldsDisabled}
                value={orderInfo.cancellationMessage}
                onChange={(e) =>
                  setOrderInfo({
                    ...orderInfo,
                    cancellationMessage: e.target.value,
                  })
                }
                className={`feedback-message`}
                placeholder={t("orders_list.order_form.cancelation_message")}
              />
            </div>
            {submitted &&
              !isOptionalCancellationMessage &&
              !(orderInfo?.cancellationMessage?.length > 0) &&
              !fieldsDisabled && (
                <small className="p-error block">
                  {t("common.required_field")}
                </small>
              )}
          </div>
        )}
        {order?.promoCode?.name && (
          <div className="row">
            <div className="row-full-width">
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    name="promoCode"
                    disabled={true}
                    value={`${order?.promoCode?.name} (${order?.promoCode?.discount} NIS)`}
                    className="form-input cancellation-message-input enabled"
                  />
                  <label htmlFor="promoCode">Promo Code</label>
                </span>
              </div>
            </div>
            <div className="row-full-width"></div>
          </div>
        )}
        <div className="row paid">
          <div>{t("common.paid")}</div>
          <ToggleButton
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={orderInfo?.paid}
            disabled={fieldsDisabled}
            aria-label="Confirmation"
            onChange={(e) => setOrderInfo({ ...orderInfo, paid: e.value })}
          />
        </div>
        {features.orders.order.edit && (
          <Button
            name="buttons.save_order"
            onClick={handleSave}
            disabled={fieldsDisabled}
          />
        )}
      </form>
      {!open && <FeedBackForm open={open} deletedUser={deletedUser} />}
    </div>
  );
};

export default OrderForm;
