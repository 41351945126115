import "./CompaniesList.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import { confirmDialog } from "primereact/confirmdialog";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";

import {
  deleteCompany,
  fetchCompanies,
} from "../../../redux/slices/CompaniesSlice";

const CompaniesList = () => {
  const { features } = useSelector((state) => state.user);
  const { companies } = useSelector((state) => state.companies);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let dispatch = useDispatch();
  let { t } = useTranslation();

  useEffect(() => {
    if (companies?.length) {
      setLoading(false);
    }
    dispatch(
      fetchCompanies({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBodyTemplate = (e) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        onClick={() => deleteConfirmPopUp(e.name)}
      />
    );
  };

  const deleteConfirmPopUp = (name) => {
    confirmDialog({
      header: t("services_page.companies.delete_company_pop_up.title"),
      message: t("services_page.companies.delete_company_pop_up.body"),
      accept: () => {
        setLoading(true);
        dispatch(
          deleteCompany({
            name: name,
            successCallback: successDeleteCompanyCallback,
            failureCallback: failureDeleteCompanyCallback,
          })
        );
      },
      reject: () => console.log("reject"),
    });
  };
  const successDeleteCompanyCallback = () => {
    setLoading(false);
    dispatch(
      fetchCompanies({
        successCallback: () => {},
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_deleted"),
      })
    );
  };

  const failureDeleteCompanyCallback = (message) => {
    setLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };
  return errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <DataTable
      rowHover
      rows={20}
      scrollable
      paginator
      value={companies}
      dataKey="name"
      selectionMode="single"
      responsiveLayout="scroll"
      emptyMessage={t("services_page.companies.no_companies_found")}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <Column
        filter
        sortable
        field="name"
        showAddButton={false}
        header={t("common.name")}
        showFilterMatchModes={false}
        showFilterMenuOptions={false}
        className="companies-main-column"
        filterPlaceholder={t("searches.search_by_name")}
      />
      <Column body={actionBodyTemplate} headerClassName="companies-header" />
    </DataTable>
  );
};

export default CompaniesList;
