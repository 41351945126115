import "./CustomerOrdersTab.css";
import OrdersList from "../../../../OrdersList/OrdersList";

const CustomerOrdersTab = () => {
  return (
    <div className="customerOrderScreen">
      <OrdersList userOrders={true} />
    </div>
  );
};

export default CustomerOrdersTab;
