import i18next from "i18next";
import axios from "../../api/api";
import { errorCodes } from "./constants";
import { PartnersSliceUrls } from "./SlicesUrls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchPartners = createAsyncThunk(
  "partners/fetchPartners",
  async ({ successCallback }) => {
    return axios
      .get(PartnersSliceUrls.providersUrl)
      .then((res) => {
        const partnersDropDown = [];
        res.data.forEach((partner) => {
          const partnerObj = {};
          partnerObj["value"] = partner._id;
          partnerObj["label"] = partner.name;
          partnersDropDown.push(partnerObj);
        });
        if (successCallback !== undefined) successCallback();
        return { partners: res.data, partnersDropDown };
      })
      .catch((err) => {
        console.log(err);
        return { errors: true };
      });
  }
);

export const fetchPartner = createAsyncThunk(
  "partners/fetchPartner",
  async ({ id, successCallback, failureCallback }) => {
    return axios
      .get(`${PartnersSliceUrls.providerUrl}/${id}`)
      .then((res) => {
        successCallback();
        return { partner: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const addPartner = createAsyncThunk(
  "partners/addPartner",
  async ({ partner, successCallback, failureCallback }) => {
    console.log(partner);
    return axios
      .post(PartnersSliceUrls.providerUrl, {
        ...partner,
      })
      .then((res) => {
        console.log(res.data);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        let duplicatePhoneEmail = false;
        if (
          err.response?.data?.error_code === errorCodes.DUPLICATE_EMAIL ||
          err.response?.data?.error_code === errorCodes.DUPLICATE_PHONE_NUMBER
        ) {
          message = err.response.data.message;
          duplicatePhoneEmail = true;
        } else {
          message = i18next.t("toast_messages.something_went_wrong");
        }
        failureCallback(
          message,
          duplicatePhoneEmail,
          err.response?.data?.error_code
        );
      });
  }
);

export const updatePartner = createAsyncThunk(
  "partners/updatePartner",
  async ({ id, partner, successCallback, failureCallback }) => {
    return axios
      .post(`${PartnersSliceUrls.providerUrl}/${id}`, {
        ...partner,
      })
      .then((res) => {
        successCallback();
        return { partner: res.data };
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        let duplicatePhoneEmail = false;
        if (
          err.response?.data?.error_code === errorCodes.DUPLICATE_EMAIL ||
          err.response?.data?.error_code === errorCodes.DUPLICATE_PHONE_NUMBER
        ) {
          message = err.response.data.message;
          duplicatePhoneEmail = true;
        } else {
          message = i18next.t("toast_messages.something_went_wrong");
        }
        failureCallback(
          message,
          duplicatePhoneEmail,
          err.response?.data?.error_code
        );
      });
  }
);

export const partnersSlice = createSlice({
  name: "partners",
  initialState: {
    partners: [],
    errors: false,
    partner: null,
    partnersDropDown: [],
  },
  reducers: {
    resetPartner: (state) => {
      state.partner = null;
    },
  },
  extraReducers: {
    [fetchPartners.fulfilled]: (state, action) => {
      state.partner = null;
      state.errors = action.payload?.errors;
      state.partners = action.payload.partners;
      state.partnersDropDown = action.payload.partnersDropDown;
    },
    [fetchPartner.fulfilled]: (state, action) => {
      state.partner = action.payload.partner;
    },
  },
});

export const { resetPartner, setPartner, resetPartnerActions } =
  partnersSlice.actions;

export default partnersSlice.reducer;
