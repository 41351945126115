import "./OrdersList.css";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import OrderForm from "./OrderForm/OrderForm";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import StarFilled from "../../assets/svg/StarFilled.svg";
import { ScrollRestoration, useNavigate, useParams } from "react-router-dom";
import { Button as PrimeReactButton } from "primereact/button";
import { tableFilters, statues, paidStatus, PAGE_SIZE } from "./constants";
import Button from "../../components/CustomButton/CustomButton";
import ErrorLayout from "../../components/ErrorLayout/ErrorLayout";
import { setToastMessage } from "../../redux/slices/ToastMessageSlice";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {
  resetOrder,
  fetchOrders,
  fetchCustomerOrders,
  exportOrdersToCsvFile,
  deleteOrder,
} from "../../redux/slices/OrdersSlice";
import { confirmDialog } from "primereact/confirmdialog";
import {
  ALL_ORDERS_TABLE,
  PREVENT_LOADING_WHEN_RETURNED,
  ROWS_IN_TABLE,
} from "../../constants";
import { VscFeedback } from "react-icons/vsc";

import "primereact/utils";
import Avatar from "react-avatar";
import { FaMobileAlt } from "react-icons/fa";
import { classNames } from "primereact/utils";

const OrdersList = ({ userOrders }) => {
  const { features } = useSelector((state) => state.user);
  const { orders, customerOrders, pages } = useSelector(
    (state) => state.orders
  );

  let { t } = useTranslation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let params = useParams();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [filters, setFilters] = useState(tableFilters);
  const [paidStatuses, setPaidStatuses] = useState([]);
  const [orderStatues, setOrderStatuses] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [errorDataLoading, setErrorDataLoading] = useState(false);
  const returned = sessionStorage.getItem(PREVENT_LOADING_WHEN_RETURNED);
  const getOrdersPages = (pageIndex) => {
    const timeout = setTimeout(() => {
      if (pageIndex > pages) {
        clearTimeout(timeout);
        return;
      } else {
        dispatch(
          fetchOrders({
            queryParams: {
              size: PAGE_SIZE,
              page: pageIndex,
            },
            successCallback: () => {
              getOrdersPages(pageIndex + 1);
              setLoading(false);
            },
            failureCallback: () => {},
          })
        );
      }
    }, 1000);
    return () => clearTimeout(timeout);
  };
  useEffect(() => {
    if (returned) {
      setLoading(false);
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!returned) {
      if (!userOrders) {
        if (orders?.length) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        const timeout = getOrdersPages(1);
        return timeout;
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //check this approach
    if (!returned) {
      if (userOrders) {
        if (customerOrders?.length) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        dispatch(
          fetchCustomerOrders({
            id: params.id,
            successCallback: () => setLoading(false),
            failureCallback: () => setErrorDataLoading(true),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statuses = statues.map((status) => {
      return {
        label: t("orders_list.order_statuses." + status),
        value: status,
      };
    });
    setOrderStatuses(statuses);

    const arr = paidStatus.map((status) => {
      return {
        label: t("orders_list.paid_statuses." + status),
        value: status,
      };
    });
    setPaidStatuses(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowSelect = (event) => {
    sessionStorage.setItem(PREVENT_LOADING_WHEN_RETURNED, true);
    dispatch(resetOrder());
    const state = {
      userOrders: userOrders,
      customerFullName: event.data.customerName,
      customerId: event.data.customerId,
      deletedUser: false,
    };
    if (!event.data.customerId) {
      state["deletedUser"] = true;
    }
    navigate(`/dashboard/orders/${event.data._id}`, {
      state: state,
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        mask="99/99/9999"
        dateFormat="yy/mm/dd"
        value={options.value}
        placeholder="yyyy/mm/dd"
        onChange={(e) => options.filterCallback(e.value.toLocaleDateString())}
      />
    );
  };
  const deleteOrderSuccessCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("orders_list.delete_order_successful"),
      })
    );
    if (userOrders) {
      dispatch(
        fetchCustomerOrders({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: () => setErrorDataLoading(true),
        })
      );
    } else {
      getOrdersPages(1);
    }
  };
  const deleteConfirmPopUp = (id) => {
    confirmDialog({
      header: t("orders_list.delete_order_pop_up.title"),
      message: t("orders_list.delete_order_pop_up.body"),
      accept: () =>
        dispatch(
          deleteOrder({
            id,
            successCallback: () => deleteOrderSuccessCallback(),
            failureCallback: (message) => {
              dispatch(
                setToastMessage({
                  show: true,
                  position: "bottom-center",
                  type: "error",
                  detail: message,
                })
              );
            },
          })
        ),

      reject: () => {},
    });
  };
  const actionBodyTemplate = (e) => {
    return (
      <div className="customers-list-icons-container">
        {e.feedback && <VscFeedback color="green" size={20} />}
        {e.customerId && (
          <PrimeReactButton
            type="button"
            icon="pi pi-pencil"
            onClick={() => onRowSelect({ data: e })}
          />
        )}
        {features.orders.order.delete && e.customerId && (
          <PrimeReactButton
            type="button"
            icon="pi pi-trash"
            onClick={() => deleteConfirmPopUp(e._id)}
          />
        )}
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        options={orderStatues}
        className="p-column-filter"
        placeholder="Select a Status"
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
      />
    );
  };
  const handleClose = () => {
    dispatch(resetOrder());
    setOpen(!open);
  };
  const openNewOrderHandler = () => {
    dispatch(resetOrder());
    setOpen(!open);
  };
  const handleAdd = (userOrder) => {
    if (userOrder) {
      setOpen(!open);
      dispatch(
        fetchCustomerOrders({
          id: params.id,
          successCallback: () => setLoading(false),
          failureCallback: () => setErrorDataLoading(true),
        })
      );
    } else {
      dispatch(resetOrder());
      setOpen(!open);
      dispatch(
        fetchOrders({
          queryParams: {
            size: PAGE_SIZE,
            page: 1,
          },
          successCallback: () => setLoading(false),
          failureCallback: () => setErrorDataLoading(true),
        })
      );
    }
  };

  const starBodyTemplate = (rowData) => {
    if (rowData.type === "membership") {
      return <img src={StarFilled} alt="customer-has-membership" />;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div className={`orders-list-status-column-item item-${rowData.status}`}>
        {rowData.status}
      </div>
    );
  };

  const failureExportCsvFile = (message) => {
    setCsvLoading(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const handleCSV = () => {
    setCsvLoading(true);
    dispatch(
      exportOrdersToCsvFile({
        successCallback: () => setCsvLoading(false),
        failureCallback: (message) => failureExportCsvFile(message),
      })
    );
  };

  const paidBodyTemplate = (rowData) => {
    let paid = rowData.paid ? t("common.yes") : t("common.no");
    return <div>{paid}</div>;
  };

  const paidFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        options={paidStatuses}
        className="p-column-filter"
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  useEffect(() => {
    if (returned) {
      const orderTableSession = JSON.parse(
        sessionStorage.getItem(ALL_ORDERS_TABLE)
      );
      if (orderTableSession) {
        setGlobalFilterValue(orderTableSession.filters?.global?.value);
      } else {
        setGlobalFilterValue(filters?.global?.value);
      }
    } else {
      setGlobalFilterValue(filters?.global?.value);
    }
    // eslint-disable-next-line
  }, [open]);
  const fullNameBodyTemplate = (rowData) => {
    return (
      <div>
        <div className="image-name-container">
          <div className="custom-avatar">
            <Avatar
              src={rowData?.customerPhoto?.url}
              name={`${rowData.customerName}`}
              size="40"
              round={true}
              className="p-overlay-badge"
            ></Avatar>
            {rowData.source === "mobile" && (
              <div className={classNames("custom-badge")}>
                <FaMobileAlt
                  size={13}
                  enableBackground={true}
                  className="phone-icon"
                />
              </div>
            )}
          </div>
          {rowData.customerName}
        </div>
      </div>
    );
  };
  return open ? (
    <OrderForm
      open={open}
      handleAdd={handleAdd}
      userOrders={userOrders}
      closeTapHandler={handleClose}
    />
  ) : errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <Layout
      className={`orders-tab-container ${userOrders ? "user-orders" : ""}`}
    >
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
      {csvLoading && (
        <OverlayLoader showTitle={true} title={t("common.wait_moment")} />
      )}
      <div className="table-header">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder={t("searches.keyword_search")}
        />
        <div className="csv-dropdown-container">
          {features.orders.order.add && (
            <Button
              name="buttons.new_order_button"
              onClick={openNewOrderHandler}
            />
          )}
          {features.config.csv && !userOrders && (
            <Dropdown
              onChange={() => handleCSV()}
              dropdownIcon="pi pi-ellipsis-v"
              options={[t("common.export_to_csv")]}
              className="customers-table-export-csv-dropdown"
            />
          )}
        </div>
      </div>
      <DataTable
        rowHover
        paginator
        rows={ROWS_IN_TABLE}
        dataKey="_id"
        filters={filters}
        filterDisplay="menu"
        selectionMode="single"
        className="orders-table"
        responsiveLayout="scroll"
        onRowSelect={(e) => onRowSelect(e)}
        emptyMessage={t("orders_list.no_orders")}
        value={userOrders ? customerOrders : orders}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        onFilter={(e) => setFilters(e.filters)}
        stateStorage="session"
        stateKey={ALL_ORDERS_TABLE}
      >
        <Column
          body={starBodyTemplate}
          headerClassName="orders-table-star-column"
        />
        {userOrders ? null : (
          <Column
            sortable
            field="customerName"
            showAddButton={false}
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            className="orders-table-main-column"
            body={fullNameBodyTemplate}
            header={t("orders_list.customer_name")}
          />
        )}
        <Column
          sortable
          field="car"
          showFilterMatchModes={false}
          header={t("orders_list.car")}
          showFilterMenuOptions={false}
          className="orders-table-main-column"
        />
        <Column
          sortable
          field="service"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("orders_list.service")}
          className="orders-table-main-column"
        />
        <Column
          filter
          sortable
          field="status"
          body={statusBodyTemplate}
          header={t("common.status")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={statusFilterTemplate}
          className="orders-table-main-column"
        />
        <Column
          filter
          sortable
          field="paid"
          body={paidBodyTemplate}
          header={t("common.paid")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={paidFilterTemplate}
          className="orders-table-main-column"
        />
        <Column
          filter
          sortable
          field="date"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("orders_list.date")}
          filterElement={dateFilterTemplate}
          className="orders-table-main-column"
        />
        <Column
          body={actionBodyTemplate}
          headerClassName="orders-list-row-action"
        />
      </DataTable>
    </Layout>
  );
};

export default OrdersList;
