const images = {
  logo: require("./Images/TurqatLogo.png"),
  marker: require("./Images/Marker.png"),
  markerShadow: require("./Images/MarkerShadow.png"),
  verifyEmail: require("./Images/VerifyEmail.png"),
  accept: require("./Images/Accept.png"),
  backToTop: require("./Images/backToTop.jpg"),
  changePassword: require("./Images/ChangePassword.jpg"),
};

export default images;
