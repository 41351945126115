import _ from "lodash";
import "./TodayOrders.css";
import Avatar from "react-avatar";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ORDER_STATUSES, statues } from "../../OrdersList/constants";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { resetOrder, updateOrder } from "../../../redux/slices/OrdersSlice";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";

const TodayOrders = ({ ordersLoading, handleLoading }) => {
  const { todayOrders } = useSelector((state) => state.orders);
  const { partnersDropDown } = useSelector((state) => state.partners);

  let { t } = useTranslation();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [orderStatuses, setOrderStatuses] = useState([]);
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [todayOrdersInfo, setTodayOrdersInfo] = useState([]);

  useEffect(() => {
    if (todayOrders?.length > 0) {
      setTodayOrdersInfo(todayOrders);
    }
    const statuses = statues.map((status) => {
      return {
        label: t("orders_list.order_statuses." + status),
        value: status,
      };
    });
    setOrderStatuses(statuses);
    // eslint-disable-next-line
  }, [todayOrders]);
  const successUpdateOrderCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.order.order_updated"),
      })
    );
    handleLoading();
  };

  const failureUpdateOrderCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
    handleLoading();
  };

  const handleChange = (value, orderIndex) => {
    let orders = _.cloneDeep(todayOrdersInfo);
    let order = { ...todayOrdersInfo[orderIndex] };
    if (value === ORDER_STATUSES.ASSIGNED) {
      if (assignedOrders.includes(orderIndex)) return;
      setAssignedOrders([...assignedOrders, orderIndex]);
      orders[orderIndex].status = value;
      setTodayOrdersInfo(orders);
    } else {
      if (assignedOrders.includes(orderIndex)) {
        const index = assignedOrders.indexOf(orderIndex);
        assignedOrders.splice(index, 1);
      }
      order.status = value;
      const customerId = order.customerId;
      delete order.customerId;
      order.price = order.price ? order.price : 0;
      if (order.status === ORDER_STATUSES.COMPLETED && order.price === 0) {
        order.paid = true;
      }
      dispatch(
        updateOrder({
          order,
          id: customerId,
          successCallback: () => successUpdateOrderCallback(),
          failureCallback: (message) => failureUpdateOrderCallback(message),
        })
      );
    }
  };

  const handlePartner = (provider, index) => {
    let orders = _.cloneDeep(todayOrdersInfo);
    orders[index].provider = provider;
    let order = orders[index];
    const customerId = order.customerId;
    delete order.customerId;
    dispatch(
      updateOrder({
        order,
        id: customerId,
        successCallback: () => successUpdateOrderCallback(),
        failureCallback: (message) => failureUpdateOrderCallback(message),
      })
    );
    setTodayOrdersInfo(orders);
  };

  const handleClick = (order) => {
    dispatch(resetOrder());
    navigate(`/dashboard/orders/${order._id}`, {
      state: {
        mapTab: true,
        customerFullName: order.fullName,
        customerId: order.customerId,
      },
    });
  };

  return (
    <div className="orders-container">
      {ordersLoading ? (
        <Loader todayOrders={true} />
      ) : (
        <>
          <div className="form-title center">{t("map_page.today_orders")}</div>
          <div
            className={`orders-scroll ${
              todayOrdersInfo?.length === 0 ? "no-orders" : ""
            }`}
          >
            {todayOrdersInfo?.map((order, index) => {
              return (
                <div
                  className={"today-order-container " + order.status}
                  key={index}
                >
                  <div className="order">
                    <div
                      className="today-order-clickable-area"
                      onClick={() => handleClick(order)}
                    >
                      <div className="name-row">
                        <Avatar
                          name={order.firstName + " " + order.lastName}
                          size="35"
                        />
                        <div className="full-name">{order.fullName}</div>
                      </div>
                      <div className="row">
                        <div>
                          {new Date(order.createdDate).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <Dropdown
                        name="status"
                        value={order.status}
                        options={orderStatuses}
                        onChange={(e) => handleChange(e.value, index)}
                        className={`today-order-dropdown ${order.status}`}
                        disabled={
                          order.status === "completed" ||
                          order.status === "canceled"
                        }
                      />
                      {order.status === "assigned" && (
                        <Dropdown
                          name="partner"
                          value={order.provider}
                          id="today-orders-partner"
                          options={partnersDropDown}
                          className="padded-input-text"
                          placeholder={t("common.partners")}
                          onChange={(e) => handlePartner(e.value, index)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {todayOrdersInfo?.length === 0 && (
              <div className="no-orders-message">
                {t("map_page.no_orders_today")}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TodayOrders;
