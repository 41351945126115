import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onMessageListener } from "../../firebase";
import { fetchTodayOrders } from "../../redux/slices/OrdersSlice";
import "./Notification.css";

const Notification = () => {
  const [notification, setNotification] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickNotification = (t) => {
    toast.dismiss(t.id);
    console.log(notification);
    if (notification?.data?.type === "order") {
      navigate(`/dashboard/orders/${notification.data.orderId}`, {
        state: {
          customerFullName: notification.data?.customerFullName,
          customerId: notification.data?.customerId,
        },
      });
    } else {
      //   navigate(`/dashboard/orders/${notification.data.type}`);
    }
  };

  const handleReceiveNotification = (payload) => {
    dispatch(
      fetchTodayOrders({
        successCallback: () => {},
        failureCallback: () => {},
      })
    );
    setNotification(payload);
  };

  useEffect(
    () => {
      if (notification?.data) {
        toast.custom((t) => (
          <div className="toast active" onClick={() => onClickNotification(t)}>
            <div className="toast-content">
              <i className="fas fa-solid fa-check check pi pi-bell"></i>
              <div className="message">
                <span className="text text-1">{notification?.data?.title}</span>
                <span className="text text-2">{notification?.data?.body}</span>
              </div>
            </div>
            <i className="fa-solid fa-xmark close"></i>
            <div className="progress active"></div>
          </div>
        ));
      }
    },
    // eslint-disable-next-line
    [notification]
  );

  useEffect(() => {
    const unmount = onMessageListener(handleReceiveNotification);
    return unmount;
    // eslint-disable-next-line
  }, []);

  return <Toaster position="bottom-right" reverseOrder={false} />;
};

export default Notification;
