import "./ProfilePage.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import ChangePassword from "./ChangePassword/ChangePassword";
import ProfileCard from "../CustomersList/Customer/components/GeneralTab/ProfileCard/ProfileCard";
import { languages } from "../../constants";
import { EditUserProfile } from "../CustomersList/Customer/components/GeneralTab/ProfileCard/constants";
const ProfilePage = () => {
  const { language } = useSelector((state) => state.user);

  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    const root = document?.querySelector("root");
    let dir = language === languages.ENGLISH ? "ltr" : "rtl";
    root?.setAttribute("dir", dir);
  }, [language]);

  return (
    <Layout className="profile-page">
      {changePassword ? (
        <ChangePassword setChangePassword={setChangePassword} />
      ) : (
        <div className="profile-page-container">
          <ProfileCard
            profilePage={true}
            setChangePassword={setChangePassword}
            type={EditUserProfile}
          />
        </div>
      )}
    </Layout>
  );
};

export default ProfilePage;
