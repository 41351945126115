import "./Layout.css";
const Layout = ({ children, className }) => {
  return (
    <div id="layout" className={className}>
      {children}
    </div>
  );
};

export default Layout;
