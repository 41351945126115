import api from "axios";
import i18next from "i18next";
import axios from "../../api/api";
import { UserSliceUrls } from "./SlicesUrls";
import { languages, userRoles } from "../../constants";
import { NETWORK_ERROR, userFeatures } from "./constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const createUser = createAsyncThunk(
  "user/createUser",
  async ({ failureCallback }) => {
    return axios
      .post(`${UserSliceUrls.userUrl}`, {})
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);
export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async ({ IdToken, successCallback }) => {
    return axios
      .post(
        `${UserSliceUrls.refreshToken}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${IdToken}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("JWTToken", res.headers.authentication);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
      });
  }
);
export const login = createAsyncThunk(
  "user/login",
  async ({ IdToken, failureCallback, successCallback }) => {
    return axios
      .post(
        `${UserSliceUrls.login}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${IdToken}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("JWTToken", res.headers.authentication);
        successCallback();
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        if (failureCallback) {
          failureCallback();
        }
      });
  }
);
export const logout = createAsyncThunk("user/logout", async ({ callBack }) => {
  return axios
    .post(`${UserSliceUrls.logout}`, {})
    .then((res) => {
      callBack();
      return res.data;
    })
    .catch((err) => {
      callBack();
      console.log(err);
    });
});
export const fetchAdminsDispatchers = createAsyncThunk(
  "user/fetchAdminsDispatchers",
  async ({ successCallback, failureCallback }) => {
    return axios
      .get(`${UserSliceUrls.adminsDispatchersUrl}`)
      .then((res) => {
        console.log(res);
        const adminsDispatchers = res.data.map((customer) => {
          const user = {};
          user.name = customer.fullName;
          user.role = customer.role;
          user.createdAt = new Date(customer.createdAt).toLocaleString();
          user._id = customer._id;
          return user;
        });
        successCallback();
        return { adminsDispatchers };
      })
      .catch((err) => {
        console.log(err);
        failureCallback();
      });
  }
);

export const updateUserRole = createAsyncThunk(
  "user/updateUserRole",
  async ({ role, id, successCallback, failureCallback }) => {
    return axios
      .put(`${UserSliceUrls.adminsDispatchersUrl}/${id}`, {
        role,
      })
      .then((res) => {
        successCallback();
        console.log(res.data);
        return { user: res.data };
      })
      .catch((err) => {
        console.log(err);
        failureCallback(i18next.t("toast_messages.something_went_wrong"));
      });
  }
);

export const setAccountInfo = createAsyncThunk(
  "user/setAccountInfo",
  async ({ oobCode, params, successCallback, failureCallback }) => {
    return api
      .post(
        `${UserSliceUrls.setAccountInfoUrl}`,
        {
          oobCode,
        },
        { params }
      )
      .then((res) => {
        console.log(res);
        successCallback();
      })
      .catch((err) => {
        console.log(err);
        let message = "";
        if (err.code === NETWORK_ERROR)
          message = i18next.t("toast_messages.something_went_wrong");
        else message = err.response.data.error.message;
        failureCallback(message);
      });
  }
);

export const getSmsConfiguration = createAsyncThunk(
  "user/getSmsStatus",
  async ({ successCallback, failureCallback }) => {
    axios
      .get(`${UserSliceUrls.adminSms}`)
      .then((res) => {
        console.log(res.data);
        successCallback?.(res.data.smsFlag, res.data.phoneNumbers);
      })
      .catch((err) => failureCallback?.(err));
  }
);

export const updateSmsConfiguration = createAsyncThunk(
  "user/getSmsStatus",
  async ({ smsFlag, phoneNumbersList, successCallback, failureCallback }) => {
    axios
      .put(`${UserSliceUrls.adminSms}`, {
        smsFlag,
        phoneNumbers: phoneNumbersList,
      })
      .then((res) => successCallback?.(res.data.smsFlag, res.data.phoneNumbers))
      .catch((err) => failureCallback?.(err));
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedUser: {},
    isLogged: false,
    adminsDispatchers: [],
    language: languages.ENGLISH,
    features: userFeatures.initialFeatures,
  },
  reducers: {
    setLoggedOut: (state, action) => {
      state.email = null;
      state.isLogged = false;
    },
    setLogged: (state, action) => {
      state.isLogged = action.payload.isLogged;
    },
    setLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    refreshUserFeature: (state, action) => {
      switch (action.payload.role) {
        case userRoles.DISPATCHER:
          state.features = userFeatures.dispatcherFeatures;
          break;
        case userRoles.ADMIN:
          state.features = userFeatures.adminFeatures;
          break;
        case userRoles.SUPER_ADMIN:
          state.features = userFeatures.superAdminFeatures;
          break;
        case userRoles.CRM:
          state.features = userFeatures.crmFeatures;
          break;
        default:
          state.features = userFeatures.initialFeatures;
          break;
      }
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loggedUser = action.payload;
      state.role = action.payload.role;
      switch (action.payload.role) {
        case userRoles.DISPATCHER:
          state.features = userFeatures.dispatcherFeatures;
          break;
        case userRoles.ADMIN:
          state.features = userFeatures.adminFeatures;
          break;
        case userRoles.SUPER_ADMIN:
          state.features = userFeatures.superAdminFeatures;
          break;
        case userRoles.CRM:
          state.features = userFeatures.crmFeatures;
          break;
        default:
          state.features = userFeatures.initialFeatures;
          break;
      }
      state.isLogged = true;
    },
    [logout.fulfilled]: (state) => {
      state.email = null;
      state.isLogged = false;
    },
    [fetchAdminsDispatchers.fulfilled]: (state, action) => {
      state.adminsDispatchers = action.payload?.adminsDispatchers;
    },
  },
});

export const { setLoggedOut, setLogged, setLanguage, refreshUserFeature } =
  userSlice.actions;

export default userSlice.reducer;
