import "./OverlayLoader.css";

const OverlayLoader = ({ showTitle, title }) => {
  return (
    <div className={`overlay ${showTitle ? "darker-overlay" : ""}`}>
      {showTitle && <h1>{title}</h1>}
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default OverlayLoader;
