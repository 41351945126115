import "./FeedBackForm.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import StarEmpty from "../../../../assets/svg/StarEmpty.svg";
import StarFilled from "../../../../assets/svg/StarFilled.svg";
import Button from "../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../redux/slices/ToastMessageSlice";
import {
  addOrderFeedBack,
  fetchOrder,
  updateOrderFeedBack,
} from "../../../../redux/slices/OrdersSlice";

const FeedBackForm = ({ open, deletedUser }) => {
  const { order } = useSelector((state) => state.orders);

  const initialOrderFeedBack = {
    rate: 0,
    message: "",
    type: [],
  };
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [orderFeedback, setOrderFeedback] = useState(initialOrderFeedBack);

  let params = useParams();
  let dispatch = useDispatch();
  let { t } = useTranslation();

  useEffect(() => {
    if (order?.feedback) {
      const feedBackObj = initialOrderFeedBack;
      const keys = Object.keys(feedBackObj);
      keys.forEach((key) => {
        if (order?.feedback[key] === undefined) {
          feedBackObj[key] = "";
        } else {
          feedBackObj[key] = order?.feedback[key];
        }
      });
      setOrderFeedback(feedBackObj);
      setRating(order.feedback.rate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const onChoiceChange = (e) => {
    let newType = [...orderFeedback.type];
    if (e.checked) {
      newType.push(e.target.name);
    } else {
      newType.splice(newType.indexOf(e.value), 1);
    }
    setOrderFeedback({
      ...orderFeedback,
      type: newType,
    });
  };

  const successAddFeedbackCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.feedback.feedback_added"),
      })
    );
    dispatch(
      fetchOrder({
        id: params.id,
        successCallback: () => {},
        failureCallback: () => {},
      })
    );
  };

  const failureAddUpdateFeedbackCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const successUpdateFeedbackCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.feedback.feedback_updated"),
      })
    );
  };

  const handleFeedBack = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const feedback = orderFeedback;
    feedback.rate = rating;
    feedback.order = order._id;
    if (orderFeedback.type?.length === 0) return;
    if (!order.feedback) {
      dispatch(
        addOrderFeedBack({
          feedback,
          successCallback: () => successAddFeedbackCallback(),
          failureCallback: (message) =>
            failureAddUpdateFeedbackCallback(message),
        })
      );
    } else {
      dispatch(
        updateOrderFeedBack({
          feedback,
          id: order.feedback._id,
          successCallback: () => successUpdateFeedbackCallback(),
          failureCallback: (message) =>
            failureAddUpdateFeedbackCallback(message),
        })
      );
    }
  };

  return (
    <form className="feedback-form">
      <div className="form-title">{t("orders_list.feedback_form.title")}</div>
      <div className="row stars-row">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={
                !deletedUser
                  ? index <= (hover || rating)
                    ? "on"
                    : "off"
                  : "on"
              }
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
              disabled={deletedUser}
            >
              <img
                src={index <= (hover || rating) ? StarFilled : StarEmpty}
                alt="star"
              />
            </button>
          );
        })}
      </div>
      <div>
        <div className="feedback-form-question">
          {t("orders_list.feedback_form.question")}
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice1"
            name="social_media"
            value={t("orders_tab.social_media")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("social_media") !== -1}
          />
          <label htmlFor="choice1">
            {t("orders_list.feedback_form.choices.social_media")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice2"
            name="billboard"
            value={t("orders_tab.billboard")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("billboard") !== -1}
          />
          <label htmlFor="choice2">
            {t("orders_list.feedback_form.choices.billboard")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice3"
            name="word_of_mouth"
            value={t("orders_tab.word_of_mouth")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("word_of_mouth") !== -1}
          />
          <label htmlFor="choice3">
            {t("orders_list.feedback_form.choices.word_of_mouth")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice4"
            name="used_service_before"
            value={t("orders_tab.used_service_before")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("used_service_before") !== -1}
          />
          <label htmlFor="choice4">
            {t("orders_list.feedback_form.choices.used_service_before")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice5"
            name="radio"
            value={t("orders_tab.radio")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("radio") !== -1}
          />
          <label htmlFor="choice5">
            {t("orders_list.feedback_form.choices.radio")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice6"
            name="news_outlet"
            value={t("orders_tab.news_outlet")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("news_outlet") !== -1}
          />
          <label htmlFor="choice6">
            {t("orders_list.feedback_form.choices.news_outlet")}
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="choice7"
            name="direct_sales"
            value={t("orders_tab.direct_sales")}
            onChange={onChoiceChange}
            disabled={deletedUser}
            checked={orderFeedback.type.indexOf("direct_sales") !== -1}
          />
          <label htmlFor="choice7">
            {t("orders_list.feedback_form.choices.direct_sales")}
          </label>
        </div>
        <div>
          {submitted && orderFeedback.type?.length === 0 && (
            <small className="p-error block">
              {t("orders_list.feedback_form.select_one_choice")}
            </small>
          )}
        </div>
      </div>
      <div className="column">
        <InputTextarea
          rows={2}
          value={orderFeedback?.message}
          onChange={(e) =>
            setOrderFeedback({ ...orderFeedback, message: e.target.value })
          }
          placeholder={t(
            "customers_page.customer.orders_tab.notes_about_experience"
          )}
          className="feedback-message"
          disabled={deletedUser}
        />
      </div>
      <Button
        flex="flexStart"
        disabled={open || deletedUser}
        onClick={handleFeedBack}
        name="buttons.send_feedback_button"
        className={`send-feedback-button${open ? "-disabled" : ""}`}
      />
    </form>
  );
};

export default FeedBackForm;
