import {
  ALL_ORDERS_TABLE,
  CUSTOMERS_TABLE,
  PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE,
  PARTNERS_TABLE,
  PREVENT_LOADING_WHEN_RETURNED,
} from "../../../constants";

const clearSessions = () => {
  sessionStorage.removeItem(ALL_ORDERS_TABLE);
  sessionStorage.removeItem(CUSTOMERS_TABLE);
  sessionStorage.removeItem(PARTNERS_TABLE);
  sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED);
  sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
};
export default clearSessions;
