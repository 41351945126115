import carsReducer from "./slices/CarsSlice";
import userReducer from "./slices/UserSlice";
import tabsReducer from "./slices/TabsSlice";
import ordersReducer from "./slices/OrdersSlice";
import storage from "redux-persist/lib/storage";
import servicesReducer from "./slices/ServicesSlice";
import partnersReducer from "./slices/PartnersSlice";
import customersReducer from "./slices/CustomersSlice";
import membershipsReducer from "./slices/MembershipsSlice";
import toastMessagesReducer from "./slices/ToastMessageSlice";
import promotionCodesReducer from "./slices/PromotionCodesSlice";
import companiesReducer from "./slices/CompaniesSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const USER_LOGOUT = "user/logout";

const appReducer = combineReducers({
  user: userReducer,
  customers: customersReducer,
  services: servicesReducer,
  partners: partnersReducer,
  promotionCodes: promotionCodesReducer,
  orders: ordersReducer,
  cars: carsReducer,
  memberships: membershipsReducer,
  tabs: tabsReducer,
  toastMessages: toastMessagesReducer,
  companies: companiesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

persistStore(store);
