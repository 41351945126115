export const paymentMethods = [
  { label: "Visa", value: "visa" },
  { label: "Cash", value: "cash" },
  { label: "Bank Transfer", value: "bank_transfer" },
  { label: "eWallets", value: "ewallets" },
];

export const newMembershipOptionsDropDown = [
  { label: "Select Membership", value: "select_membership" },
  { label: "Add Custom Membership", value: "add_custom_membership" },
];

export const newMembershipOptions = {
  SELECT_MEMBERSHIP: "select_membership",
  CUSTOM_MEMBERSHIP: "add_custom_membership",
};
