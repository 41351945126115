import "./Navbar.css";
import { useRef } from "react";
import {
  auth,
  notificationUnsubscriber,
  requestFCMToken,
} from "../../../firebase";
import { Button } from "primereact/button";
import images from "../../../assets/images";
import { SERVICES_TABS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { SlideMenu } from "primereact/slidemenu";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, ScrollRestoration, useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { resetTabs, setServicesTab } from "../../../redux/slices/TabsSlice";
import {
  logout,
  setLanguage,
  setLoggedOut,
} from "../../../redux/slices/UserSlice";
import clearSessions from "./ClearSessions";

const Navbar = () => {
  const { loggedUser, features } = useSelector((state) => state.user);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { t } = useTranslation();

  const menu = useRef(null);

  const handleSignOut = () => {
    requestFCMToken()
      .then(notificationUnsubscriber)
      .then(() => {
        dispatch(
          logout({
            callBack: () => {
              auth
                .signOut()
                .then(() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  navigate("/sign-in");
                })
                .catch((err) => {
                  console.log(err);
                });
            },
          })
        );
      })
      .catch((error) => console.log(error));
  };

  const confirm = () => {
    confirmDialog({
      message: t("navbar.sign_out_popup"),
      header: t("navbar.sign_out"),
      accept: () => {
        dispatch(setLoggedOut({ isLogged: false }));
        handleSignOut();
      },
    });
  };

  const handleClick = () => {
    dispatch(resetTabs());
    clearSessions();
  };
  const handleServiceClick = () => {
    clearSessions();
    dispatch(resetTabs());
    dispatch(
      setServicesTab({
        servicesTab: features.services.requests.view
          ? SERVICES_TABS.REQUESTS
          : features.services.memberships.view
          ? SERVICES_TABS.MEMBERSHIPS
          : features.services.services.view
          ? SERVICES_TABS.SERVICES
          : SERVICES_TABS.PROMO_CODE,
      })
    );
  };
  // const handleLanguage = (e, newLanguage) => {
  //   if (newLanguage === language) return;
  //   dispatch(setLanguage({ language: newLanguage }));
  //   menu.current.toggle(e);
  // };

  const menuItems = [
    {
      label: loggedUser.fullName,
      className: "dropdown-user-email",
    },
    {
      label: "Profile",
      icon: "pi pi-user",
      command: () => {
        navigate("/dashboard/profile");
      },
    },
    {
      command: confirm,
      icon: "pi pi-lock",
      label: t("navbar.sign_out"),
    },
    // {
    //   label: "Language",
    //   icon: "pi pi-book",
    //   items: [
    //     {
    //       label: t("languages.english"),
    //       icon: `pi pi-check ${
    //         language !== languages.ENGLISH ? "check-hidden" : ""
    //       }`,
    //       command: (e) => {
    //         handleLanguage(e, languages.ENGLISH);
    //       },
    //     },
    //     {
    //       label: t("languages.arabic"),
    //       icon: `pi pi-check ${
    //         language !== languages.ARABIC ? "check-hidden" : ""
    //       }`,
    //       command: (e) => {
    //         handleLanguage(e, languages.ARABIC);
    //       },
    //     },
    //   ],
    // },
  ];

  const handleLogoClick = () => {
    clearSessions();
    navigate(features.config.initialRoute);
  };

  return (
    <header>
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
      <img
        alt="logo"
        className="logo"
        src={images.logo}
        onClick={() => handleLogoClick()}
      />
      <div id="nav">
        {features.map.view && (
          <NavLink
            className="link"
            to="/dashboard/map"
            onClick={() => handleClick()}
          >
            {t("navbar.map")}
          </NavLink>
        )}
        {features.orders.order.view && (
          <NavLink
            className="link"
            to="/dashboard/orders"
            onClick={() => handleClick()}
          >
            {t("common.orders")}
          </NavLink>
        )}
        {features.customers.view && (
          <NavLink
            className="link"
            to="/dashboard/customers"
            onClick={() => handleClick()}
          >
            {t("navbar.customers")}
          </NavLink>
        )}
        {features.partners.view && (
          <NavLink
            className="link"
            to="/dashboard/partners"
            onClick={() => handleClick()}
          >
            {t("common.partners")}
          </NavLink>
        )}
        {features.services.servicesTab && (
          <NavLink
            className="link"
            to="/dashboard/services"
            onClick={() => handleServiceClick()}
          >
            {t("common.services")}
          </NavLink>
        )}
        {features.administration.view && (
          <NavLink
            className="link"
            to="/dashboard/administration"
            onClick={() => handleClick()}
          >
            {t("navbar.administration")}
          </NavLink>
        )}
      </div>
      <SlideMenu ref={menu} model={menuItems} popup id="popup_menu"></SlideMenu>

      {/* <Menu model={menuItems} popup ref={menu} id="popup_menu" /> */}
      <Button
        icon="pi pi-user"
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
      />
    </header>
  );
};

export default Navbar;
