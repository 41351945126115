import "./ProfileCard.css";
import Avatar from "react-avatar";
import { useEffect, useMemo, useState } from "react";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CloseSvg from "../../../../../../assets/svg/Close.svg";
import { errorCodes } from "../../../../../../redux/slices/constants";
import { createUser } from "../../../../../../redux/slices/UserSlice";
import Button from "../../../../../../components/CustomButton/CustomButton";
import { Button as DeleteButton } from "primereact/button";

import { setToastMessage } from "../../../../../../redux/slices/ToastMessageSlice";
import { EditUserProfile } from "./constants";
import {
  validateEmail,
  validateForm,
  validatePhoneNumber,
} from "../../../../../../utils/Validator";
import {
  addCustomer,
  updateCustomer,
} from "../../../../../../redux/slices/CustomersSlice";
import { fetchCompanies } from "../../../../../../redux/slices/CompaniesSlice";
import { PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE } from "../../../../../../constants";

const ProfileCard = ({
  newUser,
  noteInfo,
  closeForm,
  saveProfile,
  profilePage,
  setSaveProfile,
  setChangePassword,
  type,
}) => {
  const { loggedUser, features } = useSelector((state) => state.user);
  const { cities, customer } = useSelector((state) => state.customers);
  const { companies } = useSelector((state) => state.companies);

  const formatedCompanies = useMemo(
    () => companies?.map((company) => company.name) ?? [],
    [companies]
  );

  const [validEmail, setValidEmail] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [firstTimeSubmitted, setFirstTimeSubmitted] = useState(false);
  const [duplicatePhoneNumber, setDuplicatePhoneNumber] = useState(false);
  const [dropdownCompanies, setDropdownCompanies] = useState(formatedCompanies);

  let dispatch = useDispatch();
  let { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();

  const initialUserInputs = {
    city: "",
    email: "",
    lastName: "",
    birthday: "",
    firstName: "",
    fatherName: "",
    gender: "male",
    phoneNumber: "",
    grandfatherName: "",
    company: "",
  };
  const [user, setUser] = useState(initialUserInputs);

  useEffect(() => {
    const path = location.pathname;
    if (customer || path === "/dashboard/profile") {
      let customerObj = {};
      if (path === "/dashboard/profile") {
        customerObj = loggedUser;
      } else {
        customerObj = customer;
      }
      const customerInfo = initialUserInputs;
      const keys = Object.keys(customerInfo);
      keys.forEach((key) => {
        if (customerObj[key] === undefined) {
          customerInfo[key] = undefined;
        } else {
          customerInfo[key] = customerObj[key];
        }
      });
      console.log(customerInfo);
      setUser(customerInfo);
      validateEmailPhoneNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    dispatch(fetchCompanies({ successCallback: () => {} }));
    if (
      (features.customers.tabs.general.edit && !newUser) ||
      (features.customers.add && newUser) ||
      type === EditUserProfile
    ) {
      setFieldsDisabled(false);
    } else {
      setFieldsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({ ...user, customerNote: noteInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteInfo]);

  useEffect(() => {
    if (user) {
      validateEmailPhoneNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const replaceCountryCode = () => {
    if (user.phoneNumber.startsWith("+970")) {
      let phoneNumber = user.phoneNumber.replace("+970", "+972");
      const customer = { ...user };
      customer.phoneNumber = phoneNumber;
      return customer;
    } else {
      return user;
    }
  };

  const successSaveProfile = () => {
    if (setSaveProfile !== undefined) setSaveProfile(false);
    dispatch(fetchCompanies({ successCallback: () => {} }));
    setFirstTimeSubmitted(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.user.user_updated"),
      })
    );
    if (type === EditUserProfile) {
      navigate("/dashboard/map");
    }
    if (profilePage) {
      const token = localStorage.getItem("token");
      dispatch(
        createUser({
          token,
          failureCallback: (message, duplicatePhoneEmail, errorCode) =>
            failureAddUpdateProfile(message, duplicatePhoneEmail, errorCode),
        })
      );
    }
  };

  const failureAddUpdateProfile = (message, duplicatePhoneEmail, errorCode) => {
    if (setSaveProfile !== undefined) setSaveProfile(false);
    if (!duplicatePhoneEmail) {
      dispatch(
        setToastMessage({
          show: true,
          position: "bottom-center",
          type: "error",
          detail: message,
        })
      );
    } else {
      if (errorCode === errorCodes.DUPLICATE_EMAIL) setDuplicateEmail(true);
      else setDuplicatePhoneNumber(true);
      setDuplicateMessage(message);
    }
  };

  const successAddCustomer = (id) => {
    dispatch(fetchCompanies({ successCallback: () => {} }));
    setFirstTimeSubmitted(false);
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("customer.user_added"),
      })
    );
    navigate(`/dashboard/customers/${id}`);
  };

  const validateFields = () => {
    let validated = validateForm([
      user.firstName,
      user.lastName,
      user.phoneNumber,
    ]);
    if (user.email?.length > 0 && !validEmail) {
      setSaveProfile(false);
      return false;
    }
    if (!validated || !validPhoneNumber) return false;
    else return true;
  };

  const saveUserProfile = () => {
    setFirstTimeSubmitted(true);
    const validated = validateFields();
    if (validated) {
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
      const id = profilePage ? loggedUser._id : customer?._id;
      dispatch(
        updateCustomer({
          user,
          id,
          successCallback: () => successSaveProfile(),
          failureCallback: (message, duplicatePhoneEmail, errorCode) =>
            failureAddUpdateProfile(message, duplicatePhoneEmail, errorCode),
        })
      );
    } else {
      if (setSaveProfile !== undefined) setSaveProfile(false);
    }
  };

  useEffect(() => {
    if (saveProfile) {
      saveUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveProfile]);

  const handleAddUser = () => {
    setFirstTimeSubmitted(true);
    const validated = validateFields();
    if (validated && validPhoneNumber) {
      const customer = replaceCountryCode();
      dispatch(
        addCustomer({
          customer,
          successCallback: (id) => successAddCustomer(id),
          failureCallback: (message, duplicatePhoneEmail, errorCode) =>
            failureAddUpdateProfile(message, duplicatePhoneEmail, errorCode),
        })
      );
    }
  };

  const handleChange = (e) => {
    const userInfo = user;
    if (typeof e !== "object") {
      setUser({ ...userInfo, phoneNumber: e });
    } else {
      setUser({ ...userInfo, [e.target.name]: e.target.value });
    }
  };

  const handleGender = (gender) => {
    const userInfo = user;
    setUser({ ...userInfo, gender: gender });
  };

  const validateEmailPhoneNumber = () => {
    if (user.email?.length !== 0) {
      if (user.email === undefined) {
        setValidEmail(false);
      } else {
        setValidEmail(validateEmail(user.email));
      }
    }
    if (user.phoneNumber?.length !== 0) {
      if (user.phoneNumber === undefined) {
        setValidPhoneNumber(false);
      } else {
        setValidPhoneNumber(validatePhoneNumber(user.phoneNumber));
      }
    }
  };

  const handleBlur = () => {
    validateEmailPhoneNumber();
  };

  const handleFilterCompany = (e) => {
    if (
      formatedCompanies.find((item) =>
        item.toLowerCase().trim().includes(e.filter.toLowerCase().trim())
      )
    ) {
      setDropdownCompanies(formatedCompanies);
    } else {
      setDropdownCompanies([e.filter]);
    }
  };

  const handleDeleteUserCompany = (e) => {
    const userInfo = user;
    setUser({ ...userInfo, company: null });
  };
  return (
    <div className={`profile-card-container ${newUser ? "new-customer" : ""}`}>
      {profilePage ? null : (
        <>
          {newUser && (
            <div className="close-profile-card-form">
              <img
                src={CloseSvg}
                alt="close car form"
                onClick={closeForm}
                className="close-button"
              />
            </div>
          )}
          {customer?.photo?.url ? (
            <div className="profile-picture-container">
              <img src={customer?.photo?.url} alt="" id="profile-img" />
            </div>
          ) : (
            <div className="row full-row">
              <Avatar
                size="100"
                color="#ffd800"
                className="edit-profile-avatar"
                name={`${user.firstName} ${user.lastName}`}
              />
            </div>
          )}
        </>
      )}
      <div className="row">
        <div className="full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
                disabled={fieldsDisabled}
                className="profile-card-input"
              />
              <label htmlFor="firstName">{t("common.first_name")}</label>
            </span>
          </div>
          {firstTimeSubmitted && user.firstName?.length === 0 && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
        <div className="full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="fatherName"
                value={user.fatherName}
                onChange={handleChange}
                disabled={fieldsDisabled}
                className="profile-card-input"
              />
              <label htmlFor="fatherName">
                {t("profile_card.father_name")}
              </label>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="grandfatherName"
                onChange={handleChange}
                disabled={fieldsDisabled}
                value={user.grandfatherName}
                className="profile-card-input"
              />
              <label htmlFor="grandfatherName">
                {t("profile_card.grandfather_name")}
              </label>
            </span>
          </div>
        </div>
        <div className="full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                required
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
                disabled={fieldsDisabled}
                className="profile-card-input"
                placeholder={t("common.last_name")}
              />
              <label htmlFor="lastName">{t("common.last_name")}</label>
            </span>
          </div>
          {firstTimeSubmitted && user.lastName?.length === 0 && (
            <small className="p-error block">
              {t("common.required_field")}
            </small>
          )}
        </div>
      </div>
      <div className="row">
        <div className="full-width">
          <PhoneInput
            required
            name="phoneNumber"
            defaultCountry="PS"
            onBlur={handleBlur}
            onChange={handleChange}
            value={user.phoneNumber}
            disabled={fieldsDisabled}
            className="profile-card-input"
            placeholder={t("common.phone_number")}
          />
          {firstTimeSubmitted &&
            (user.phoneNumber?.length === 0 ||
              !validPhoneNumber ||
              duplicatePhoneNumber) && (
              <small className="p-error block">
                {user.phoneNumber?.length === 0
                  ? t("common.required_field")
                  : !validPhoneNumber
                  ? t("common.enter_valid_phone_number")
                  : "* " + duplicateMessage}
              </small>
            )}
        </div>
        <div className="full-width">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Calendar
                required
                name="birthday"
                id="customer-birthday"
                onChange={handleChange}
                disabled={fieldsDisabled}
                className="birthday-calendar"
                placeholder={t("profile_card.birth_day")}
                value={user.birthday ? new Date(user.birthday) : ""}
              />
              <label htmlFor="birthday">{t("profile_card.birth_day")}</label>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <InputText
              required
              type="email"
              name="email"
              value={user.email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t("common.email")}
              className="profile-card-input full-row"
              disabled={fieldsDisabled || profilePage}
            />
            <label htmlFor="email">{t("common.email")}</label>
          </span>
        </div>
        {firstTimeSubmitted &&
          ((!validEmail && user.email?.length > 0) || duplicateEmail) && (
            <small className="p-error block">
              {duplicateEmail
                ? "* " + duplicateMessage
                : t("common.enter_valid_email")}
            </small>
          )}
      </div>
      {!profilePage && (
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              name="city"
              options={cities}
              value={user.city}
              id="cities-dropdown"
              onChange={handleChange}
              disabled={fieldsDisabled}
              placeholder={t("common.city")}
            />
            <label htmlFor="city">{t("common.city")}</label>
          </span>
        </div>
      )}
      {type !== EditUserProfile && (
        <div className="field col-12 md:col-4">
          <span className="p-float-label companies-input-no-border">
            <Dropdown
              filter
              name="company"
              inputId="companies"
              onChange={handleChange}
              disabled={fieldsDisabled || profilePage}
              options={dropdownCompanies}
              onFilter={handleFilterCompany}
              value={user?.company}
              className="companies-dropdown"
            />
            <DeleteButton
              icon="pi pi-times"
              rounded
              text
              severity="danger"
              aria-label="Cancel"
              className="delete-company-button"
              onClick={handleDeleteUserCompany}
            />
            <label htmlFor="companies">{t("common.company")}</label>
          </span>
        </div>
      )}
      <div className="row gender-row">
        <span id="gender">{t("profile_card.gender")}</span>
        <div className="row gender-buttons">
          <button
            className={user.gender === "female" ? "selected" : "notSelected"}
            name="female"
            onClick={() => handleGender("female")}
            disabled={fieldsDisabled}
          >
            {t("profile_card.female")}
          </button>
          <button
            name="male"
            disabled={fieldsDisabled}
            onClick={() => handleGender("male")}
            className={user.gender === "male" ? "selected" : "notSelected"}
          >
            {t("profile_card.male")}
          </button>
        </div>
      </div>
      {newUser && (
        <Button
          onClick={() => handleAddUser()}
          name="buttons.add_customer_button"
        />
      )}
      {profilePage && (
        <>
          <div
            className="change-password-button"
            onClick={() => setChangePassword(true)}
          >
            {t("profile_card.change_password")}
          </div>
          <Button
            onClick={() => saveUserProfile()}
            name="buttons.save_profile_button"
          />
        </>
      )}
    </div>
  );
};

export default ProfileCard;
