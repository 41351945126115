import "./ErrorLayout.css";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import WrongSvg from "../../assets/svg/Wrong.svg";
import { LOCAL_STORAGE_TOKEN } from "../../redux/slices/constants";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { refreshToken } from "../../redux/slices/UserSlice";

const ErrorLayout = () => {
  let { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="error-layout-container">
      <img src={WrongSvg} alt="error-logo" />
      <h2>{t("error_layout.title")}</h2>
      <small>{t("error_layout.content")}</small>
      <Button
        icon="pi pi-replay"
        aria-label="Search"
        className="p-button-rounded p-button-info"
        onClick={() => {
          auth.currentUser?.getIdToken(true).then((IdToken) => {
            localStorage.setItem(LOCAL_STORAGE_TOKEN, IdToken);
            dispatch(
              refreshToken({
                IdToken,
                successCallback: () => {
                  window.location.reload(false);
                },
              })
            );
          });
        }}
      />
    </div>
  );
};

export default ErrorLayout;
