import "./ServicesList.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import {
  fetchServices,
} from "../../../redux/slices/ServicesSlice";

const ServicesList = () => {
  const { features } = useSelector((state) => state.user);
  const { services } = useSelector((state) => state.services);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorDataLoading, setErrorDataLoading] = useState(false);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { t } = useTranslation();

  useEffect(() => {
    if (services?.length) {
      setLoading(false);
    }
    dispatch(
      fetchServices({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services) {
      const arr = services.map((service) => {
        const obj = { ...service };
        obj.key = t("services_page.services." + service.key);
        return obj;
      });
      setData(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const actionBodyTemplate = (e) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        onClick={() => onRowSelect({ data: e })}
        className={
          !features.services.services.edit || e.key === "Towing/جر وسحب المركبة"
            ? "services-list-features-not-allowed"
            : ""
        }
      />
    );
  };

  const onRowSelect = (event) => {
    navigate(`/dashboard/services/${event.data._id}`);
  };

  const handleRowSelection = (rowData) => {
    if (rowData.data.key !== "Towing/جر وسحب المركبة") {
      return true;
    } else {
      return false;
    }
  };

  return errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <DataTable
      rowHover
      rows={20}
      scrollable
      paginator
      value={data}
      dataKey="_id"
      selectionMode="single"
      responsiveLayout="scroll"
      onRowSelect={onRowSelect}
      isDataSelectable={handleRowSelection}
      emptyMessage={t("services_page.services.no_services_found")}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <Column
        filter
        sortable
        field="key"
        showAddButton={false}
        header={t("common.name")}
        showFilterMatchModes={false}
        showFilterMenuOptions={false}
        className="services-main-column"
        filterPlaceholder={t("searches.search_by_name")}
      />
      <Column body={actionBodyTemplate} headerClassName="services-header" />
    </DataTable>
  );
};

export default ServicesList;
