import { useEffect, useState } from "react";
import "./CustomMembership.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../../../../../assets/svg/Close.svg";
import { validateForm } from "../../../../../../utils/Validator";
import Button from "../../../../../../components/CustomButton/CustomButton";
import { setToastMessage } from "../../../../../../redux/slices/ToastMessageSlice";
import { addCustomMembership } from "../../../../../../redux/slices/MembershipsSlice";
import { PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE } from "../../../../../../constants";

const initialMembership = {
  car: "",
  price: null,
  endDate: "",
  services: [
    { numberOfFreeTimesUsed: 0, numberOfFreeTimes: 0 },
    { numberOfFreeTimesUsed: 0, numberOfFreeTimes: 0 },
    { numberOfFreeTimesUsed: 0, numberOfFreeTimes: 0 },
    { numberOfFreeTimesUsed: 0, numberOfFreeTimes: 0 },
    { numberOfFreeTimesUsed: 0, numberOfFreeTimes: 0 },
  ],
  startDate: "",
};

const CustomMembership = ({
  handleOpen,
  isEditing,
  userMembership,
  handleEditMembership,
  handleClosing,
}) => {
  const { carsDropDown, carsWithMembership } = useSelector(
    (state) => state.customers
  );
  const { services } = useSelector((state) => state.services);
  const [customMembership, setCustomMembership] = useState(
    isEditing ? userMembership : initialMembership
  );
  const [carsWithNoMemberships, setCarsWithNoMemberships] = useState([]);

  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (carsWithMembership.length) {
      const filteredCars = carsDropDown.filter((element) => {
        if (!carsWithMembership.includes(element.value)) return element;
      });
      setCarsWithNoMemberships(filteredCars);
    } else {
      setCarsWithNoMemberships(carsDropDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carsDropDown, carsWithMembership]);
  useEffect(() => {
    if (services) {
      const servicesArray = services.map((element) => {
        return {
          service: element._id,
          numberOfFreeTimesUsed: 0,
          numberOfFreeTimes: 0,
        };
      });
      initialMembership.services = servicesArray;
    }
  }, []);
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let params = useParams();

  const handleChange = (e) => {
    if (e.target?.name) {
      setCustomMembership({
        ...customMembership,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomMembership({
        ...customMembership,
        [e.originalEvent.target.name]: e.value,
      });
    }
  };

  const handleServicesPrices = (e, id) => {
    const services = [...customMembership.services];
    let service = services.filter((element) => {
      return element.service === id;
    });
    if (service.length) {
      services[services.indexOf(service[0])][e.originalEvent.target.name] =
        e.value;
    } else {
      services.push({
        service: id,
        [e.originalEvent.target.name]: e.value,
      });
    }
    setCustomMembership({ ...customMembership, services });
  };

  const handleEmptyServicesFields = () => {
    customMembership.services?.forEach((service) => {
      if (typeof service.numberOfFreeTimesUsed !== "number")
        service.numberOfFreeTimesUsed = 0;
      if (typeof service.numberOfFreeTimes !== "number")
        service.numberOfFreeTimes = 0;
    });
  };

  const successAddCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_added"),
      })
    );
    handleOpen();
  };

  const failureAddCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const setServicesIds = () => {
    let membershipServices = customMembership.services.map(
      (membershipService, index) => {
        membershipService.service = services[index]._id;
        return membershipService;
      }
    );
    let membership = {
      price: customMembership.price,
      services: [...membershipServices],
    };
    let copiedCustomMembership = { ...customMembership };
    delete copiedCustomMembership.price;
    delete copiedCustomMembership.services;
    copiedCustomMembership.membership = membership;
    return copiedCustomMembership;
  };

  const handleCreate = () => {
    setSubmitted(true);
    handleEmptyServicesFields();
    const inputs = Object.values(customMembership);
    const validated = validateForm([...inputs]);
    if (validated) {
      sessionStorage.removeItem(PREVENT_LOADING_WHEN_RETURNED_CUSTOMER_PAGE);
      if (isEditing) {
        handleEditMembership(customMembership);
      } else {
        const membership = setServicesIds();
        dispatch(
          addCustomMembership({
            customMembership: membership,
            id: params.id,
            successCallback: () => successAddCallback(),
            failureCallback: (message) => failureAddCallback(message),
          })
        );
      }
    }
  };
  const getNumberOfTimes = (service) => {
    const wantedService = customMembership.services.filter((element) => {
      return element.service == service;
    })[0];
    if (!wantedService) {
      return { numberOfFreeTimes: 0, numberOfFreeTimesUsed: 0 };
    }
    return {
      membershipNumberOfFreeTimes: wantedService?.numberOfFreeTimes ?? 0,
      membershipNumberOfFreeTimesUsed:
        wantedService?.numberOfFreeTimesUsed ?? 0,
    };
  };
  return (
    <div className="custom-membership-form">
      <div className="close-button-container">
        <div className="form-title">
          {t("memberships_list.custom_membership.title")}
        </div>
        <img
          src={CloseSvg}
          alt="close car form"
          onClick={() => handleClosing()}
          id="close-card-form-button"
        />
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Dropdown
              required
              name="car"
              options={isEditing ? carsDropDown : carsWithNoMemberships}
              onChange={handleChange}
              value={customMembership.car}
              inputId="custom-membership-car"
              disabled={isEditing}
              filter
            />
            <label htmlFor="custom-membership-car">{t("common.vehicle")}</label>
          </span>
        </div>
        {submitted && customMembership.car?.length === 0 && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <InputNumber
              min={0}
              name="price"
              onChange={handleChange}
              value={customMembership.price}
              inputId="custom-membership-price"
              className="form-input integer-only"
            />
            <label htmlFor="custom-membership-price">{t("common.price")}</label>
          </span>
        </div>
        {submitted && typeof customMembership.price !== "number" && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Calendar
              required
              name="startDate"
              onChange={handleChange}
              inputId="custom-membership-start-date"
              className="custom-membership-calender"
              value={
                customMembership.startDate
                  ? new Date(customMembership.startDate)
                  : ""
              }
            />
            <label htmlFor="custom-membership-start-date">
              {t("memberships_list.custom_membership.start_date")}
            </label>
          </span>
        </div>
        {submitted && !customMembership.startDate && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Calendar
              required
              name="endDate"
              onChange={handleChange}
              inputId="custom-membership-end-date"
              className="custom-membership-calender"
              value={
                customMembership.endDate
                  ? new Date(customMembership.endDate)
                  : ""
              }
            />
            <label htmlFor="custom-membership-end-date">
              {t("memberships_list.custom_membership.end_date")}
            </label>
          </span>
        </div>
        {submitted && !customMembership.endDate && (
          <small className="p-error block">{t("common.required_field")}</small>
        )}
      </div>
      <div className="custom-membership-services-container">
        <div className="row custom-membership-labels-container">
          <div className="service-name">{t("common.name")}</div>
          <div className="row-full-width custom-membership-label">
            {t("memberships_list.custom_membership.used")}
          </div>
          <div className="row-full-width custom-membership-label">
            {t("memberships_list.custom_membership.total")}
          </div>
        </div>

        {services?.length > 0 &&
          services.map((service, index) => {
            const {
              membershipNumberOfFreeTimes,
              membershipNumberOfFreeTimesUsed,
            } = getNumberOfTimes(service._id);
            return (
              <div className="row service-row" key={service._id}>
                <div className="service-name">
                  {t("services_page.services." + service.key)}
                </div>
                <div className="row used-total-service">
                  <div className="row-full-width">
                    <InputNumber
                      min={0}
                      name="numberOfFreeTimesUsed"
                      value={membershipNumberOfFreeTimesUsed}
                      onChange={(e) => handleServicesPrices(e, service._id)}
                      className="form-input integer-only"
                    />
                  </div>
                  <div className="row-full-width">
                    <InputNumber
                      min={0}
                      name="numberOfFreeTimes"
                      value={membershipNumberOfFreeTimes}
                      onChange={(e) => handleServicesPrices(e, service._id)}
                      className="form-input integer-only"
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Button name="buttons.apply_membership_button" onClick={handleCreate} />
    </div>
  );
};

export default CustomMembership;
