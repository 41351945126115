import "./Services.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { TabView, TabPanel } from "primereact/tabview";
import ServicesList from "./ServicesList/ServicesList";
import { useDispatch, useSelector } from "react-redux";
import RequestsList from "./RequestsList/RequestsList";
import { setServicesTab } from "../../redux/slices/TabsSlice";
import MembershipsList from "./MembershipsList/MembershipsList";
import PromotionCodesList from "./PromotionCodesList/PromotionCodesList";
import CompaniesList from "./CompaniesList/CompaniesList";

const Services = () => {
  const { servicesTab } = useSelector((state) => state.tabs);
  const { features } = useSelector((state) => state.user);

  let dispatch = useDispatch();
  let { t } = useTranslation();

  const handleTab = (index) => {
    dispatch(setServicesTab({ servicesTab: index }));
  };

  const renderTab = () => {
    switch (servicesTab) {
      case 0:
        return <RequestsList />;
      case 1:
        return <MembershipsList />;
      case 2:
        return <ServicesList />;
      case 3:
        return <PromotionCodesList />;
      case 4:
        return <CompaniesList />;
      default:
        return features.services.requests.view ? (
          <RequestsList />
        ) : features.services.memberships.view ? (
          <MembershipsList />
        ) : features.services.services.view ? (
          <ServicesList />
        ) : features.services.companies.view ? (
          <CompaniesList />
        ) : (
          <PromotionCodesList />
        );
    }
  };

  useEffect(() => {
    renderTab();
    // eslint-disable-next-line
  }, [servicesTab]);

  return (
    <Layout className="services-tab-container">
      <TabView
        className="services-tabs"
        activeIndex={servicesTab}
        onTabChange={(tab) => handleTab(tab.index)}
      >
        {features.services.requests.view && (
          <TabPanel header={t("services_page.membership_requests.title")} />
        )}
        {features.services.memberships.view && (
          <TabPanel header={t("common.memberships")} />
        )}
        {features.services.services.view && (
          <TabPanel header={t("common.services")} />
        )}
        {features.services.promotionCodes.view && (
          <TabPanel
            header={t("common.promotion_codes")}
            className="promotion-codes-tab"
          />
        )}
        {features.services.companies.view && (
          <TabPanel header={t("services_page.companies.title")} />
        )}
      </TabView>
      {renderTab()}
    </Layout>
  );
};

export default Services;
