import { isValidPhoneNumber } from "react-phone-number-input";

export const validatePhoneNumber = (phone) => {
  return isValidPhoneNumber(phone);
};

export const validateEmail = (email, errors) => {
  let result = true;
  if (!email) {
    result = false;
  } else {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
  }
  return result;
};

export const validateForm = (inputs) => {
  let result = true;
  inputs.forEach((input) => {
    if (input === undefined || input === null || input.length === 0) {
      result = false;
    }
  });
  return result;
};
