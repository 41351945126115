import "./RequestsList.css";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { confirmDialog } from "primereact/confirmdialog";
import ErrorLayout from "../../../components/ErrorLayout/ErrorLayout";
import { setToastMessage } from "../../../redux/slices/ToastMessageSlice";
import { ReactComponent as ViewIcon } from "../../../assets/svg/eye.svg";
import {
  actions,
  actionsDropDown,
  paidOptions,
  statuses,
  tableFilters,
} from "./constants";
import {
  activateMembership,
  deleteMembershipRequest,
  fetchPendingMemberships,
  markMembershipRequest,
} from "../../../redux/slices/MembershipsSlice";

const RequestsList = () => {
  const { pendingMemberships } = useSelector((state) => state.memberships);
  const { features } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(tableFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [errorDataLoading, setErrorDataLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  let { t } = useTranslation();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (pendingMemberships?.length) {
      setLoading(false);
    }
    dispatch(
      fetchPendingMemberships({
        successCallback: () => setLoading(false),
        failureCallback: () => setErrorDataLoading(true),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const successActivateCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_activated"),
      })
    );
  };

  const successDeleteCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: message,
      })
    );
  };

  const successMarkAsPaidCallback = () => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "success",
        detail: t("toast_messages.membership.membership_marked"),
      })
    );
  };

  const failureActivateDeleteCallback = (message) => {
    dispatch(
      setToastMessage({
        show: true,
        position: "bottom-center",
        type: "error",
        detail: message,
      })
    );
  };

  const acceptConfirmPopUp = (rowData, index) => {
    confirmDialog({
      header: t("memberships_list.activate_membership_pop_up.title"),
      message: t("memberships_list.activate_membership_pop_up.body"),
      accept: () =>
        dispatch(
          activateMembership({
            id: rowData._id,
            index,
            successCallback: () => successActivateCallback(),
            failureCallback: (message) =>
              failureActivateDeleteCallback(message),
          })
        ),
      reject: () => console.log("reject"),
    });
  };

  const deleteConfirmPopUp = (rowData, index) => {
    confirmDialog({
      header: t("memberships_list.delete_membership_pop_up.title"),
      message: t("memberships_list.delete_membership_pop_up.body"),
      accept: () =>
        dispatch(
          deleteMembershipRequest({
            id: rowData._id,
            index,
            successCallback: (message) => successDeleteCallback(message),
            failureCallback: (message) =>
              failureActivateDeleteCallback(message),
          })
        ),
      reject: () => console.log("reject"),
    });
  };

  const markAsPaidConfirmPopUp = (rowData, index) => {
    confirmDialog({
      header: t("memberships_list.mark_membership_pop_up.title"),
      message: t("memberships_list.mark_membership_pop_up.body"),
      accept: () => {
        dispatch(
          markMembershipRequest({
            id: rowData._id,
            index,
            successCallback: () => {
              setRefresh((prevState) => !prevState);
              successMarkAsPaidCallback();
            },
            failureCallback: (message) =>
              failureActivateDeleteCallback(message),
          })
        );
      },
      reject: () => console.log("reject"),
    });
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("common.select_status")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const paidFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={paidOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("common.select_status")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const paidItemTemplate = (rowData) => {
    if (rowData.paid) {
      return <div>{t("common.yes")}</div>;
    } else {
      return <div>{t("common.no")}</div>;
    }
  };

  const registrationPhotoItemTemplate = (rowData) => {
    return rowData?.registrationPhoto ? (
      // eslint-disable-next-line
      <a
        href={rowData.registrationPhoto}
        className="view-registration-photo-link"
        target="_blank"
      >
        <ViewIcon />
      </a>
    ) : (
      "-"
    );
  };

  const handleAction = (rowData, action, index) => {
    if (action === actions.ACTIVATE) {
      acceptConfirmPopUp(rowData, index);
    } else if (action === actions.DELETE) {
      deleteConfirmPopUp(rowData, index);
    } else {
      markAsPaidConfirmPopUp(rowData, index);
    }
  };

  const actionBodyTemplate = (options, rowData) => {
    let dropDownOptions = actionsDropDown;
    if (options.paid && !features.customers.tabs.membership.markAsPaid) {
      dropDownOptions = dropDownOptions.filter((item) => item.value !== "mark");
    }
    if (!features.customers.tabs.membership.delete) {
      dropDownOptions = dropDownOptions.filter(
        (item) => item.value !== "delete"
      );
    }
    if (!features.customers.tabs.membership.activate) {
      dropDownOptions = dropDownOptions.filter(
        (item) => item.value !== "activate"
      );
    }
    return (
      <Dropdown
        options={dropDownOptions}
        value={options.value}
        dropdownIcon="pi pi-ellipsis-v"
        onChange={(e) => handleAction(options, e.value, rowData.rowIndex)}
        className="p-column-filter requests-list-dropdown"
      />
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleCellSelection = (e) => {
    if (
      e.value.column.props.headerClassName !==
      "requests-header options-dropdown"
    ) {
      navigate(`/dashboard/customers/${e.value.rowData.user._id}`);
    }
  };

  useEffect(() => {
    setGlobalFilterValue(filters?.global?.value);
    // eslint-disable-next-line
  }, [loading]);

  return errorDataLoading ? (
    <ErrorLayout />
  ) : loading ? (
    <Loader />
  ) : (
    <>
      <div className="table-header">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder={t("searches.keyword_search")}
        />
      </div>
      <DataTable
        rowHover
        paginator
        rows={20}
        scrollable
        dataKey="_id"
        cellSelection
        filters={filters}
        id="requests-table"
        filterDisplay="menu"
        selectionMode="single"
        responsiveLayout="scroll"
        value={pendingMemberships}
        onSelectionChange={handleCellSelection}
        emptyMessage={t("services_page.memberships.no_memberships_found")}
        globalFilterFields={[
          "user.fullName",
          "user.phoneNumber",
          "brandModel",
          "paymentMethod",
          "registrationPhoto",
          "paid",
        ]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        <Column
          sortable
          field="user.fullName"
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="requests-table-main-column"
          header={t("services_page.membership_requests.client_name")}
        />
        <Column
          sortable
          showAddButton={false}
          field="user.phoneNumber"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          header={t("common.phone_number")}
          className="requests-table-main-column"
        />
        <Column
          sortable
          field="brandModel"
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          className="requests-table-main-column"
          header={t("services_page.membership_requests.car_brand_model")}
        />
        <Column
          filter
          sortable
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={(rowData) => {
            return t("common.payment_method." + rowData?.paymentMethod);
          }}
          header={t("common.payment_method.title")}
          filterElement={statusFilterTemplate}
          className="requests-table-main-column"
        />
        <Column
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={(rowData) => {
            return rowData.promoCode ?? "-";
          }}
          header={t("services_page.promotion_codes.promotion_code_form.title")}
          filterElement={statusFilterTemplate}
          className="requests-table-main-column"
        />
        <Column
          filter
          sortable
          field="registrationPhoto"
          showAddButton={false}
          body={registrationPhotoItemTemplate}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showFilterMenu={false}
          header={t("common.registration_photo")}
          filterElement={statusFilterTemplate}
          className="requests-table-main-column"
        />
        <Column
          filter
          sortable
          field="paid"
          showAddButton={false}
          body={paidItemTemplate}
          header={t("common.paid")}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={paidFilterTemplate}
          className="requests-table-main-column"
        />
        <Column
          body={actionBodyTemplate}
          headerClassName="requests-header options-dropdown"
        />
      </DataTable>
    </>
  );
};

export default RequestsList;
